 
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import EmptyInterface from "@components/empty";
import data from "../reports/dataview/data.json";
import TimeReports from "./dataview";

function TotalTimesTables() {
  return (
    <div className={`mt-12 rounded-lg ${data && "bg-white"}`}>
      <div className="px-5 py-4 flex justify-between items-center">
        <h1 className="text-2xl font-outfit font-semibold">Total times</h1>
        <div className="flex gap-x-3 items-center">
          <CopyIcon />
          <ShareIcon />
          <PrintIcon />
        </div>
      </div>
      <div className="mt-4">
        {data.length === 0 ? (
          <div className="mt-4 border border-custom_lighter_gray rounded-xl">
            <EmptyInterface
              description="No tasks assigned yet..."
              buttonText=""
              buttonAction={() => null}
              showButton={false}
            />
          </div>
        ) : (
          <div>
            <div className="mt-4">
              <TimeReports />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TotalTimesTables;
