function BoardIcon(props: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 18.3327H12.5C16.6667 18.3327 18.3333 16.666 18.3333 12.4993V7.49935C18.3333 3.33268 16.6667 1.66602 12.5 1.66602H7.5C3.33333 1.66602 1.66666 3.33268 1.66666 7.49935V12.4993C1.66666 16.666 3.33333 18.3327 7.5 18.3327Z"
        stroke={props?.fill || "#7E879F"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 1.66602V18.3327"
        stroke={props?.fill || "#7E879F"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BoardIcon;
