export function extractTextFromPathname(pathname: string): string | null {
  const pathParts = pathname.split("/");

  // Check if there are at least two parts in the path
  if (pathParts.length >= 2) {
    return pathParts[1];
  } else {
    return null;
  }
}




export const getRandomColor = () => {
  const colors = [
    {
      border: "border-card_yellow",
      bg: "bg-card_yellow",
      text: "text-card_yellow",
    },
    {
      border: "border-card_blue",
      bg: "bg-card_blue",
      text: "text-card_blue",
    },
    {
      border: "border-card_green",
      bg: "bg-card_green",
      text: "text-card_green",
    },
    {
      border: "border-card_purple",
      bg: "bg-card_purple",
      text: "text-card_purple",
    },
    {
      border: "border-card_red",
      bg: "bg-card_pink",
      text: "text-card_pink",
    },
  ];

  return colors[Math.floor(Math.random() * colors.length)];
}
export const STATUSLIST = [
  {
    name: "Pending",
    colors: {
      bg: "bg-orange-100",
      text: "text-[#FBB03B]",
    },
  },
  {
    name: "Complete",
    colors: {bg: "bg-green-100", text: "text-green-800"},
  },
  {
    name: "Incomplete",
    colors: {bg: "bg-red-100", text: "text-red-800"},
  },
];
export const COMPANIESLIST = [
  {
    name: "New Lead",
    colors: {bg: "bg-green-100", text: "text-green-800"},
  },
  {
    name: "In progress",
    colors: {
      bg: "bg-orange-100",
      text: "text-[#FBB03B]",
    },
  },
  {
    name: "Idle",
    colors: {bg: "bg-red-100", text: "text-red-800"},
  },
  {
    name: "Prospect",
    colors: {bg: "bg-purple-100", text: "text-purple-800"},
  },
];



export const JobStatus = [
  {
    name: "Passed",
    colors: {
      bg: "bg-green-100",
      text: "text-gren-800",
    },
  },
  {
    name: "Failed",
    colors: {bg: "bg-red-100", text: "text-red-800"},
  },
  {
    name: "Not started",
    colors: {bg: "bg-custom_faded_purple", text: "text-custom_deep_purple"},
  },
];

export const YesNo = [
  {
    name: "Yes",
    colors: {
      bg: "bg-green-100",
      text: "text-green-800",
    },
  },
  {
    name: "No",
    colors: {bg: "bg-red-100", text: "text-red-800"},
  },
];