function Badge({
  text,
  status,
  colors,
}: {
  text: string;
  status: string;
  colors?: {bg: string; text: string};
}) {
  if (colors) {
    return (
      <div>
        <span
          className={`px-3 rounded-full text-sm py-1 ${colors.bg} ${colors.text}`}
        >
          {text}
        </span>
      </div>
    );
  } else if (status === "No" || status === "Pending") {
    return (
      <div>
        <span className="px-3 rounded-full py-1 bg-orange-100 text-sm text-orange-500">
          {text}
        </span>
      </div>
    );
  } else {
    return (
      <div>
        <span className="px-3 rounded-full text-sm py-1 bg-green-100 text-green-500">
          {text}
        </span>
      </div>
    );
  }
}

export default Badge;
