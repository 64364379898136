import EmployeeIcon from "@components/svgs/bigemployee";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import Input from "@components/input";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AddNewCandidate() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

const navigate = useNavigate();

  return (
    <>
      <div className="gap-y-4 flex flex-col pb-12">
        <div className="w-full px-6 items-center rounded-lg justify-between flex bg-custom_faded_blue">
          <h1 className="text-2xl font-outfit font-medium">
            Add a new candidate
          </h1>

          <div className="">
            <EmployeeIcon />
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={() => navigate(-1)}
            className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
          >
            <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
            Back
          </button>
        </div>
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg  bg-custom_faded_blue">
          <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 lg:px-2 xl:px-0">
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                First name:{" "}
              </label>
              <Input
                placeholder={"eg. John"}
                className={"mt-1"}
                value={firstName}
                onChange={(e: any) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Last name:{" "}
              </label>
              <Input
                placeholder={"eg. Doe"}
                className={"mt-1"}
                value={lastName}
                onChange={(e: any) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Position:{" "}
              </label>
              <Input
                type="text"
                placeholder={"eg. software engineer"}
                className={"mt-1"}
                value={position}
                onChange={(e: any) => setPosition(e.target.value)}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Email:{" "}
              </label>
              <Input
                type="email"
                placeholder={"eg. software engineer"}
                className={"mt-1"}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Password:{" "}
              </label>
              <div className="mt-1 relative">
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  placeholder="eg. *********"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute outline-none right-0 top-0 mt-3 mr-3"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-6 w-6 text-custom_gray" />
                  ) : (
                    <EyeIcon className="h-6 w-6 text-custom_gray" />
                  )}
                </button>
              </div>
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Confirm Password:{" "}
              </label>
              <div className="mt-1 relative">
                <Input
                  id="password"
                  name="password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  placeholder="eg. *********"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute outline-none right-0 top-0 mt-3 mr-3"
                >
                  {showConfirmPassword ? (
                    <EyeSlashIcon className="h-6 w-6 text-custom_gray" />
                  ) : (
                    <EyeIcon className="h-6 w-6 text-custom_gray" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
            <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
              <span className="text-white">Add candidate !</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewCandidate;
