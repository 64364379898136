 

interface ButtonProps {
  onClick: () => void;
  className?: string;
  title: string;
  icon?: any;
  props?: any;
}

function Button({onClick, title, className, icon, props}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`bg-custom_deep_blue text-sm w-full justify-center text-white px-4 py-3 rounded-lg flex items-center gap-3 ${className}`}
      {...props}
    >
      {icon ? <img src={icon} className="w-6 h-6" /> : null}
      <span>{title}</span>
    </button>
  );
}

export default Button;
