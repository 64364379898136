import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={187}
    height={142}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#F2F2F2"
        d="M113.391 42.79a1.135 1.135 0 0 0 .823-1.963l-.078-.309.031-.074c1.046-2.487 4.59-2.47 5.627.02.92 2.212 2.092 4.426 2.38 6.764a8.976 8.976 0 0 1-.158 3.095 36.01 36.01 0 0 0 3.291-14.987 34.79 34.79 0 0 0-.217-3.886 35.64 35.64 0 0 0-.498-3.15 36.466 36.466 0 0 0-7.257-15.472 9.723 9.723 0 0 1-4.051-4.191 7.395 7.395 0 0 1-.676-2.025c.197.025.744-2.97.595-3.154.275-.416.767-.623 1.067-1.029 1.492-2.018 3.549-1.666 4.623 1.077 2.293 1.155 2.316 3.07.908 4.911-.895 1.172-1.018 2.757-1.804 4.012.081.103.165.203.246.306a36.792 36.792 0 0 1 3.845 6.075c-.306-2.38.145-5.246.914-7.094.875-2.105 2.515-3.879 3.959-5.699 1.735-2.186 5.293-1.232 5.598 1.54l.009.08c-.214.12-.424.249-.63.384a1.54 1.54 0 0 0-.197 2.4c.226.211.511.35.818.397l.032.005a15.407 15.407 0 0 1-.404 2.286c1.852 7.147-2.148 9.75-7.86 9.867-.126.065-.249.129-.375.19a37.4 37.4 0 0 1 2.024 9.5 35.407 35.407 0 0 1-.026 5.742l.01-.067a9.393 9.393 0 0 1 3.217-5.427c2.475-2.028 5.973-2.775 8.643-4.405 1.286-.785 2.933.229 2.709 1.715l-.011.072a10.382 10.382 0 0 0-1.791.942 1.54 1.54 0 0 0-.197 2.4c.226.21.512.35.818.397l.032.004.064.01a15.281 15.281 0 0 1-2.822 4.408c-1.158 6.239-6.134 6.83-11.457 5.014h-.004a37.402 37.402 0 0 1-2.525 7.352h-9.019c-.033-.1-.062-.203-.091-.303.835.052 1.673.002 2.496-.149-.669-.819-1.338-1.644-2.008-2.463a.535.535 0 0 1-.042-.049c-.339-.419-.682-.835-1.021-1.254h-.001c-.018-1.285.13-2.567.44-3.814h.001Z"
      />
      <path
        fill="#2F2E41"
        d="M142.351 19.767c-1.678-6.099 11.849-11.329 15.402-6.417.493-.698 2.319-1.123 3.156-.908.838.214 1.542.75 2.229 1.268.945.724 1.925 1.473 2.504 2.514.585 1.037.675 2.45-.111 3.35-.623.716-2.461 1.178-3.398 1.367-.652.133-1.202.24-1.675.317a3.191 3.191 0 0 1-.558-2.112 2.595 2.595 0 0 0-1.134 1.902c-.013.158 5.279 19.577 5.279 19.577-.636.361-1.305.66-1.997.895a24.996 24.996 0 0 1-3.114-6.768c-.16 1.604-.14 3.22.06 4.82.106.896.258 1.787.455 2.668-6.288 1.354-15.746.908-19.86-4.85a1.706 1.706 0 0 1-.002-.07.979.979 0 0 0-.808-.951.983.983 0 0 0-1.09.609c-.1.254-.191.474-.273.648-1.199-4.275.352-9.223 4.935-17.859ZM131.65 122.015v.199a.946.946 0 0 1 0-.199ZM137.808 87.5c.16-.043.323-.075.487-.095-.188.031-.351.062-.487.095ZM137.666 87.536l.142-.037c-.047.013-.093.022-.142.037Z"
      />
      <path
        fill="#2F2E41"
        d="M183.16 141.999h-23.049a1.829 1.829 0 0 1-.493-2.251c.63-1.44-.604-2.756-.604-2.756a3.897 3.897 0 0 1-.79-3.604c.61-2.127-1.783-8.131-1.783-8.131s-1.29 7.753-2.871 16.742h-21.92a4.054 4.054 0 0 1 0-4.115c1.278-2.16 0-2.574 0-2.574s-1.277-2.418 0-4.173c1.278-1.755 0-8.923 0-8.923.049.317.412-.259.973-1.467.853-1.838 2.156-5.143 3.473-8.98a136.437 136.437 0 0 0 2.685-8.698h.002c.207-.774.405-1.541.591-2.301a65.52 65.52 0 0 0 .912-4.298c.514-2.928.684-5.444.289-7.08-.327-1.362-1.043-2.113-2.28-1.984.663-.11 1.633-.214 2.829-.312l.67-.053c8.919-.673 28.055-1.059 28.055-1.059l5.861 10.469 7.423 13.261s-.202 17.79.027 32.287Z"
      />
      <path
        fill="#E6E6E6"
        d="m158.559 40.735-13.504-2.857-2.681 5.383-9.029 5.214s5.82 23.735 5.787 27.073c-.085 8.475-13.681 27.474-3.411 26.012 10.269-1.461 12.355-7.583 15.652-6.615 2.974.873 6.604 4.757 9.035 1.979 2.43-2.778 3.286.43 4.823.54.731.053.291-3.507 2.128.111 1.838 3.618 11.583-1.252 8.28-4.546-5.501-5.487-6.619-45.93-6.619-45.93l-6.123-1.88-4.338-4.484Z"
      />
      <path
        fill="#A0616A"
        d="M133.089 126.931a3.23 3.23 0 0 0-.333-4.497 3.152 3.152 0 0 0-.417-.301l2.258-11.052-5.22-3.116-1.451 15.474a3.21 3.21 0 0 0-.288 2.059 3.224 3.224 0 0 0 1.033 1.806c.306.266.663.468 1.048.596a3.092 3.092 0 0 0 3.316-.904l.054-.065Z"
      />
      <path
        fill="#E6E6E6"
        d="M149.296 68.675c-.033-.356-.487-.517-1.117-.74a6.914 6.914 0 0 1-1.785-.843c-1.394-1.004-.845-2.055-.087-3.507.149-.282.303-.576.447-.881.429-.905-.254-4.636-2.035-11.093-.549-1.988-.547-3.392-.926-4.323-.378-.931-1.134-1.39-3.197-1.486a9.443 9.443 0 0 0-6.787 2.406c-.05.044-.102.092-.152.138a7.961 7.961 0 0 0-.277.271 9.386 9.386 0 0 0-2.739 6.46l-.009.413-.426 19.728-1.195 17.311-1.649 23.847 1.221.059 4.872.242.591.029 1.402-5.865 1.686-7.058.62-2.593 1.924-8.043.897-3.758.549-2.295.223-.937 1.09-4.555c.025-.097 2.532-9.962 5.422-11.612 1.079-.614 1.468-.972 1.437-1.315Z"
      />
      <path
        fill="#A0616A"
        d="M181.724 126.813a3.226 3.226 0 0 1-.153-4.507c.118-.125.245-.24.382-.343l-3.435-10.747 4.854-3.656 3.109 15.228a3.215 3.215 0 0 1-.324 3.923 3.103 3.103 0 0 1-3.344.792 3.106 3.106 0 0 1-1.028-.631l-.061-.059Z"
      />
      <path
        fill="#E6E6E6"
        d="M184.098 103.495s-3.786-13.285-2.631-14.723c1.154-1.438-2.255-1.719-.786-4.397 1.469-2.678-.559-3.126-.559-3.126l-1.1-6.159-1.968-15.126-.059-.45-.573-4.4a9.395 9.395 0 0 0-1.212-3.563 9.435 9.435 0 0 0-2.672-2.922 9.452 9.452 0 0 0-7.014-1.672c-4.08.634-2.81 2.151-3.472 6.217-1.076 6.611-1.355 10.394-.83 11.248.176.288.362.563.539.828.911 1.364 1.57 2.348.291 3.498a6.97 6.97 0 0 1-1.684 1.027c-.6.29-1.036.499-1.03.856.007.345.432.657 1.569 1.152 1.379.602 2.859 2.877 4.072 5.2a57.297 57.297 0 0 1 2.57 5.765l10.422 29.235 1.7 4.768.587-.092 4.815-.762 1.208-.19-2.183-12.212Z"
      />
      <path
        fill="#A0616A"
        d="M152.862 37.676c6.084 0 11.017-4.92 11.017-10.988 0-6.069-4.933-10.988-11.017-10.988-6.085 0-11.017 4.92-11.017 10.988 0 6.069 4.932 10.988 11.017 10.988Z"
      />
      <path
        fill="#2F2E41"
        d="M136.337 20.623c-1.677-6.098 11.85-11.328 15.402-6.417.494-.698 2.32-1.122 3.157-.908.838.214 1.542.75 2.229 1.268.945.724 1.925 1.474 2.504 2.515.585 1.036.675 2.45-.111 3.35-.623.715-2.461 1.178-3.398 1.366-.652.133-1.202.24-1.675.317a3.185 3.185 0 0 1-.558-2.112 2.597 2.597 0 0 0-1.134 1.902c-.013.159 5.279 19.577 5.279 19.577-.636.361-1.305.661-1.998.896a25.023 25.023 0 0 1-3.113-6.769c-.16 1.604-.14 3.22.06 4.82.106.897.258 1.787.455 2.669-6.288 1.353-15.746.908-19.86-4.85a1.702 1.702 0 0 1-.002-.071.985.985 0 0 0-.808-.95.983.983 0 0 0-1.09.608c-.1.254-.192.474-.274.649-1.198-4.275.353-9.223 4.935-17.86Z"
      />
      <path
        fill="#F2F2F2"
        d="M81.7 58.593a1.139 1.139 0 0 1-1.1-.695 1.13 1.13 0 0 1 .277-1.269l.078-.309-.031-.074c-1.046-2.487-4.59-2.47-5.627.021-.92 2.21-2.092 4.425-2.38 6.763a8.976 8.976 0 0 0 .158 3.095 36.008 36.008 0 0 1-3.291-14.987c0-1.298.072-2.596.216-3.886a36.16 36.16 0 0 1 .498-3.15 36.462 36.462 0 0 1 7.258-15.471 9.726 9.726 0 0 0 4.051-4.192c.322-.639.55-1.321.676-2.025-.198.026-.744-2.97-.595-3.154-.275-.416-.767-.622-1.067-1.028-1.493-2.019-3.55-1.666-4.623 1.077-2.294 1.154-2.316 3.07-.909 4.91.896 1.172 1.019 2.758 1.804 4.012-.08.103-.165.203-.245.306a36.717 36.717 0 0 0-3.845 6.075c.305-2.38-.145-5.246-.914-7.094-.875-2.105-2.515-3.878-3.96-5.698-1.735-2.187-5.292-1.233-5.598 1.539l-.008.08c.214.121.424.25.63.385a1.538 1.538 0 0 1 .648 1.61 1.535 1.535 0 0 1-1.27 1.186l-.031.005c.076.771.212 1.535.404 2.286-1.853 7.147 2.148 9.75 7.86 9.867.125.065.248.13.374.19a37.418 37.418 0 0 0-2.023 9.5 35.486 35.486 0 0 0 .025 5.743l-.01-.068a9.393 9.393 0 0 0-3.216-5.427c-2.476-2.028-5.973-2.775-8.644-4.405-1.285-.785-2.932.23-2.708 1.716l.01.07a10.36 10.36 0 0 1 1.791.943 1.538 1.538 0 0 1 .649 1.61 1.536 1.536 0 0 1-1.27 1.187l-.031.005-.065.01a15.298 15.298 0 0 0 2.822 4.407c1.16 6.24 6.135 6.831 11.458 5.014h.003a37.423 37.423 0 0 0 2.525 7.352h9.02c.032-.1.061-.203.09-.303-.834.052-1.673.002-2.495-.148.669-.82 1.338-1.645 2.007-2.464a.539.539 0 0 0 .042-.048c.34-.42.683-.835 1.022-1.255a14.936 14.936 0 0 0-.44-3.814Z"
      />
      <path
        fill="#2F2E41"
        d="M39.613 13.35c-.205.475-6.517.424-6.806.848-2.23 3.271 5.806 17.19 3.577 20.464l-.31.37a14.68 14.68 0 0 0 2.088-1.176c.06-1.34.119-2.685.183-4.026a11.06 11.06 0 0 1 1.6 2.804c.518-.364.944-1.04 3.168-2.371.9-.987 1.939-1.84 3.083-2.531L39.613 13.35Z"
      />
      <path
        fill="#FFB6B6"
        d="M44.196 40.158a5.237 5.237 0 0 0-6.77.916 5.21 5.21 0 0 0-1.15 2.169L26.684 68.17l-16.488-9.336a5.084 5.084 0 0 0-1.175-2.922 5.117 5.117 0 0 0-8.243.558 5.08 5.08 0 0 0 .41 5.973A5.117 5.117 0 0 0 7.011 63.9c3.472 3.696 18.526 18.85 24.758 12.93C37.007 71.85 44.567 52.561 46.386 45.9a5.202 5.202 0 0 0-2.19-5.743Z"
      />
      <path
        fill="#FFB6B6"
        d="m26.185 77.873 3.027 13.646 22.134 1.997 2.035-11.882-27.196-3.761Z"
      />
      <path
        fill="#2F2E41"
        d="M59.996 112.867c-.01.196-.022.389-.037.585a4850.234 4850.234 0 0 0-2.063 28.547H11.47l9.004-26.993s3.636-1.997 1.785-5.351c-1.85-3.354 1.791-.681 1.825-5.471.034-4.79 3.298-9.885 3.298-9.885l.535-7.253.306.006 24.16.402.442.008.742 6.226a27.479 27.479 0 0 1 6.43 19.179Z"
      />
      <path
        fill="#27CE88"
        d="m40 33.545 11.017-.566 5.118 8.065s9.628 1.606 8.96 12.922c-.667 11.316-11.714 21.246-11.714 21.246l.472-1.615-.82 2.338.105 1.312 1.119 1.39.115 1.438-.991 2.274a1.297 1.297 0 0 1-.53 1.854 1.304 1.304 0 0 1-.706.127l-15.92-1.591c-.362-.995-.652-2.184-2.105-.204l-1.5-.145-6.234-.602-.367-1.333A5.151 5.151 0 0 1 26.862 76a6.796 6.796 0 0 1 .395-5.115l.03-.06.662-.55s-7.243-10.853 1.071-19.027l3.337-5.658 3.734-4.544 3.909-7.5Z"
      />
      <path
        fill="#FFB6B6"
        d="M42.742 28.784c5.77 0 10.447-4.665 10.447-10.42s-4.677-10.42-10.447-10.42-10.448 4.665-10.448 10.42 4.678 10.42 10.448 10.42Z"
      />
      <path
        fill="#2F2E41"
        d="M50.677 33.375a5.017 5.017 0 0 1-1.685-2.757 10.755 10.755 0 0 0 .15 3.937c-.567.424-1.115.827-1.634 1.192a11.059 11.059 0 0 0-1.6-2.804c-.063 1.34-.123 2.685-.183 4.026-.662.448-1.361.84-2.088 1.175l.31-.369c2.23-3.275-3.539-9.304-1.31-12.575.626-.917-2.199-8.159-4.351-8.486-1.915-.288-1.834.263-3.688.666a9.242 9.242 0 0 0-.89-2.295 7.564 7.564 0 0 1-.072 2.418c-.66.026-1.178-.393-1.88-.5-4.48-.683-4.024-5.516-2.778-7.047 1.251-1.528 5.403-1.986 7.317-2.482.174-.17.57-.064.744-.225 3.669-3.349 8.575-3.903 12.99-2.42 4.709 1.584 7.644 4.647 9.151 9.37l.432 3.778c.388 1.216-.1 6.87-.432 8.1-1.29 4.787-4.04 2.398-8.503 7.298Z"
      />
      <path
        fill="#FFB6B6"
        d="m65.91 82.745-.41-31.95a5.15 5.15 0 0 0-1.544-3.595 5.178 5.178 0 0 0-8.48 1.893 5.146 5.146 0 0 0-.311 2.056l1.447 33.176.028 20.196a4.502 4.502 0 0 0-2.745 4.38 4.497 4.497 0 0 0 3.192 4.069 4.515 4.515 0 0 0 4.923-1.604 4.492 4.492 0 0 0 .17-5.163l3.73-23.458Z"
      />
      <path
        fill="#2F2E41"
        d="M119.832 124.081s-.636 11.411-.929 17.919h-21.9c-.45-5.923-.602-10.274-.602-10.274L95.203 142H73.066l-1.372-19.028 6.632-16.396s35.94-2.486 39.274.668c3.332 3.151 2.232 16.837 2.232 16.837Z"
      />
      <path
        fill="#FFB6B6"
        d="M100.466 28.09c6.606 0 11.962-5.342 11.962-11.932 0-6.59-5.356-11.931-11.962-11.931-6.607 0-11.963 5.342-11.963 11.931 0 6.59 5.356 11.932 11.963 11.932Z"
      />
      <path
        fill="#2F2E41"
        d="M114.409 12.281c-1.228.56-2.025 4.606-2.99 3.665-.966-.94-.902-2.165-2.204-2.512-1.051-.282-2.183.04-3.142.548-.171.09-.337.187-.499.284a4.197 4.197 0 0 0-.327-.2 3.811 3.811 0 0 0-2.798-.314 3.724 3.724 0 0 1 1.843 1.355c-.321.214-.646.43-.978.62-2.779 1.599-6.732-1.878-9.656-3.184 1.831 3.428.93 4.848-.661 8.394l-.055.201c.09-.888-2.667-1.55-2.507-.3.114.889.884 2.863 1.316 3.647-3.075-3.546-5.176-7.885-4.732-12.361.096-.94.067-1.933.553-2.749.487-.812 1.502-1.382 2.4-1.072a7.893 7.893 0 0 1 .906-4.113 7.92 7.92 0 0 1 2.894-3.066 7.946 7.946 0 0 1 8.14-.06c1.148.672 2.106 1.622 3.159 2.442 1.052.82 2.265 1.534 3.599 1.602.847.04 1.768-.166 2.495.272.842.507.986 1.57 1.113 2.592a3.804 3.804 0 0 0-2.046.849 3.724 3.724 0 0 1 2.273.499c.446 1.69.4 2.064 1.904 2.961Z"
      />
      <path
        fill="#27CE88"
        d="M73.12 112.348c10.186-11.893 6.745-27.02 2.702-42.29l.77-22.558a6.887 6.887 0 0 1 3.13-5.542l10.4-6.777 4.047-5.837 11.158 1.113 6.187 6.526 6.849 5.215a11.323 11.323 0 0 1 3.771 5.108c.743 2.027.889 4.223.422 6.33l-6.554 29.562s2.741 15.325 1.069 17.017c-1.673 1.693-5.982.001-2.565 1.901 4.102 2.28 7.831 1.859 5.755 3.93-2.075 2.07.552 4.948.552 4.948l-47.694 1.354Z"
      />
      <path
        fill="#FFB6B6"
        d="m77.475 129.633 1.698-12.068-6.776-1.929-1.34 12.822a4.072 4.072 0 0 0 .019 6.031 4.098 4.098 0 0 0 6.017-.577 4.072 4.072 0 0 0 .382-4.279Z"
      />
      <path
        fill="#27CE88"
        d="M90.376 37.357S71.087 43.618 70.627 48.2c-.46 4.581-.307 7.386-2.343 25.72l.107 49.383s12.932 2.762 11.606-.191c-1.326-2.952-.864-4.341-.864-4.341s2.773-1.092 2.08-4.558c-.695-3.467 2.786-20.648 2.786-20.648l2.196-30.812 4.18-25.396Z"
      />
      <path
        fill="#FFB6B6"
        d="m114.159 128.994-.903-12.153 6.888-1.484.497 12.883a4.076 4.076 0 0 1-2.481 6.872 4.098 4.098 0 0 1-3.9-1.824 4.068 4.068 0 0 1-.101-4.294Z"
      />
      <path
        fill="#27CE88"
        d="M107.333 36.074s18.837 7.506 18.996 12.108c.159 4.601-.177 7.39.653 25.816l-3.344 49.272s-13.085 1.913-11.568-.947c1.517-2.86 1.146-4.276 1.146-4.276s-2.695-1.27-1.775-4.684c.919-3.414-1.428-20.785-1.428-20.785l-.173-30.89-2.507-25.614Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h187v142H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
