import Input from "@components/input";
import React from "react";
import MyEditor from "@components/editor";
import CandidatesIcon from "@components/svgs/companiesbig";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import FilePicker from "@components/filepicker";
import Dropdown from "@components/dropdowns/dropdown";

function AddProduct() {
  const [summary, setSummary] = React.useState("");
  const [country, setCountry] = React.useState("");
  const navigate = useNavigate();

  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add a new blog post</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="grid grid-cols-1 bg-[#DDEFFF33] px-4 rounded-xl py-4 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <div className="md:col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Title:{" "}
          </label>
          <Input placeholder={"eg. Wed development"} className={"mt-1"} />
        </div>

        <div className="col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Summary:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={summary}
              setValue={setSummary}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>
        <div className="col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Country:{" "}
          </label>
          <div className="h-54">
            <Dropdown
              list={[
                {name: "Nigeria", value: "Nigeria"},
                {
                  name: "Ghana",
                  value: "Ghana",
                },
                {
                  name: "South Africa",
                  value: "South Africa",
                },
              ]}
              selected={country}
              setSelected={setCountry}
            />
          </div>
        </div>
        <div className="col-span-4">
          <div className="h-54">
            <FilePicker />
          </div>
        </div>

        <div className="col-span-4">
          <div className="mt-8 flex justify-end">
            <div>
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => null}
              >
                Add blog post
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
