 
import {Column as ColumnInterface, ITask} from "../initialdata";
import Task from "./task";
import {Droppable} from "react-beautiful-dnd";
import {PlusIcon} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
// import {useRouter} from "next/navigation";

interface Props {
  key: string;
  column: ColumnInterface;
  tasks: ITask[];
  index: number;
  handleDelete: any;
}

function Column(props: Props) {
const navigate = useNavigate();
  return (
    <div className="m-2 bg-white rounded-2xl py-3 w-[736px] flex flex-col px-2">
      <div className="flex mb-4 justify-between items-center">
        <h3
          className={`p-2 text-2xl font-bold ${
            props.column.id === "column-2"
              ? "  text-[#27CE88]"
              : props.column.id === "column-3"
              ? "text-[#FF9A40]"
              : "text-card_purple"
          }`}
        >
          {props.column.title}
        </h3>
        <button
          onClick={() =>  navigate("add")}
          className="bg-[#F4F7FE] px-5 py-1 rounded-xl"
        >
          <PlusIcon className="text-card_purple h-6 w-6 " />
        </button>
      </div>

      <Droppable droppableId={props?.column?.id}>
        {(provided, snapshot) => (
          <div
            className={`p-2 grow min-h-80 rounded-2xl ${
              snapshot?.isDraggingOver
                ? props.column.id === "column-2"
                  ? "bg-green-50"
                  : props.column.id === "column-3"
                  ? "bg-orange-50"
                  : "bg-purple-50"
                : "bg-white"
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {props.tasks.map((task, index) => (
              <Task
                index={index}
                key={task.id}
                task={task}
                handleDelete={props.handleDelete}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default Column;
