import CandidatesIcon from "@components/svgs/teamsbig";
import Avartar from "@assets/candidate.jpg";
import Button from "@components/button";
import Input from "@components/input";
import {useState} from "react";
import lock from "@assets/svgs/lock.svg";

function Account() {
  const [changePassword, setChangePassword] = useState(false);
  return (
    <div className="mt-4">
      <div className="bg-[#DDEFFF33] rounded-xl font-outfit py-4">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add a new holiday type</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="my-6 bg-[#DDEFFF33] rounded-xl px-4 py-8">
        <div className="flex items-center flex-col md:flex-row gap-5 md:gap-0">
          <img
            src={Avartar}
            className=" h-auto w-32 rounded-full"
            alt="Picture of the Candidate"
          />
          <div className="md:ms-6">
            <h2 className="text-xl">Immaculate Akuvi</h2>
            <p className="font-light text-sm text-custom_gray">UI/UX Design</p>
            <p className="font-light text-sm text-custom_gray">Accra,Ghana</p>
            <p className="font-light text-sm text-custom_gray">
              immaculate@remoteli.co.uk
            </p>
          </div>
          <div className="flex md:ms-10 gap-4 md:w-1/3 w-full">
            <Button onClick={() => null} title="Upload new image" />
            <button
              onClick={() => null}
              className={`border-custom_deep_blue border font-light text-sm w-full justify-center px-4 py-3 rounded-lg flex items-center gap-3`}
            >
              Delete
            </button>
          </div>
        </div>

        <hr className="my-8" />

        <div className="max-w-4xl grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              First name:{" "}
            </label>
            <Input placeholder={"eg. Phil"} className={"mt-1"} />
          </div>
          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              Last name:{" "}
            </label>
            <Input placeholder={"eg. Summers"} className={"mt-1"} />
          </div>

          {changePassword ? (
            <>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Password:{" "}
                </label>
                <Input placeholder={"eg. ********"} className={"mt-1"} />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Re-type password:{" "}
                </label>
                <Input placeholder={"eg. *******"} className={"mt-1"} />
              </div>
            </>
          ) : (
            <div className="">
              <button
                onClick={() => setChangePassword(true)}
                className={`border-custom-gray border py-3 hover:border-custom_deep_blue px-5 font-light text-sm justify-center   rounded-lg flex items-center gap-3`}
              >
                <span>Change Password</span>
                <img src={lock} alt="lock" />
              </button>
            </div>
          )}

          <div className="mt-8 flex md:col-span-2">
            <div>
              <button className="inline-flex justify-center rounded-md border border-transparent bg-custom_deep_blue px-8 py-3 text-sm font-light text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">
                Update!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
