"use client";
import React, {useState} from "react";
import {toast} from "sonner";
import {useNavigate, Link} from "react-router-dom";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import {login} from "@services/auth";
import {setCookie} from "@services/cookies";
import {MoonLoader} from "react-spinners";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (email === "" || password === "") {
      setLoading(false);
      return toast.error("Please fill in all fields");
    }
    try {
      const response = await login(email, password);

      if (!response?.data) {
        toast.error("Email or password is incorrect", {
          id: "login-error",
          style: {
            background: "red",
            border: "none",
            color: "#ffffff",
          },
        });
        setLoading(false);
        return;
      }
      setCookie("remoteli_staff_token", response?.data as string, {
        expires: 30,
      });
      toast.success("Login Successful", {
        description: "Welcome back!, " + email,
        style: {
          background: "green",
          border: "none",
          color: "#ffffff",
        },
      });
      setLoading(false);
      navigate("/dashboard");
    } catch (error: any) {
      setLoading(false);
      toast.error("Email or password is incorrect", {
        id: "login-error",
        style: {
          background: "red",
          border: "none",
          color: "#ffffff",
        },
      });
    }
  };

  return (
    <div className="mx-auto w-full max-w-lg lg:w-full">
      <div className="flex items-center justify-center flex-col">
        <h2 className="mt-8 text-3xl font-bold font-outfit leading-9 tracking-tight text-custom_blue">
          Sign in to account
        </h2>
        <p className="mt-2 text-sm leading-6 text-custom_gray">
          Enter your email and password to login
        </p>
      </div>

      <div className="mt-10">
        <div>
          <form onSubmit={handleSubmit} method="POST" className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-xs font-medium leading-6 text-custom_gray"
              >
                Email:
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  placeholder="eg. john.doe@remoteli.co.uk"
                  required
                  className="block bg-transparent text-white w-full rounded-lg border-gray border py-3 px-4 shadow-sm focus:ring-0 placeholder:text-gray-400 sm:text-base sm:leading-6 "
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-xs font-outfit leading-6 text-custom_gray"
              >
                Password:
              </label>
              <div className="mt-1 relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  placeholder="eg. *********"
                  className="block bg-transparent text-white w-full rounded-lg border-gray border py-3 px-4 ring-0 shadow-sm focus:ring-0 placeholder:text-gray-400 sm:text-base sm:leading-6 "
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute outline-none right-0 top-0 mt-3 mr-3"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-6 w-6 text-custom_gray" />
                  ) : (
                    <EyeIcon className="h-6 w-6 text-custom_gray" />
                  )}
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 hover:bg-transparent border-gray-300 focus:border-0 focus:outline-0 hover:text-custom_blue bg-transparent checked:bg-custom_dark_blue checked:border-custom_gray"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-3 block text-sm font-outfit leading-6 text-custom_gray"
                >
                  Remember password
                </label>
              </div>

              <div className="text-sm leading-6">
                <Link
                  to="/forgotpassword"
                  className="font-light text-custom_blue font-outfit"
                >
                  Forgot password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={email === "" || password === ""}
                className="disabled:opacity-50 disabled:cursor-not-allowed flex w-full justify-center rounded-md bg-custom_deep_blue px-3 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <MoonLoader
                  color="#ffffff"
                  loading={loading}
                  className="me-3"
                  size={20}
                />
                <span>Login</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
