 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#E96D17"
      d="M12.28 11.373a3.8 3.8 0 1 1 3.84-3.8 3.827 3.827 0 0 1-3.84 3.8Zm0-6.266a2.466 2.466 0 1 0 2.507 2.466 2.494 2.494 0 0 0-2.507-2.466Z"
    />
    <path
      fill="#E96D17"
      d="M12.28 11.373a3.8 3.8 0 1 1 3.84-3.8 3.827 3.827 0 0 1-3.84 3.8Zm0-6.266a2.466 2.466 0 1 0 2.507 2.466 2.494 2.494 0 0 0-2.507-2.466Zm2.327 7.16A13.733 13.733 0 0 0 5.94 13.6a1.18 1.18 0 0 0-.607 1.067v2.373a.667.667 0 1 0 1.334 0v-2.287a12.614 12.614 0 0 1 8-1.12l-.06-1.366Z"
    />
    <path
      fill="#E96D17"
      d="M22 15.167h-4.467v-.987a.667.667 0 1 0-1.333 0v.987h-4.867a.667.667 0 0 0-.666.666V22.5a.667.667 0 0 0 .666.667H22a.667.667 0 0 0 .667-.667v-6.667a.667.667 0 0 0-.667-.666Zm-.667 6.666H12V16.5h4.2v.273a.666.666 0 1 0 1.333 0V16.5h3.8v5.333Z"
    />
    <path
      fill="#E96D17"
      d="M14.54 18.78h3.973v.933H14.54v-.933ZM7.227 8.66a12 12 0 0 0-5.3 1.333 1.113 1.113 0 0 0-.594.98v2.067a.667.667 0 1 0 1.334 0v-1.933A10.666 10.666 0 0 1 7.72 9.993a4.853 4.853 0 0 1-.493-1.333Zm14.846 1.327a11.866 11.866 0 0 0-4.746-1.334 4.977 4.977 0 0 1-.487 1.334c1.562.037 3.096.42 4.493 1.12v1.933a.667.667 0 0 0 1.334 0v-2.067a1.113 1.113 0 0 0-.594-.986ZM7.107 7.573v-.446a2.047 2.047 0 0 1 .36-4.074 2.1 2.1 0 0 1 1.466.594 5.44 5.44 0 0 1 1.134-.72 3.42 3.42 0 0 0-6 2.18A3.4 3.4 0 0 0 7.2 8.44a4.946 4.946 0 0 1-.093-.867Zm9.406-5.853a3.447 3.447 0 0 0-2.46 1.033c.451.164.877.388 1.267.667a2.093 2.093 0 0 1 3.287 1.68 2.06 2.06 0 0 1-1.194 1.847 4.743 4.743 0 0 1-.026 1.42A3.4 3.4 0 0 0 19.94 5.1a3.413 3.413 0 0 0-3.427-3.38Z"
    />
  </svg>
);
export default SvgComponent;
