import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import EmptyInterface from "@components/empty";
import CurrentPositionsTable from "./dataview";
import data from "@components/constants/data.json";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import CopyIcon from "@components/svgs/copy";
import {useNavigate} from "react-router-dom";

function projects() {
  const navigate = useNavigate();

  const handleAddProject = () => {
    navigate(`/area/tasks/addtask`);
  };

  return (
    <div>
      <div className="font-outfit mt-6">
        <div className="">
          <div className={` ${data && "bg-white"} rounded-xl my-2`}>
            <div className="px-5 py-4 flex justify-between items-center">
              <div className="w-1/3">
                <form className="relative h-full flex w-full items-center flex-1">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#657A93]"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block bg-transparent py-3 rounded-lg w-full border border-[#657A9345] h-full pl-12 pr-0  placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search here..."
                    type="search"
                    name="search"
                  />
                </form>
              </div>
              <div className="flex gap-x-3 items-start">
                <CopyIcon />
                <ShareIcon />
                <NewPrint />
                <button
                  onClick={handleAddProject}
                  className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>Add a new task</span>
                </button>
              </div>
            </div>
            <div className="">
              {data.length === 0 ? (
                <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                  <EmptyInterface
                    description="No positions created yet..."
                    buttonText="Add a new position"
                    showButton={false}
                    buttonAction={() => null}
                  />
                </div>
              ) : (
                <div>
                  <div className="">
                    <CurrentPositionsTable />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default projects;
