import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M5.513.079H5.44c-.043.001-.07.003-.096.005a1.27 1.27 0 0 0-.3.005h-.001C3.453.295 2.278 1.82 2.278 3.753c0 .838.212 1.676.591 2.35a3.237 3.237 0 0 0-1.289.943c-.468.562-.777 1.266-.99 2.039C.165 10.63.116 12.47.116 14.129v.438h1.971l.493 7.665h17.07l.493-7.665h1.97v-.438c0-1.66-.049-3.499-.474-5.044-.213-.773-.522-1.477-.99-2.039a3.235 3.235 0 0 0-1.29-.942c.38-.675.592-1.513.592-2.35 0-1.933-1.175-3.459-2.763-3.665h-.001l-.043-.005h-.003a1.295 1.295 0 0 0-.16-.005c-.041.001-.068.003-.094.005a1.294 1.294 0 0 0-.254 0H5.599a1.3 1.3 0 0 0-.086-.005ZM5.344.96h.028c.054 0 .107.004.16.01h11.165c.054-.006.107-.01.16-.01h.06c.25 0 .505.066.743.178l.004-.007c.813.385 1.411 1.331 1.411 2.622 0 .875-.322 1.808-.748 2.321l-.475.573.731.137c.6.113 1.035.393 1.393.823.357.429.627 1.017.818 1.71.349 1.265.427 2.859.438 4.374h-1.911l-.493 7.665H3.401l-.492-7.665H.998c.011-1.515.089-3.109.438-4.373.19-.694.46-1.282.818-1.711.358-.43.792-.71 1.392-.822l.732-.138-.475-.573c-.426-.513-.748-1.446-.748-2.32 0-1.293.6-2.24 1.413-2.624l.003.008c.239-.112.494-.177.744-.177h.029Zm1.624.886c.184.254.332.555.43.898h7.436c.098-.343.245-.644.43-.898H6.968ZM7.53 3.62l.002.133c0 .254-.028.512-.077.764h7.32a4.042 4.042 0 0 1-.075-.897H7.53Zm-.343 1.773c-.115.266-.252.5-.403.681l-.18.217h9.021l-.18-.217a2.853 2.853 0 0 1-.402-.68H7.188Zm.777 1.774c.172.122.326.269.469.44.114.137.219.29.315.457h4.733c.097-.167.202-.32.316-.457.142-.171.297-.318.469-.44H7.965Zm1.172 1.774a8.39 8.39 0 0 1 .24.897h3.478a6.98 6.98 0 0 1 .24-.897H9.137Zm.385 1.773c.038.293.068.593.091.897h3.005c.023-.304.053-.604.091-.897H9.522Zm.141 1.774c.012.299.019.598.023.897h2.86c.004-.299.01-.598.022-.898H9.663Zm-1.92 1.773-.058.898h6.862l-.058-.898H7.743Zm-.115 1.774-.057.897h7.09l-.058-.897H7.628Zm-.113 1.773-.058.898h7.318l-.058-.898H7.515ZM7.4 19.582l-.057.897h7.546l-.058-.897h-7.43Z"
    />
  </svg>
);
export default SvgComponent;
