import EmptyInterface from "@components/empty";
 import TaskList from "./dataview";
 import data from "./dataview/data.json";

 function Tasks() {
   return (
     <div className="mt-4 py-2 bg-white rounded-xl">
       <div className="mt-4 rounded-xl">
         {data?.length > 0 ? (
           <TaskList />
         ) : (
           <EmptyInterface
             description="No tasks to show..."
             buttonText="Add a task"
             showButton={true}
             buttonAction={() => null}
           />
         )}
       </div>
     </div>
   );
 }

export default Tasks;
