import Input from "@components/input";
import CandidatesIcon from "@components/svgs/teamsbig";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";

function BlogSettings() {
  const navigate = useNavigate();
  return (
    <div className="mt-4">
      <div className="bg-[#DDEFFF33] rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Edit blog type</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-gray-400 hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 bg-[#DDEFFF33] px-4 py-4 rounded-xl mt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <div className="col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Name of blog type:
          </label>
          <Input
            placeholder={"eg. Educative"}
            className={"mt-1"}
            defaultValue={"Affiliate blogs "}
          />
        </div>

        <div className="col-span-4">
          <div className="mt-1">
            <textarea
              rows={6}
              placeholder="Eg. Blog type description......."
              defaultValue={
                "Affiliate blogs review and recommend products to educate consumers about which products or services they should purchase."
              }
              className="mt-1 w-full rounded-xl bg-transparent border placeholder:text-[#9CA1AD] border-[#657A9345]"
            />
          </div>
        </div>

        <div className="col-span-4">
          <div className="mt-8 flex justify-end">
            <div>
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => null}
              >
                Update blog type
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogSettings;
