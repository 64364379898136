 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#7B61FF"
      d="M20 10.49C20 4.975 15.52.5 10 .5S0 4.975 0 10.49c0 3.037 1.38 5.775 3.54 7.612.02.02.04.02.04.04.18.14.36.28.56.42.1.06.18.14.28.22a9.975 9.975 0 0 0 11.2 0c.1-.06.18-.14.28-.2.18-.14.38-.28.56-.42.02-.02.04-.02.04-.04 2.12-1.858 3.5-4.595 3.5-7.632Zm-10 8.751c-1.88 0-3.6-.6-5.02-1.599.02-.16.06-.318.1-.478.12-.434.294-.85.52-1.239.22-.38.48-.72.8-1.02.3-.3.66-.579 1.02-.799.38-.22.78-.38 1.22-.5.443-.12.9-.18 1.36-.178a5.231 5.231 0 0 1 3.66 1.457c.46.46.82 1 1.08 1.619.14.36.24.74.3 1.138a8.787 8.787 0 0 1-5.04 1.6Zm-3.06-9.25a3.113 3.113 0 0 1-.26-1.28c0-.438.08-.879.26-1.279.18-.4.42-.758.72-1.058.3-.3.66-.539 1.06-.719.4-.18.84-.26 1.28-.26.46 0 .88.08 1.28.26s.76.42 1.06.719c.3.3.54.66.72 1.059.18.4.26.84.26 1.278 0 .46-.08.88-.26 1.279a3.55 3.55 0 0 1-.72 1.06 3.55 3.55 0 0 1-1.06.719c-.826.34-1.754.34-2.58 0a3.55 3.55 0 0 1-1.06-.719 3.152 3.152 0 0 1-.7-1.059Zm9.28 6.633c0-.04-.02-.06-.02-.1a6.522 6.522 0 0 0-.86-1.758 6.102 6.102 0 0 0-1.36-1.418 6.509 6.509 0 0 0-1.3-.76c.21-.139.404-.3.58-.48.298-.294.56-.623.78-.98a4.53 4.53 0 0 0 .66-2.417 4.637 4.637 0 0 0-.36-1.837 4.749 4.749 0 0 0-1.02-1.499 4.806 4.806 0 0 0-1.5-1 4.65 4.65 0 0 0-1.84-.359 4.65 4.65 0 0 0-1.84.36 4.539 4.539 0 0 0-1.5 1.019 4.8 4.8 0 0 0-1 1.499 4.637 4.637 0 0 0-.36 1.837c0 .44.06.86.18 1.259.12.42.28.8.5 1.159.2.36.48.68.78.98.18.18.38.338.6.479a6.4 6.4 0 0 0-1.3.78c-.52.4-.98.878-1.36 1.398a6.248 6.248 0 0 0-.86 1.758c-.02.04-.02.08-.02.1-1.58-1.599-2.56-3.756-2.56-6.154 0-4.815 3.94-8.751 8.76-8.751s8.76 3.936 8.76 8.751a8.7 8.7 0 0 1-2.54 6.134Z"
    />
  </svg>
);
export default SvgComponent;
