import moment from "moment";
import chaticon from "@assets/svgs/chat.svg";
import gmail from "@assets/svgs/gmail.svg";
import call from "@assets/svgs/call.svg";
import meet from "@assets/svgs/meet.svg";
import {Fragment} from "react/jsx-runtime";
import {Suspense, lazy, useState} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import CandidatesIcon from "@components/svgs/teamsbig";
import {TopLoader} from "@components/loaders/toploader";

const AddNote = lazy(() => import("./addnote"));

const notes = [
  {
    id: 1,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: chaticon,
    author: "Liam Summers",
    border: "border-custom_yellow",
    bg: "bg-custom_yellow",
    text: "text-custom_yellow",
  },
  {
    id: 2,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: gmail,
    author: "Liam Summers",
    border: "border-custom_deep_purple",
    bg: "bg-custom_deep_purple",
    text: "text-custom_deep_purple",
  },
  {
    id: 3,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: call,
    author: "Liam Summers",
    border: "border-card_pink",
    bg: "bg-card_pink",
    text: "text-card_pink",
  },
  {
    id: 4,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: meet,
    author: "Liam Summers",
    border: "border-custom_green",
    bg: "bg-custom_green",
    text: "text-custom_green",
  },
];

function Notes() {
  const [selected, setSelected] = useState(notes[0]);
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add Position</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="mt-6 py-2 bg-custom_faded_blue rounded-xl">
        {/* left pane to contain cards */}
        <div className="grid grid-cols-8 gap-10">
          <div className="col-span-3 max-h-[75vh] pe-5 overflow-y-scroll gap-2 flex flex-col">
            <form className="sticky bg-white top-0 h-full flex w-full items-center flex-1">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#657A93]"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block bg-transparent py-3 rounded-lg w-full border border-[#657A9345] h-full pl-12 pr-0  placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search here..."
                type="search"
                name="search"
              />
            </form>
            {notes?.map((note) => (
              <Fragment key={note?.id}>
                <NoteCard
                  note={note}
                  handleSelection={() => setSelected(note)}
                />
              </Fragment>
            ))}
          </div>

          {/* right pane to contain details */}
          <div className="col-span-5">
            <div className="flex justify-end pe-3 mt-3">
              <button
                onClick={() => setShow(true)}
                className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                    fill="white"
                  />
                </svg>
                <span>Add a note</span>
              </button>
            </div>

            <h1
              className={`text-2xl ${selected?.text} font-outfit font-semibold`}
            >
              {selected?.companyName}
            </h1>
            <div className="mt-4">
              <div className="flex flex-col gap-3">
                <p className="text-black">{selected?.author}</p>
                <p className="text-black font-light text-sm">
                  {moment()?.format("DD/MM/YYYY HH:MMa")}
                </p>
              </div>
            </div>

            <div className="mt-10">
              <p className="text-2xl font-semibold text-black mt-2">
                {selected?.title}
              </p>
              <p className="text-[#1C1C1C66] mt-2">{selected.description}</p>
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<TopLoader />}>
        <AddNote open={show} setOpen={setShow} />
      </Suspense>
    </>
  );
}

export default Notes;

const NoteCard = ({note, handleSelection}: any) => {
  return (
    <div
      onClick={handleSelection}
      className={`bg-white rounded-xl cursor-pointer select-none border p-4 ${note?.border}`}
    >
      <div className="flex justify-between items-center">
        <span
          className={`"font-outfit text-sm font-light ${note?.bg} px-3 py-1 rounded-xl text-white`}
        >
          {note?.companyName}
        </span>
        <div className="">
          <img src={note.icon} alt="chat" />
        </div>
      </div>
      <div className="mt-4">
        <p className=" text-black mt-2">{note?.title}</p>
        <p className="text-sm text-[#1C1C1C66] mt-2 line-clamp-3">
          {note.description}
        </p>
      </div>

      <div className="mt-4">
        <div className="flex justify-between items-center gap-3">
          <p className="text-black font-light text-sm">{note.author}</p>
          <p className="text-black font-light text-sm">
            {moment()?.format("DD/MM/YYYY HH:MMa")}
          </p>
        </div>
      </div>
    </div>
  );
};
