import TalentPools from "./dataview";
import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import EmptyInterface from "@components/empty";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import ColoredDropdown from "@components/dropdowns/colored";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const list = [
  {id: 1, name: "All", value: "all", count: 73},
  {id: 2, name: "UI/UX Designer", value: "ui/ux designer", count: 23},
  {id: 3, name: "Software Engineer", value: "software engineer", count: 23},
  {id: 4, name: "Business Assistant", value: "business assistant", count: 23},
];
function Page() {
  const [selected, setSelected] = useState("All");
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="flex mt-2 justify-between">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>
      <div className="grid grid-cols-3 my-8  gap-8">
        <div className="col-span-2">
          <ColoredDropdown
            selected={selected}
            setSelected={setSelected}
            list={list}
          />
        </div>
        <div className="col-span-1 ">
          <form className="relative h-full flex w-full items-center flex-1">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#657A93]"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block bg-transparent rounded-lg w-full border border-gray-100 h-full pl-12 pr-0  placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search here..."
              type="search"
              name="search"
            />
          </form>
        </div>
      </div>
      <div className={` ${data && "bg-white"}`}>
        <div className="px-5 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-outfit font-semibold">Talent Pools</h1>
          <div className="flex gap-x-3 items-center">
            <CopyIcon />
            <ShareIcon />
            <PrintIcon />
          </div>
        </div>
        <div className="">
          {data.length === 0 ? (
            <div className="mt-4 border border-custom_lighter_gray rounded-xl">
              <EmptyInterface
                description="No tasks assigned yet..."
                buttonText="Add a new task"
                showButton={false}
                buttonAction={() => null}
              />
            </div>
          ) : (
            <div>
              <div className="mt-4">
                <TalentPools />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Page;
