import EmptyInterface from "@components/empty";
import {ArrowRightIcon} from "@heroicons/react/24/outline";
import TaskList from "./dataview";
import {useNavigate} from "react-router-dom";
import data from "./dataview/data.json";
function LeaveSummary() {
  const navigate = useNavigate();
  const handleAddTask = () => {
    navigate("/people/leave/leave-type");
  };
  return (
    <div className={`mt-8 rounded-xl ${data && "bg-white"}`}>
      <div className="px-5 py-4 flex justify-between items-center">
        <h1 className="text-2xl font-outfit font-semibold">Employee leave </h1>
        <div className="flex gap-x-3 items-center">
          <button
            onClick={handleAddTask}
            className="bg-custom_dark_blue text-white px-4 py-2 rounded-lg flex items-center gap-3"
          >
            <span>view more list</span>
            <ArrowRightIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="mt-8">
        {data.length === 0 ? (
          <div className="mt-4 border border-custom_lighter_gray rounded-xl">
            <EmptyInterface
              description="No tasks assigned yet..."
              buttonText="Add a new task"
              buttonAction={handleAddTask}
            />
          </div>
        ) : (
          <div>
            <div className="mt-4">
              <TaskList />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LeaveSummary;
