"use client";
import EmployeeStats from "@components/stats/employeestats";
 
import EmployeeIcon from "@components/svgs/bigemployee";
import SearchIcon from "@components/svgs/search";
import Input from "@components/input";
import Dropdown from "@components/dropdowns/dropdown";
import EmptyInterface from "@components/empty";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";

import EmployeeList from "./dataview";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import NewPrint from "@components/svgs/newprint";
import ConfirmDelete from "@components/ConfirmDelete";
import {useQuery} from "@tanstack/react-query";
import {getUsers} from "@services/users";
import TableLoader from "@components/tableLoader";

function Users() {
  const [selected, setSelected] = useState("");
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const list = [
    {id: 1, name: "Admin", value: "admin"},
    {id: 2, name: "Client", value: "client"},
    {id: 3, name: "Employee", value: "employee"},
    {id: 4, name: "Manager", value: "manager"},
  ];
  const [showDelete, setShowDelete] = useState(false);

  const navigate = useNavigate();

  const handleAddEmployee = () => {
    navigate("addemployee");
  };

  const handleDelete = (data: any) => {
    setSelectedTask(data);
    setShowDelete(true);
  };

  const {data, isLoading} = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <EmployeeStats />
          </div>
          <EmployeeIcon />
        </div>
      </div>

      <div className="bg-[#DDEFFF33] mt-4 py-3 px-3 mx-4 rounded-lg font-inter">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3 w-1/2">
            <div className="relative w-2/4">
              <SearchIcon className="absolute top-3 left-3" />

              <Input placeholder="Find user..." className={`ps-10 text-sm`} />
            </div>

            <div className="w-40">
              <Dropdown
                selected={selected}
                setSelected={setSelected}
                list={list}
              />
            </div>
          </div>
          <div className="flex">
            <button
              onClick={handleAddEmployee}
              className=" flex items-center gap-3 bg-custom_deep_blue rounded-xl text-sm px-4 font-outfit  py-3 text-white"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                  fill="white"
                />
              </svg>
              <span>Add new employee</span>
            </button>
          </div>
        </div>

        <div className={`mt-8 rounded-xl ${data && "bg-white"}`}>
          <div className="px-5 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-outfit font-semibold">Employees</h1>
            <div className="flex gap-x-3 items-center">
              <CopyIcon />
              <ShareIcon />
              <NewPrint />
            </div>
          </div>
          <div className="">
            {isLoading ? (
              <TableLoader />
            ) : data?.length === 0 ? (
              <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                <EmptyInterface
                  description="No tasks assigned yet..."
                  buttonText="Add a new task"
                  buttonAction={handleAddEmployee}
                />
              </div>
            ) : (
              <div>
                <div className="">
                  <EmployeeList
                    handleDelete={handleDelete}
                    users={data?.data}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ConfirmDelete
        open={showDelete}
        setOpen={setShowDelete}
        selected={selected}
        title="Are you sure you want to delete employee"
        quotedText={selectedTask?.name}
      />
    </>
  );
}

export default Users;
