import React from "react";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
import CandidatesIcon from "@components/svgs/teamsbig";

import MyEditor from "@components/editor";
const list = [
  {id: 1, name: "Admin", value: "admin"},
  {id: 2, name: "Client", value: "client"},
  {id: 3, name: "Employee", value: "employee"},
  {id: 4, name: "Manager", value: "manager"},
];

function AddProduct() {
  const [selected, setSelected] = React.useState("");
  const [jobDescription, setJobDescription] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [skills, setSkills] = React.useState("");
  const [systems, setSystems] = React.useState("");

  return (
    <div className="mt-4">
      <div className="bg-[#DDEFFF33] rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add a new product</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 bg-[#DDEFFF33] px-4 py-4 rounded-xl mt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <div className="md:col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Role:{" "}
          </label>
          <Input placeholder={"eg. Video Editor"} className={"mt-1"} />
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Position:{" "}
          </label>
          <Input placeholder={"eg. 0"} className={"mt-1"} />
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Type:{" "}
          </label>
          <Dropdown list={list} selected={selected} setSelected={setSelected} />
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Part time:{" "}
          </label>
          <Input placeholder={"eg. $ 1,000.00"} className={"mt-1"} />
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Full time:{" "}
          </label>
          <Input placeholder={"eg. $ 1,000.00"} className={"mt-1"} />
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Job description:{" "}
          </label>
          <div className="overflow-scroll">
            <MyEditor
              value={jobDescription}
              setValue={setJobDescription}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Summary:{" "}
          </label>
          <div className="">
            <MyEditor
              value={summary}
              setValue={setSummary}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Skills:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={skills}
              setValue={setSkills}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Systems:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={systems}
              setValue={setSystems}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>

        <div className="col-span-4">
          <div className="mt-8 flex justify-end">
            <div>
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => null}
              >
                Add product
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
