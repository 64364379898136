import CandidatesIcon from "@components/svgs/teamsbig";
import data from "@components/constants/data.json";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import EmptyInterface from "@components/empty";
import JobTable from "./dataview";
import {Suspense, useState} from "react";
import {TopLoader} from "@components/loaders/toploader";
import ViewJD from "./viewjd";
import {useNavigate, useParams} from "react-router-dom";

function Jobs() {
  const [show, setShow] = useState(false);

  const showJD = () => {
    setShow(true);
  };

  const navigate = useNavigate();


  const {id} = useParams();

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 px-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <h2 className="font-normal text-2xl">Applications</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex ps-4 justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-1">
        <div className="font-outfit">
          <div className="">
            <div className={` ${data && "bg-white"} rounded-xl my-2`}>
              <div className="px-5 py-4 flex justify-between items-center">
                <h1 className="text-2xl font-outfit font-semibold">{id}</h1>
                <div className="flex gap-x-3 items-start">
                  <CopyIcon />
                  <ShareIcon />
                  <NewPrint />
                </div>
              </div>
              <div className="">
                {data.length === 0 ? (
                  <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                    <EmptyInterface
                      description="No positions created yet..."
                      buttonText="Add a new position"
                      showButton={false}
                      buttonAction={() => null}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="">
                      <JobTable showJD={showJD} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={<TopLoader />}>
        <ViewJD show={show} setShow={setShow} />
      </Suspense>
    </>
  );
}

export default Jobs;
