import axiosInstance from "..";
import {LoginResponse} from "../types";

export const login = async (
  email: string,
  password: string
): Promise<LoginResponse> => {
  const response = await axiosInstance.post(`user/log_in/${email}/${password}`);
  return response.data;
};
