import Graphs from "./graphs/graphs";
import Section1 from "./section1";
import Task from "./tasks/page";
import TimeReport from "./timereport/page";

function CurrentPosition() {
  return (
    <div className="mt-6">
      <Section1 />

      <Graphs />

      <TimeReport />

      <Task />
    </div>
  );
}

export default CurrentPosition;
