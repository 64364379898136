import {Fragment} from "react";
import moment from "moment";
import {TrashIcon} from "@heroicons/react/24/outline";

function Step2() {
  return (
    <div className="px-5 bg-white col-span-2">
      <div className="-mx-4 sm:-mx-0">
        <table className="min-w-full divide-y divide-[#E6EDFF]">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Day off
              </th>
              <th
                scope="col"
                className="hidden py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Morning
              </th>
              <th
                scope="col"
                className="hidden py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Afternoon
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Remove day
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-[#E6EDFF] bg-white">
            {[1, 3, 4, 5, 6, 7, 8]?.map((task) => (
              <Fragment key={task}>
                <tr className="cursor-pointer hover:bg-gray-50">
                  <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {moment()?.add(task, "days")?.format("DD/MM/YYYY")}
                  </td>
                  <td className="whitespace-nowrap py-4  text-sm font-medium text-gray-900 sm:pl-0">
                    <input
                      type="checkbox"
                      className=" focus:ring-0 check:border checked:text-black  focus:outline-none"
                    />
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-0">
                    <input
                      type="checkbox"
                      className=" focus:ring-0 check:border checked:text-black  focus:outline-none"
                    />
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell">
                    <TrashIcon
                      //   onClick={() => props.handleDelete()}
                      className="text-[#E94A74] h-5 w-5"
                    />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Step2;
