import React, {Fragment, Suspense, lazy} from "react";
import BlogStats from "@components/stats/blogstats";
import BlogStatsBigIcon from "@components/svgs/blogstatsbigicon";
import Blog from "@components/cards/blog";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import { TopLoader } from "@components/loaders/toploader";
import { useNavigate } from "react-router-dom";


const blogs = [
  {
    id: 1,
    title: "Unlocking Innovation:",
    subtext: "The Power of African Software Development Talent",
    image:
      "https://i0.wp.com/europeansting.com/wp-content/uploads/2021/04/innovations-1.jpeg?resize=610%2C763&ssl=1",
  },
  {
    id: 2,
    title: "Embracing Diversity:",
    subtext: "The Power of African Software Development Talent",
    image:
      "https://volteuropa.org/img/containers/assets/images/news_images/two_linked_hands.jpg/2ab3771fa015a87205e299a1c638e439.jpg",
  },
  {
    id: 3,
    title: "Unlocking Business Potential:",
    subtext: "Embracing the Power of Outsourcing Over In-House Operations",
    image:
      "https://imageio.forbes.com/specials-images/imageserve/616afe232d6595e2bc4d1790/Smiling-business-woman/960x0.jpg?format=jpg&width=1440",
  },
  {
    id: 4,
    title: "Unlocking Business Potential:",
    subtext: "Embracing the Power of Outsourcing Over In-House Operations",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*kSfnUTh0AK24WnVGIYhGJA.jpeg",
  },
  {
    id: 5,
    title: "Investing in Africa's Future:",
    subtext: "Empowering Growth Through Employment",
    image: "https://jucfoundationuganda.org/wp-content/uploads/2023/08/afr.jpg",
  },
  {
    id: 6,
    title: "Leveraging African Talent in Telesales:",
    subtext: "A Gateway to Global Success",
    image:
      "https://www.maplecashloan.com/wp-content/uploads/elementor/thumbs/1686153105-customer-service-culture-g-1366016998-qe36prhpnxesa7xg4utr847acuccbn6mv550ro33s0.jpg",
  },
];

const Edit = lazy(() => import("./edit"));
const Delete = lazy(() => import("./delete"));

function Blogs() {
  const [show, setShow] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [selected, setSelected] = React.useState(null);


  const navigate = useNavigate();
  const handleEdit = (data: any) => {
    setSelected(data);
    setShow(true);
  };

  const handleDelete = (data: any) => {
    setSelected(data);
    setShowDelete(true);
  };

  return (
    <div>
      <>
        <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
          <div className="flex justify-between items-center pe-6">
            <div className="">
              <BlogStats />
            </div>
            <BlogStatsBigIcon />
          </div>
        </div>
        <div className="my-4">
          <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-3">
            <div className="mb-5 flex justify-between items-center">
              <div className="w-1/3">
                <form className="relative h-full flex w-full items-center flex-1">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#657A93]"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block bg-transparent py-3 rounded-lg w-full border border-[#657A9345] h-full pl-12 pr-0  placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search here..."
                    type="search"
                    name="search"
                  />
                </form>
              </div>
              <div className="flex gap-x-3 items-center">
                <CopyIcon />
                <ShareIcon />
                <NewPrint />

                <button
                  onClick={() =>  navigate("addblog")}
                  className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>Add blog post</span>
                </button>
              </div>
            </div>
            <div className="font-outfit">
              <div className=" bg-[#DDEFFF33]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
                  {blogs?.map((blog) => (
                    <Fragment key={blog?.id}>
                      <Blog
                        data={blog}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                      />
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Suspense fallback={<TopLoader />}>
        <Edit open={show} setOpen={setShow} />
        <Delete open={showDelete} setOpen={setShowDelete} selected={selected} />
      </Suspense>
    </div>
  );
}

export default Blogs;
