import MonthSelector from "@components/dropdowns/monthselector";
import {useState} from "react";

import {ResponsiveContainer, PieChart, Pie, Cell} from "recharts";
function PieChartGraph() {
  const data = [
    {name: "Group B", value: 1000},
    {name: "Group A", value: 5200},
  ];

  const COLORS = ["#6AD2FF", "#4318FF"];
  const [selectedMonth, setSelectedMonth] = useState("January");
  return (
    <div className="col-span-3 flex flex-col gap-y-4 bg-white rounded-xl px-3 py-3">
      <div>
        <MonthSelector
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      </div>

      <div className="w-full flex items-center justify-center">
        <ResponsiveContainer width="100%" height={200}>
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="flex justify-between gap-x-8">
        <div className="flex items-center gap-2">
          <svg
            width="12"
            height="12"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#4318FF" />
          </svg>

          <div className="flex flex-col">
            <span className="font-outfit text-2xl font-bold text-[#2B3674]">
              5200
            </span>
            <span className="text-xs">Total people in talent pool</span>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <svg
            width="12"
            height="12"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#6AD2FF" />
          </svg>

          <div className="flex flex-col">
            <span className="font-outfit text-2xl font-bold text-[#2B3674]">
              1000
            </span>
            <span className="text-xs">Total assigned candidates</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PieChartGraph;
