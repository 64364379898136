import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
function YearGraph() {
  const data = [
    {
      name: "Jan",
      report: 1860,
    },
    {
      name: "Feb",
      report: 3000,
    },
    {
      name: "Mar",
      report: 2000,
    },
    {
      name: "Apr",
      report: 220,
    },
    {
      name: "May",
      report: 2000,
    },
    {
      name: "June",
      report: 278,
    },
    {
      name: "July",
      report: 270,
    },
    {
      name: "Aug",
      report: 780,
    },
    {
      name: "Sep",
      report: 280,
    },
    {
      name: "Oct",
      report: 780,
    },
    {
      name: "Nov",
      report: 1780,
    },
    {
      name: "Dec",
      report: 500,
    },
  ];
  return (
    <div className="col-span-5 flex flex-col justify-between bg-white rounded-xl px-3 py-3">
      <div className="flex justify-between gap-y-4 px-3">
        <h1 className="font-outfit font-bold text-black">
          THIS YEARS TIME REPORT (2024)
        </h1>
      </div>

      <div className="w-full mt-10">
        <ResponsiveContainer height={300} width={"100%"}>
          <BarChart height={300} data={data}>
            <XAxis dataKey="name" fontSize={12} />
            <YAxis fontSize={12} />
            <Tooltip />

            <Bar
              dataKey="report"
              fill="#A1E3CB"
              activeBar={<Rectangle fill="#A1E3CB" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default YearGraph;
