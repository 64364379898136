import CustomDatePicker from "@components/datepicker";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
import {useState} from "react";


function Step1() {
  const [type, setType] = useState("Sickness");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  return (
    <>
      <div>
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Start date:{" "}
        </label>
        <CustomDatePicker
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        />
      </div>
      <div>
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          End date:{" "}
        </label>
        <CustomDatePicker selectedDate={endDate} setSelectedDate={setEndDate} />
      </div>

      <div>
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Time off category:{" "}
        </label>
        <Dropdown
          list={[
            {
              id: "holiday",
              name: "Holiday",
              value: "Holiday",
            },
            {
              id: "sickness",
              name: "Sickness",
              value: "Sickness",
            },
          ]}
          selected={type}
          setSelected={setType}
        />
      </div>

      <div>
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Number of hours:{" "}
        </label>
        <Input type={"number"} placeholder="Eg. 1" />
      </div>

      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Reason:{" "}
        </label>
        <div className="mt-1 relative">
          <textarea
            rows={6}
            placeholder="Write Notes here...."
            className="mt-1 w-full rounded-xl bg-transparent border py-4 placeholder:text-[#9CA1AD] border-[#657A9345]"
          />
        </div>
      </div>
    </>
  );
}

export default Step1;
