import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import EmptyInterface from "@components/empty";

import TeamList from "./dataview";
import {TopLoader} from "@components/loaders/toploader";
import {Suspense, lazy, useState} from "react";
import {useNavigate} from "react-router-dom";
import NewPrint from "@components/svgs/newprint";
import ProductsStats from "@components/stats/leadsstats";
import ProductsStatsBigIcon from "@components/svgs/productsstatsbig";

const Delete = lazy(() => import("./edit/delete"));

function Page() {
  const navigate = useNavigate();

  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);

  const onDelete = (data: any) => {
    setSelected(data);
    setShowDelete(true);
  };

  const handleAddNewLead = () => {
    navigate("addlead");
  };

  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33] rounded-lg font-inter">
        <div className="flex justify-between items-center pe-6">
          <div className="">
            <ProductsStats />
          </div>
          <ProductsStatsBigIcon />
        </div>
      </div>

      <div className="bg-[#DDEFFF33] mt-4 p-3">
        <div className={` ${data && "bg-white"}`}>
          <div className="px-5 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-outfit font-semibold">Leads</h1>

            <div className="flex gap-x-3 items-center">
              <CopyIcon />
              <ShareIcon />
              <NewPrint />
              <button
                onClick={handleAddNewLead}
                className="bg-custom_deep_blue text-sm text-white px-4 py-2 rounded-lg flex items-center gap-3"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                    fill="white"
                  />
                </svg>
                <span>Add a new lead</span>
              </button>
            </div>
          </div>
          <div className="">
            {data.length === 0 ? (
              <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                <EmptyInterface
                  description="No tasks assigned yet..."
                  buttonText="Add a new task"
                  showButton={false}
                  buttonAction={() => null}
                />
              </div>
            ) : (
              <div>
                <div className="mt-4">
                  <TeamList onDelete={onDelete} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Suspense fallback={<TopLoader />}>
        <Delete open={showDelete} setOpen={setShowDelete} selected={selected} />
      </Suspense>
    </div>
  );
}

export default Page;
