function TableIcon(props: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3333 12.5007V7.50065C18.3333 3.33398 16.6667 1.66732 12.5 1.66732H7.5C3.33333 1.66732 1.66666 3.33398 1.66666 7.50065L1.66666 12.5007C1.66666 16.6673 3.33333 18.334 7.5 18.334H12.5C16.6667 18.334 18.3333 16.6673 18.3333 12.5007Z"
        stroke={props?.fill || "#7E879F"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.66666 10L18.3333 10"
        stroke={props?.fill || "#7E879F"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default TableIcon;
