import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={185}
    height={95}
    fill="none"
    {...props}
  >
    <path fill="#E6E6E6" d="M155.704 80.403h-9.938v.445h9.938v-.445Z" />
    <path
      fill="#E96D17"
      d="M161.107 73.268v1.983a.6.6 0 0 1-.595.595h-.993a.196.196 0 0 0-.198.198v16.259a.6.6 0 0 1-.595.594H146.62a.593.593 0 0 1-.595-.594V76.044a.199.199 0 0 0-.198-.198h-1.191a.597.597 0 0 1-.595-.595v-1.983a.584.584 0 0 1 .367-.55.596.596 0 0 1 .228-.044h15.876a.597.597 0 0 1 .595.594Z"
    />
    <path
      fill="#FFB8A1"
      d="M159.321 85.015c-4.449 1.84-8.947 1.86-13.494 0v-4.19a21.208 21.208 0 0 1 13.494 0v4.19Z"
    />
    <path
      fill="#283252"
      d="M107.93 37.108a5.396 5.396 0 0 0 5.398-5.394 5.396 5.396 0 0 0-5.398-5.394 5.396 5.396 0 0 0-5.399 5.394 5.396 5.396 0 0 0 5.399 5.394Z"
    />
    <path
      fill="#283252"
      d="M109.434 26.469a5.406 5.406 0 0 1 4.635 2.63 5.385 5.385 0 0 1 .117 5.324 5.39 5.39 0 0 0-3.307-8.683 5.408 5.408 0 0 0-4.224 1.06 5.392 5.392 0 0 0-1.436 1.697 5.374 5.374 0 0 1 4.215-2.028Z"
    />
    <path
      fill="#283252"
      d="M97.841 31.703c8.759 0 15.859-7.094 15.859-15.845 0-8.751-7.1-15.845-15.859-15.845-8.758 0-15.859 7.094-15.859 15.845 0 8.75 7.1 15.845 15.86 15.845Z"
    />
    <path
      fill="#283252"
      d="M85.395 6.027a15.866 15.866 0 0 1 21.978-1.661c-.13-.124-.258-.248-.393-.368a15.867 15.867 0 0 0-22.384 1.32 15.836 15.836 0 0 0 1.31 22.365c.136.12.274.232.412.346a15.836 15.836 0 0 1-.923-22.002Z"
    />
    <path
      fill="#FFB8B8"
      d="M97.707 31.568c5.641 0 10.214-4.569 10.214-10.205s-4.573-10.205-10.214-10.205-10.214 4.569-10.214 10.205 4.573 10.205 10.214 10.205Z"
    />
    <path
      fill="#C6D3ED"
      d="m102.925 34.339-10.93.49s.912 5.649-5.002 6.186c-5.913.537-11.29-1.075-13.44 2.954-2.15 4.028-1.223 18.372-1.223 18.372s4.032 14.503 7.257 16.651c3.226 2.148 31.449-.806 31.449-.806l11.812-14.9-.4-11.492c-.209-6.002-5.693-10.507-11.599-9.396-.116.021-.233.045-.35.07 0 0-1.298-.969-4.858-2.747-2.177-1.088-2.716-5.382-2.716-5.382Z"
    />
    <path
      fill="#283252"
      d="M79.198 40.746s2.598 4.71 1.523 11.424c-1.076 6.714 3.435 20.914 3.435 20.914l3.226-.806s-2.15-13.965-1.613-17.188c.538-3.223.686-12.733-2.002-14.344-2.688-1.611-4.57 0-4.57 0ZM110.31 43.216l1.129 29.733 2.151 1.343s3.091-32.63 1.478-32.63h-3.281a1.477 1.477 0 0 0-1.477 1.554Z"
    />
    <path
      fill="#E96D17"
      d="M85.635 72.412a1.343 1.343 0 1 0 .001-2.687 1.343 1.343 0 0 0-.001 2.687ZM112.514 73.755a1.343 1.343 0 1 0 .002-2.686 1.343 1.343 0 0 0-.002 2.686Z"
    />
    <path
      fill="#283252"
      d="M88.971 8.607v10.205h2.366l3.01-3.223-.403 3.223H104.4l-.645-3.223 1.29 3.223h1.667V8.607h-17.74Z"
    />
    <path
      fill="#FFB8B8"
      d="M87.359 20.692c.445 0 .806-.661.806-1.477 0-.816-.361-1.477-.806-1.477-.446 0-.807.661-.807 1.477 0 .816.361 1.477.807 1.477ZM108.055 20.692c.446 0 .807-.661.807-1.477 0-.816-.361-1.477-.807-1.477-.445 0-.806.661-.806 1.477 0 .816.361 1.477.806 1.477Z"
    />
    <path
      fill="#283252"
      d="M130.428 90.175v.9a2.001 2.001 0 0 1-.279 1.018 1.998 1.998 0 0 1-1.713.972h-66.24a1.995 1.995 0 0 1-1.856-1.268 1.976 1.976 0 0 1-.135-.722v-.9a1.988 1.988 0 0 1 1.99-1.99h3.82v-.42a.083.083 0 0 1 .083-.083h1.992a.082.082 0 0 1 .083.083v.42h1.245v-.42a.082.082 0 0 1 .024-.058.084.084 0 0 1 .058-.025h1.993c.022 0 .043.009.059.025a.082.082 0 0 1 .024.058v.42h1.246v-.42a.082.082 0 0 1 .024-.058.083.083 0 0 1 .059-.025h1.992a.082.082 0 0 1 .083.083v.42h1.245v-.42a.082.082 0 0 1 .024-.058.083.083 0 0 1 .059-.025H78.3a.082.082 0 0 1 .083.083v.42h1.245v-.42a.082.082 0 0 1 .024-.058.082.082 0 0 1 .058-.025h1.993a.082.082 0 0 1 .083.083v.42h1.246v-.42a.082.082 0 0 1 .024-.058.082.082 0 0 1 .059-.025h1.992a.083.083 0 0 1 .083.083v.42h1.245v-.42a.082.082 0 0 1 .024-.058.082.082 0 0 1 .059-.024h15.604a.085.085 0 0 1 .059.024.087.087 0 0 1 .025.059v.419h1.246v-.42c0-.01.002-.021.006-.031.004-.01.01-.02.018-.027a.076.076 0 0 1 .027-.018.078.078 0 0 1 .031-.007h1.993c.022 0 .043.01.058.025a.082.082 0 0 1 .025.058v.42h1.244v-.42a.07.07 0 0 1 .007-.031c.004-.01.01-.02.018-.027a.072.072 0 0 1 .026-.018.083.083 0 0 1 .032-.007h1.993a.082.082 0 0 1 .058.025.087.087 0 0 1 .025.059v.419h1.244v-.42c0-.01.002-.021.006-.031a.117.117 0 0 1 .018-.027.086.086 0 0 1 .059-.025h1.992a.083.083 0 0 1 .083.083v.42h1.246v-.42a.086.086 0 0 1 .025-.058.072.072 0 0 1 .026-.018.087.087 0 0 1 .032-.007h1.993c.021 0 .042.01.058.025a.084.084 0 0 1 .023.058v.42h1.246v-.42c0-.01.002-.021.006-.031a.117.117 0 0 1 .018-.027.086.086 0 0 1 .059-.025h1.992a.083.083 0 0 1 .083.083v.42h1.245v-.42c0-.01.002-.021.006-.031a.092.092 0 0 1 .018-.027.086.086 0 0 1 .059-.025h1.992a.083.083 0 0 1 .083.083v.42h5.811a1.993 1.993 0 0 1 1.992 1.99Z"
    />
    <path
      fill="#E5E9F2"
      d="M183.975 92.95H1.025a1.025 1.025 0 0 0 0 2.05h182.95a1.025 1.025 0 0 0 0-2.05Z"
    />
    <path
      fill="#283252"
      d="M125.094 52.29H64.419a1.67 1.67 0 0 0-1.67 1.668v33.777a1.668 1.668 0 0 0 1.67 1.669h60.675a1.67 1.67 0 0 0 1.67-1.669V53.958a1.668 1.668 0 0 0-1.67-1.669Z"
    />
    <path
      fill="#E96D17"
      d="M94.805 67.787a3.707 3.707 0 0 0 3.708-3.705 3.707 3.707 0 0 0-3.708-3.705 3.707 3.707 0 0 0-3.708 3.705 3.707 3.707 0 0 0 3.708 3.705ZM119.62 41.484l13.807-13.458h6.518v-.211h-6.61l-.032.031-13.838 13.489.155.149ZM163.155 22.225h-15.679v1.396h15.679v-1.396ZM145.565 24.503h-3.319v-3.316h3.319v3.316Zm-3.104-.214h2.89v-2.888h-2.89v2.888ZM163.155 26.878h-15.679v1.396h15.679v-1.396ZM145.565 29.156h-3.319V25.84h3.319v3.316Zm-3.104-.214h2.89v-2.888h-2.89v2.888ZM163.155 31.531h-15.679v1.396h15.679V31.53ZM145.565 33.81h-3.319v-3.317h3.319v3.317Zm-3.104-.215h2.89v-2.888h-2.89v2.888ZM26.582 74.977c3.703 3.978 3.894 9.825 3.894 9.825s-5.812-.603-9.515-4.58c-3.703-3.979-3.895-9.826-3.895-9.826s5.813.603 9.516 4.58Z"
    />
    <path
      fill="#E96D17"
      d="M38.24 81.186c-3.974 3.708-9.815 3.9-9.815 3.9s.602-5.82 4.576-9.527c3.974-3.707 9.814-3.9 9.814-3.9s-.602 5.82-4.576 9.527Z"
    />
    <path
      fill="#283252"
      d="M37.252 92.994H22.636a1.494 1.494 0 0 1-1.344-.842l-3.182-6.549a1.497 1.497 0 0 1 1.344-2.15h20.98a1.494 1.494 0 0 1 1.344 2.15l-3.182 6.55a1.496 1.496 0 0 1-1.344.84Z"
    />
    <path
      fill="#EEE"
      d="M146.881 60.568c-.279.048-.594.123-.73.371-.218.394.176.838.535 1.11.663.5 1.362.99 1.813 1.688.45.699.592 1.673.099 2.342-.643.873-2.178 1.061-2.327 2.136-.095.69.501 1.28 1.105 1.626 1.49.855 3.412 1.083 4.492 2.42.446-.92 1.445-1.45 2.441-1.678.996-.227 2.03-.21 3.04-.364.326-.05.693-.15.839-.445.282-.574-.522-1.261-.245-1.837.212-.438.832-.404 1.286-.58.738-.285 1.042-1.236.833-2-.21-.764-.802-1.361-1.424-1.85-1.677-1.32-3.693-2.096-5.733-2.654-.948-.26-2.062-.666-3.047-.706-.951-.038-2.035.26-2.977.42Z"
    />
  </svg>
);
export default SvgComponent;
