import EmptyInterface from "@components/empty";
 import TaskList from "./dataview";
 import data from "./dataview/data.json";
import {Suspense} from "react";
import {TopLoader} from "@components/loaders/toploader";
import ViewJD from "./viewjd";
import React from "react";

function Tasks() {
  const [show, setShow] = React.useState(false);

  const showJD = () => {
    setShow(true);
  };

  return (
    <div className="mt-4 py-2 bg-white rounded-xl">
      <div className="mt-4 rounded-xl">
        {data?.length > 0 ? (
          <TaskList showJD={showJD} />
        ) : (
          <EmptyInterface
            description="No tasks to show..."
            buttonText="Add a task"
            showButton={true}
            buttonAction={() => null}
          />
        )}
      </div>
      <Suspense fallback={<TopLoader />}>
        <ViewJD show={show} setShow={setShow} />
      </Suspense>
    </div>
  );
}

export default Tasks;
