import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={163}
    height={144}
    fill="none"
    {...props}
  >
    <path
      fill="#FF6B93"
      d="M34.153 56.582c15.638 0 28.316-12.666 28.316-28.291S49.79 0 34.153 0C18.515 0 5.838 12.666 5.838 28.291s12.677 28.291 28.315 28.291Z"
    />
    <path
      fill="#F2F2F2"
      d="M84.945 151.954c40.95 0 74.145-33.167 74.145-74.081S125.895 3.791 84.945 3.791c-40.949 0-74.144 33.168-74.144 74.082 0 40.914 33.195 74.081 74.144 74.081Z"
    />
    <path
      fill="#283252"
      d="M127.856 37.624c5.159 0 9.341-4.178 9.341-9.333 0-5.154-4.182-9.333-9.341-9.333-5.159 0-9.341 4.179-9.341 9.333 0 5.155 4.182 9.333 9.341 9.333ZM134.132 130.518l-5.254 14.291s-4.379 3.5-1.751 4.666c2.627 1.167 7.881-1.166 7.881-1.166l4.087-10.5-.584-7.291h-4.379Z"
    />
    <path
      fill="#000"
      d="m134.132 130.518-5.254 14.291s-4.379 3.5-1.751 4.666c2.627 1.167 7.881-1.166 7.881-1.166l4.087-10.5-.584-7.291h-4.379Z"
      opacity={0.1}
    />
    <path
      fill="#C6D3ED"
      d="m134.57 45.79 4.379.584s2.043 16.041 1.167 17.208c-.875 1.166-5.254 5.833-7.005 4.666-1.752-1.166 1.459-22.458 1.459-22.458Z"
    />
    <path
      fill="#FFB8B8"
      d="M92.681 75.103s-6.13 2.625-7.297 2.916c-1.168.292-2.92 3.208-.876 3.792 2.043.583 9.925-5.25 9.925-5.25l-1.752-1.459Z"
    />
    <path
      fill="#283252"
      d="m136.176 136.351 3.211 11.958s.292 4.375 1.751 4.666c1.46.292 5.838-1.458 5.838-2.041s-1.751-5.25-1.751-5.25 0-3.5-1.751-4.958c-1.752-1.459-3.33-6.344-3.33-6.344l-3.968 1.969ZM140.263 108.935c.589 1.374.516 11.34-.129 17.972-.312 3.223-.759 5.658-1.331 5.944l-4.183-1.044-1.655-.414-.044-3.629-.248-19.996-4.963-20.708v-5.25l12.553 1.167 1.99 3.124.053.084s-2.919 20.708-2.043 22.75Z"
    />
    <path
      fill="#000"
      d="M137.635 115.351c.625.937 1.667 6.542 2.499 11.556-.312 3.223-.759 5.658-1.331 5.944l-4.183-1.044c-.064-.134-.128-.274-.196-.414a42.837 42.837 0 0 1-1.503-3.629l-.248-19.996-4.963-20.708v-5.25l12.553 1.167 1.99 3.124-11.623-.79 2.627 11.374 3.211 11.958s0 4.958 1.167 6.708Z"
      opacity={0.1}
    />
    <path
      fill="#283252"
      d="M116.91 70.144s-4.087 9.042 1.751 21.583a254.665 254.665 0 0 1 9.633 23.916s2.044 9.625 4.963 15.75c2.919 6.124 2.335 6.416 2.335 6.416l4.67-2.625s-2.627-18.083-3.794-19.833c-1.168-1.75-1.168-6.708-1.168-6.708l-3.211-11.958-2.627-11.375 12.844.875s0-16.624-5.838-19.832l-19.558 3.791Z"
    />
    <path
      fill="#FFB8B8"
      d="M121.726 32.666s0 4.667-1.459 4.958c-1.46.292-4.379.292-5.255 1.75-.875 1.459 6.714 20.708 6.714 20.708l7.882-4.958 2.627-13.125s-3.795-2.333-3.795-3.5v-5.25l-6.714-.583Z"
    />
    <path
      fill="#FFB8B8"
      d="M121.726 32.666s0 4.667-1.459 4.958c-1.46.292-4.379.292-5.255 1.75-.875 1.459 6.714 20.708 6.714 20.708l7.882-4.958 2.627-13.125s-3.795-2.333-3.795-3.5v-5.25l-6.714-.583Z"
      opacity={0.1}
    />
    <path
      fill="#FFB8B8"
      d="M126.251 37.77a7.003 7.003 0 0 0 7.006-7c0-3.866-3.137-7-7.006-7a7.003 7.003 0 0 0-7.006 7c0 3.866 3.137 7 7.006 7Z"
    />
    <path
      fill="#C6D3ED"
      d="M122.602 54.249s-2.627-16.625-3.211-16.625c-.584 0-8.757 1.75-9.341 2.042-.584.291-2.627 2.916-2.627 2.916l4.086 11.667s1.752 8.166 2.919 9.624c1.168 1.459 3.503 5.542 2.336 6.125-1.168.584-2.336 1.459-1.168 1.459s10.801-1.75 11.385-.875c.583.875 10.216-3.209 10.216-3.5 0-.292-1.167-14-1.167-14l2.919-6.708-8.757-6.125-7.59 14Z"
    />
    <path
      fill="#C6D3ED"
      d="m110.342 41.124-2.919 1.458s-8.174 18.666-8.758 18.958c-.583.292-3.502 5.833-3.794 7-.292 1.167-.876 4.958-1.752 5.541-.47.32-.957.611-1.46.876 0 0 1.752 2.916 2.628 2.916.876 0 3.795-4.083 3.795-4.083s6.422 4.083 8.173.875c1.752-3.208 6.13-23.625 6.13-23.625l-2.043-9.916Z"
    />
    <path
      fill="#FFB8B8"
      d="M133.257 50.02s-4.087-9.917-6.422-8.75c-2.336 1.166 1.751 8.166 4.378 9.333l2.044-.584Z"
    />
    <path
      fill="#000"
      d="m129.17 50.311 4.095-.925.867 4.425s3.795 6.708 2.919 9.625c-.875 2.917-6.13 7.583-7.881 4.958-1.752-2.625-3.211-6.708-2.044-9.041 1.168-2.334 2.919-7.875 2.044-9.042Z"
      opacity={0.1}
    />
    <path
      fill="#C6D3ED"
      d="m130.046 49.728 4.095-.925.867 4.425s3.795 6.708 2.919 9.624c-.875 2.917-6.13 7.584-7.881 4.959-1.752-2.625-3.211-6.709-2.044-9.042 1.168-2.333 2.919-7.875 2.044-9.041Z"
    />
    <path
      fill="#283252"
      d="M134.492 29.364c.787-1.677-1.416-4.366-4.92-6.006-3.504-1.64-6.982-1.61-7.768.068-.787 1.677 1.416 4.366 4.92 6.006 3.504 1.64 6.982 1.61 7.768-.067ZM133.403 24.5a4.084 4.084 0 1 0 0-8.167 4.085 4.085 0 0 0-4.087 4.083 4.085 4.085 0 0 0 4.087 4.084Z"
    />
    <path
      fill="#283252"
      d="M129.608 18.958a4.083 4.083 0 0 1 3.649-4.06 4.093 4.093 0 0 0-4.176 2.395 4.08 4.08 0 0 0 2.481 5.552 4.093 4.093 0 0 0 1.695.172 4.083 4.083 0 0 1-3.649-4.06Z"
    />
    <path fill="#94A4C4" d="M115.012 80.79H12.844v70.873h102.168V80.79Z" />
    <path fill="#F2F2F2" d="M34.445 87.79H21.018v13.416h13.427V87.79Z" />
    <path fill="#C6D3ED" d="M34.445 109.664H21.018v13.416h13.427v-13.416Z" />
    <path
      fill="#F2F2F2"
      d="M34.445 131.539H21.018v13.416h13.427v-13.416ZM104.795 92.019H40.867v1.166h63.928V92.02ZM60.717 95.81h-19.85v1.167h19.85V95.81Z"
    />
    <path
      fill="#C6D3ED"
      d="M104.795 113.893H40.867v1.167h63.928v-1.167ZM60.717 117.685h-19.85v1.166h19.85v-1.166Z"
    />
    <path
      fill="#F2F2F2"
      d="M104.795 135.768H40.867v1.166h63.928v-1.166ZM60.717 139.559h-19.85v1.167h19.85v-1.167Z"
    />
  </svg>
);
export default SvgComponent;
