import Input from "@components/input";
import React from "react";
import MyEditor from "@components/editor";
import CandidatesIcon from "@components/svgs/companiesbig";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import FilePicker from "@components/filepicker";

function AddProduct() {
  const [summary, setSummary] = React.useState("");
  const navigate = useNavigate();

  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add a new testimonial</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="grid grid-cols-1 bg-[#DDEFFF33] px-4 rounded-xl py-4 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <div className="md:col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Name:{" "}
          </label>
          <Input placeholder={"Company name"} className={"mt-1"} />
        </div>

        <div className="col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Testimonial:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={summary}
              setValue={setSummary}
              placeholder="Enter Tasks Here..."
            />{" "}
            <div className="flex mt-2 items-center gap-x-2">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                  stroke="#6F727A"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-xs text-[#6F727A]">
                Be clear and concise when writing the content. Be as inclusive
                as possible!
              </span>
            </div>
          </div>
        </div>

        <div className="col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Company Logo:{" "}
          </label>
          <div className="h-54">
            <FilePicker />
          </div>
        </div>

        <div className="col-span-4">
          <div className="mt-8 flex justify-end">
            <div>
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => null}
              >
                Add testimonial
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
