
 
import AddTaskIcon from "@components/svgs/addtask";
import MyEditor from "@components/editor";
import CustomDatePicker from "@components/datepicker";
import UserDropdown from "@components/dropdowns/userdropdown";
import {UserInterface} from "@components/dropdowns/types";
import StatusDropdown from "@components/dropdowns/status";
import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import FilePicker from "@components/filepicker";
import Subtask from "./subtask";
import {useTimer} from "../../../../contexts/TimerContext";

function AddNewTask() {
  const [value, setValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selecteduser, setSelectedUser] = useState<UserInterface | null>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [subTasks, setSubTasks] = useState<any>([]);
  const [createdTask, setCreatedTask] = useState<any>(false);
  const {handlePlay} = useTimer();

  const navigate = useNavigate();
  const handleAddSubTask = () => {
    setSubTasks([...subTasks, {name: "", notes: "", file: ""}]);
  };

  const removeSubTask = (index: number) => {
    const newSubTasks = subTasks.filter((_: any, i: number) => i !== index);
    setSubTasks(newSubTasks);
  };

  const setName = (index: number, name: string) => {
    subTasks[index].name = name;
    setSubTasks([...subTasks]);
  };

  const setFile = (index: number, file: string) => {
    subTasks[index].file = file;
    setSubTasks([...subTasks]);
  };

  const setNotes = (index: number, notes: string) => {
    subTasks[index].notes = notes;
    setSubTasks([...subTasks]);
  };

  return (
    <>
      <div className="gap-y-4 flex flex-col pb-12">
        <div className="w-full px-6 items-center rounded-lg justify-between flex bg-custom_faded_blue">
          <h1 className="text-2xl font-outfit font-medium">Tasks</h1>

          <div className="mt-10">
            <AddTaskIcon />
          </div>
        </div>

        <div className="flex justify-between my-4">
          <button
            onClick={() => navigate(-1)}
            className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
          >
            <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
            Back
          </button>
        </div>

        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg  bg-custom_faded_blue">
          <div className="flex justify-between">
            <label className="text-custom_secondary font-outfit font-light">
              Dashboard / <span className="text-custom_blue">Tasks</span>
            </label>

            {createdTask ? (
              <button
                onClick={handlePlay}
                className="bg-custom_orange rounded-md flex items-center gap-2 py-2 px-4"
              >
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.73445 17.6251C1.44665 17.6245 1.16394 17.5491 0.914141 17.4062C0.351641 17.0874 0.00195312 16.4687 0.00195312 15.797V2.20321C0.00195312 1.52961 0.351641 0.912739 0.914141 0.593989C1.16989 0.447006 1.46043 0.37142 1.75539 0.37513C2.05034 0.378841 2.33889 0.461713 2.59086 0.615083L14.2088 7.56946C14.451 7.72128 14.6506 7.93211 14.7889 8.18218C14.9273 8.43225 14.9999 8.71336 14.9999 8.99915C14.9999 9.28494 14.9273 9.56605 14.7889 9.81611C14.6506 10.0662 14.451 10.277 14.2088 10.4288L2.58898 17.3851C2.33112 17.541 2.03577 17.6239 1.73445 17.6251Z"
                    fill="white"
                  />
                </svg>

                <span className="text-white text-xs font-outfit font-light">
                  Start timer
                </span>
              </button>
            ) : null}
          </div>
          <div className="mt-4">
            {createdTask ? (
              <div
                className="border rounded-lg px-4 py-5 border-gray-200"
                dangerouslySetInnerHTML={{__html: value}}
              />
            ) : (
              <MyEditor
                value={value}
                setValue={setValue}
                placeholder="Enter Tasks Here..."
              />
            )}
          </div>

          <div className="my-4 flex items-center gap-x-2">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                stroke="#6F727A"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-sm text-[#6F727A]">
              Be clear and concise when writing the content. Be as inclusive as
              possible!
            </span>
          </div>

          <div>
            <FilePicker />
          </div>

          <div className="grid  lg:grid-cols-10  gap-4 my-4">
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Due Date</label>

              <div className="mt-1">
                <CustomDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Assign to:</label>
              <UserDropdown
                selecteduser={selecteduser}
                setSelectedUser={setSelectedUser}
              />
            </div>
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Status:</label>
              <StatusDropdown
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </div>
          </div>
        </div>

        {createdTask ? (
          <>
            {subTasks.map((subTask: any, index: number) => (
              <Fragment>
                <Subtask
                  subTask={subTask}
                  index={index}
                  setName={setName}
                  setFile={setFile}
                  setNotes={setNotes}
                  removeSubTask={removeSubTask}
                />
              </Fragment>
            ))}

            <div className="mt-5 flex w-full items-center justify-center relative">
              <hr className="w-full bordr-[#657A9345]" />
              <button
                onClick={handleAddSubTask}
                className="mt-8 flex absolute mb-8 items-center gap-3 bg-custom_deep_blue rounded-xl text-sm px-4  font-outfit  py-2 text-white"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                    fill="white"
                  />
                </svg>
                <span>Add a sub task</span>
              </button>
            </div>
          </>
        ) : null}

        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <button
            onClick={() => setCreatedTask(true)}
            className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex"
          >
            <span className="text-white">Create task</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default AddNewTask;
