import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import EmptyInterface from "@components/empty";
import CandidatesIcon from "@components/svgs/teamsbig";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import TeamList from "./dataview";
import {TopLoader} from "@components/loaders/toploader";
import {Suspense, lazy, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import NewPrint from "@components/svgs/newprint";

const Edit = lazy(() => import("./edit/editmember"));
const Delete = lazy(() => import("./edit/delete"));

function Page() {
const navigate = useNavigate();

const params = useParams();
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);

  const onEdit = (data: any) => {
    setSelected(data);
    setShow(true);
  };

  const onDelete = (data: any) => {
    setSelected(data);
    setShowDelete(true);
  };

  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-1/2 ps-6">
            <h1 className="uppercase text-3xl text-transparent bg-gradient-to-r font-medium from-orange-500 via-[#88400D95] to-[#E5C4AC] bg-clip-text ">
              {params?.team?.replace(/-/g, " ")}
            </h1>
            <span className="text-[#7C8DB5] font-light">Members: 12</span>
          </div>
          <CandidatesIcon />
        </div>
      </div>
      <div className="flex mt-8 justify-between">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>
      <div className="bg-[#DDEFFF33] p-3 mt-4">
        <div className={` ${data && "bg-white"}`}>
          <div className="px-5 py-4 flex justify-between items-center">
            <form
              className="relative flex items-center flex-1"
              action="#"
              method="GET"
            >
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#B0BACC]"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block border  rounded-lg w-full lg:w-2/5 h-3/5 border-[#B0BACC] py-3 pl-12 pr-0  placeholder:text-[#B0BACC] focus:ring-0 sm:text-sm"
                placeholder="Search here..."
                type="search"
                name="search"
              />
            </form>
            <div className="flex gap-x-3 items-center">
              <CopyIcon />
              <ShareIcon />
              <NewPrint />
            </div>
          </div>
          <div className="">
            {data.length === 0 ? (
              <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                <EmptyInterface
                  description="No tasks assigned yet..."
                  buttonText="Add a new task"
                  showButton={false}
                  buttonAction={() => null}
                />
              </div>
            ) : (
              <div>
                <div className="mt-4">
                  <TeamList onDelete={onDelete} onEdit={onEdit} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Suspense fallback={<TopLoader />}>
        <Edit open={show} setOpen={setShow} selected={selected} />
        <Delete open={showDelete} setOpen={setShowDelete} selected={selected} />
      </Suspense>
    </div>
  );
}

export default Page;
