import React, {Fragment} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import BadgeDropdown from "@components/dropdowns/badgedropdown";
import {STATUSLIST} from "@components/utils";
import moment from "moment";

function TaskList({tasks}: any) {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  return (
    <div className="px-5">
      <div className="-mx-4 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-100">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                ID
              </th>
              <th
                scope="col"
                className="py-3.5  text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                User
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Type
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Task
              </th>
              <th
                scope="col"
                className="hidden ps-4  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Due date
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 bg-white">
            {tasks?.slice(start, end).map((task: any) => (
              <Fragment key={task?.id}>
                <tr key={task.id} className="cursor-pointer hover:bg-gray-50">
                  <td className="whitespace-nowrap  py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                    {task?.id}
                  </td>
                  <td className="whitespace-nowrap  py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                    {task?.u_id}
                  </td>
                  <td className="whitespace-nowrap  py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                    <span className="line-clamp-2 ">{task?.type || "N/A"}</span>
                  </td>
                  <td className="underline text-custom_deep_blue w-40  py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                    <div
                      className="line-clamp-2 "
                      dangerouslySetInnerHTML={{__html: task?.task || "N/A"}}
                    />
                  </td>

                  <td className="py-4 text-sm font-medium sm:pl-0">
                    <div>
                      <BadgeDropdown
                        selected={STATUSLIST?.find(
                          (item) => item.name === "Pending"
                        )}
                        setSelected={() => null}
                      />
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm font-medium sm:pl-0">
                    {task?.due_date !== "0000-00-00"
                      ? moment(task?.due_date)?.format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              disabled={start === 0}
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              {end / 10}
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TaskList;
