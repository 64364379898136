import  {Fragment} from "react";
import Card from "@components/cards/card";
import { useNavigate } from "react-router-dom";



function Candidates() {
const navigate = useNavigate();
  const onClick = (dapartment: string) => {
     navigate(`${dapartment?.toLowerCase()}`);
  };

  const departments = [
    {
      id: 1,
      title: "Business Assistant",
      image:
        "https://eandeeyecare.com/wp-content/uploads/2023/11/Image-9-1-23-at-2.29-PM.jpeg",
      subtext: "Total number in the pool: 108",
      buttonText: "Find candidate",
      onClick: onClick,
    },
    {
      id: 2,
      title: "Software Development",
      image:
        "https://drive.google.com/thumbnail?id=1mZs2QSlm1RLBe0k2CFXYySSbrymP7OXx",
      subtext: "Total number in the pool: 208",
      buttonText: "Find candidate",
      onClick: onClick,
    },
    {
      id: 3,
      title: "Marketing Creatives",
      subtext: "Total number in the pool: 200",
      buttonText: "Find candidate",
      onClick: onClick,
      image:
        "https://drive.google.com/thumbnail?id=1kmHlxUbSPMNwUkrYItIZR20E5iIKmkLu",
    },
    {
      id: 4,
      title: "Human Resource",
      subtext: "Total number in the pool: 108",
      buttonText: "Find candidate",
      onClick: onClick,

      image:
        "https://images.unsplash.com/photo-1573496130407-57329f01f769?q=80&w=1738&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ];

  return (
    <div className="font-outfit">
      <div className=" bg-[#DDEFFF33]">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-6">
          {departments?.map((department) => (
            <Fragment key={department?.id}>
              <Card data={department} />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Candidates;
