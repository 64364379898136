 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={225}
    height={116}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#F2F2F2"
        d="M185.053 15.46C159.199-4.51 123.231-5.325 96.99 14.13 83.12 24.416 72.365 40.152 74.321 64.111c.692 8.475 2.616 15.428 5.424 21.12 11.373 23.072 58.086 22.183 60.68 22.18 1.681-.002 3.362.036 5.043.113a110.386 110.386 0 0 1 17.512 2.218c8.225 1.718 18.697 2.581 28.053-.672a32.63 32.63 0 0 0 4.619-2.013c5.142-2.738 10.898-5.354 14.262-11.848 2.529-4.874 4.371-10.985 5.258-18.597 3.227-27.72-12.897-47.851-30.119-61.152Z"
      />
      <path
        fill="#3F3D56"
        d="M145.58 114.81c40.83 0 73.93-3.113 73.93-6.954 0-3.84-33.1-6.954-73.93-6.954-40.829 0-73.929 3.114-73.929 6.954 0 3.841 33.1 6.954 73.929 6.954Z"
      />
      <path
        fill="#000"
        d="M141.969 111.12c37.975 0 68.76-1.461 68.76-3.264 0-1.803-30.785-3.264-68.76-3.264-37.975 0-68.76 1.461-68.76 3.264 0 1.803 30.785 3.264 68.76 3.264Z"
        opacity={0.1}
      />
      <path
        fill="#575A89"
        d="M180.008 14.725v7.801h-10.136v85.081h21.153V14.725h-11.017ZM124.186 67.127V53.732h-12.927v13.395h-4.848v40.627h22.476V67.127h-4.701Z"
      />
      <path fill="#3F3D56" d="M126.389 88.912h-17.481v18.989h17.481V88.912Z" />
      <path
        fill="#000"
        d="M111.699 88.912h-2.791v18.989h2.791V88.912Z"
        opacity={0.1}
      />
      <path
        fill="#3F3D56"
        d="M162.38 10.455v4.563h-25.855v-4.563h-7.638v97.446h40.985V10.455h-7.492Z"
      />
      <path
        fill="#000"
        d="M168.109 10.455h-5.729v4.563h5.729v-4.563ZM134.616 10.455h-5.729v97.446h5.729V10.455Z"
        opacity={0.1}
      />
      <path
        fill="#3F3D56"
        d="M99.653 38.276v-3.68h-3.819v3.68H88.93V107.9h17.481V38.276h-6.758Z"
      />
      <path
        fill="#000"
        d="M97.89 34.596h-2.056v3.68h2.057v-3.68ZM90.986 38.275H88.93v69.626h2.056V38.275ZM168.109 47.992h-5.729v59.91h5.729v-59.91ZM106.411 38.275h-5.289v12.807h5.289V38.275Z"
        opacity={0.1}
      />
      <path
        fill="#FF6584"
        d="M143.43 22.82h-8.227v3.533h8.227V22.82ZM160.911 59.178h-8.227v3.533h8.227v-3.533ZM143.43 84.644h-8.227v3.533h8.227v-3.533ZM101.857 54.174h-8.226v3.533h8.226v-3.533ZM121.835 90.973h-8.226v3.532h8.226v-3.532Z"
      />
      <path
        fill="#000"
        d="M124.773 70.219h-14.102v5.888h14.102v-5.888ZM186.471 29.297h-14.102v5.888h14.102v-5.888ZM186.471 41.809h-14.102v5.888h14.102v-5.888ZM186.471 54.32h-14.102v5.888h14.102V54.32ZM186.471 66.832h-14.102v5.888h14.102v-5.888ZM186.471 79.346h-14.102v5.888h14.102v-5.888ZM186.471 91.857h-14.102v5.888h14.102v-5.888Z"
        opacity={0.1}
      />
      <path
        fill="#3F3D56"
        d="M195.873 55.94V39.894H185.59v16.044h-3.673v51.962h17.482V55.939h-3.526Z"
      />
      <path
        fill="#000"
        d="M188.822 39.895h-3.232v16.044h3.232V39.895ZM185.149 55.94h-3.232V107.9h3.232V55.94ZM199.399 81.994h-8.667v5.152h8.667v-5.152Z"
        opacity={0.1}
      />
      <path fill="#FF6584" d="M199.252 74.34h-8.227v3.533h8.227V74.34Z" />
      <path
        fill="#0D9967"
        d="M225 77.907c0 13.114-7.78 17.693-17.378 17.693-9.597 0-17.378-4.58-17.378-17.693 0-13.114 17.378-29.798 17.378-29.798S225 64.793 225 77.907Z"
      />
      <path
        fill="#3F3D56"
        d="m206.989 93.594.178-10.975 7.407-13.579-7.379 11.857.08-4.935 5.105-9.824-5.084 8.518.144-8.876 5.466-7.82-5.443 6.425.09-16.276-.565 21.546.046-.89-5.558-8.523 5.469 10.23-.518 9.913-.015-.263-6.407-8.97 6.387 9.9-.065 1.239-.011.018.005.102-1.314 25.15h1.756l.21-12.99 6.372-9.876-6.356 8.899Z"
      />
      <path
        fill="#0D9967"
        d="M97.639 78.05c0 13.114-7.78 17.693-17.378 17.693-9.597 0-17.378-4.58-17.378-17.694 0-13.114 17.378-29.797 17.378-29.797S97.64 64.935 97.64 78.049Z"
      />
      <path
        fill="#3F3D56"
        d="m79.628 93.737.178-10.976 7.407-13.578-7.379 11.856.08-4.935 5.105-9.823-5.084 8.517.144-8.875 5.467-7.821-5.444 6.425.09-16.275-.565 21.545.046-.889-5.558-8.524 5.469 10.23-.518 9.913-.015-.263-6.407-8.97 6.387 9.9-.064 1.24-.012.018.005.102-1.314 25.149h1.756l.21-12.99 6.372-9.876-6.356 8.9Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h225v135H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
