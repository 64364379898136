 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#989FB0"
      d="M19.2 9.6c0-5.3-4.3-9.6-9.6-9.6S0 4.3 0 9.6c0 4.646 3.302 8.515 7.68 9.408V12.48H5.76V9.6h1.92V7.2a3.364 3.364 0 0 1 3.36-3.36h2.4v2.88h-1.92a.963.963 0 0 0-.96.96V9.6h2.88v2.88h-2.88v6.672c4.848-.48 8.64-4.57 8.64-9.552Z"
    />
  </svg>
);
export default SvgComponent;
