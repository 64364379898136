import data from "./dataview/data.json";
import EmptyInterface from "@components/empty";
import {useNavigate} from "react-router-dom";
import TaskList from "./dataview";
function Page() {
  const navigate = useNavigate();
  return (
    <div className="pb-4">
      <div className={`mx-4 rounded-xl ${data && "bg-white"}`}>
        <div className="">
          {data.length === 0 ? (
            <div className="mt-4 border border-custom_lighter_gray rounded-xl">
              <EmptyInterface
                description="No tasks assigned yet..."
                buttonText="Add a new task"
                buttonAction={() => navigate("add")}
              />
            </div>
          ) : (
            <div>
              <div className="mt-4">
                <TaskList />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Page;
