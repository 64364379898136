const initialData = {
  tasks: {
    "task-1": {id: "task-1", content: "Take out the garbage"},
    "task-2": {id: "task-2", content: "Watch my favorite show"},
    "task-3": {id: "task-3", content: "Charge my phone"},
    "task-4": {id: "task-4", content: "Cook dinner"},
    "task-5": {id: "task-5", content: "Workout"},
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Prospect",
      taskIds: ["task-1", "task-2", "task-3", "task-4"],
    },
    "column-2": {
      id: "column-2",
      title: "New Lead",
      taskIds: ["task-5"],
    },
    "column-3": {
      id: "column-3",
      title: "In Progress",
      taskIds: [],
    },
  },
  columnOrder: ["column-1", "column-2", "column-3"],
};

export interface ITask {
  id: string;
  content: string;
}
export interface Column {
  id: string;
  title: string;
  taskIds: string[];
}
export interface State {
  tasks: {
    [key: string]: ITask;
  };
  columns: {
    [key: string]: Column;
  };
  columnOrder: string[];
}

export default initialData;
