import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M8 13v2a6 6 0 0 0-6 6H0a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6Zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm9 6h1v5h-8v-5h1v-1a3 3 0 0 1 6 0v1Zm-2 0v-1a1 1 0 0 0-2 0v1h2Z"
    />
  </svg>
);
export default SvgComponent;
