function Sickleave(props: any) {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M16.7 15.275L19.35 12.6C19.8833 12.0667 20.2917 11.4583 20.575 10.775C20.8583 10.0917 21 9.375 21 8.625C21 7.05833 20.4543 5.729 19.363 4.637C18.2717 3.545 16.9423 2.99933 15.375 3C14.625 3 13.9083 3.14167 13.225 3.425C12.5417 3.70833 11.9333 4.11667 11.4 4.65L8.725 7.3L16.7 15.275ZM8.625 21C9.375 21 10.0917 20.8583 10.775 20.575C11.4583 20.2917 12.0667 19.8833 12.6 19.35L15.275 16.7L7.3 8.725L4.65 11.4C4.11667 11.9333 3.70833 12.5417 3.425 13.225C3.14167 13.9083 3 14.625 3 15.375C3 16.9417 3.546 18.2707 4.638 19.362C5.73 20.4533 7.059 20.9993 8.625 21Z"
          fill="#FBB03B"
        />
      </svg>
    </div>
  );
}

export default Sickleave;
