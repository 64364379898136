import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
function PeopleOffPerMonth() {
  const data = [
    {
      name: "Jan",
      female: 4000,
      male: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      female: 3000,
      male: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      female: 2000,
      male: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      female: 2780,
      male: 3908,
      amt: 2000,
    },
    {
      name: "May",
      female: 1890,
      male: 4800,
      amt: 2181,
    },
    {
      name: "June",
      female: 2390,
      male: 3800,
      amt: 2500,
    },
    {
      name: "July",
      female: 3490,
      male: 4300,
      amt: 2100,
    },
  ];

  return (
    <div className="col-span-5 flex flex-col justify-between bg-white rounded-xl px-3 py-3">
      <div className="flex justify-between gap-y-4 px-3">
        <h1 className="font-outfit font-bold text-black">
          People off per month
        </h1>
        <div className="flex gap-5">
          <div className="flex gap-x-3 items-center">
            <div className="h-4 w-4 rounded-md bg-[#3A63DA]" />
            <span className="text-[#718EBF] font-light">
              Male:<span className="text-green-500 ms-1">5</span>
            </span>
          </div>
          <div className="flex gap-x-3 items-center">
            <div className="h-4 w-4 rounded-md bg-[#FCAA0B]" />
            <span className="text-[#718EBF] font-light">
              Female:<span className="text-green-500 ms-1">20</span>
            </span>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <ResponsiveContainer height={300}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="male"
              fill="#1A16F366"
              activeBar={<Rectangle fill="#3A63DA" stroke="#3A63DA" />}
            />
            <Bar
              dataKey="female"
              fill="#FCAA0B66"
              activeBar={<Rectangle fill="#FCAA0B" stroke="#FCAA0B" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default PeopleOffPerMonth;
