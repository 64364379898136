"use client";

import MonthSelector from "@components/dropdowns/monthselector";
import { useState } from "react";
 
import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
function SignUps() {
  const data = [
    {
      name: "W1",
      uv: 2500,
      pv: 1000,
    },
    {
      name: "W2",
      uv: 2800,
      pv: 1398,
    },
    {
      name: "W3",
      uv: 1300,
      pv: 500,
    },
    {
      name: "W4",
      uv: 1800,
      pv: 800,
    },
    {
      name: "W5",
      uv: 1100,
      pv: 500,
    },
    {
      name: "March",
      uv: 1100,
      pv: 500,
    },
  ];

  const [selectedMonth, setSelectedMonth] = useState("January");
  return (
    <div className="col-span-4 flex flex-col justify-between bg-white rounded-xl px-3 py-3">
      <div className="flex flex-col gap-y-4">
        <div>
          <MonthSelector
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
        </div>
      </div>

      <div className="w-full flex">
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="" stroke="#ECECEC" />
            <XAxis dataKey="name" style={{fontSize: "12px"}} />
            <YAxis color="" style={{fontSize: "12px"}} />
            <Tooltip />

            <Line
              type="linear"
              dataKey="pv"
              stroke="#F765A3"
              activeDot={{r: 5}}
              strokeWidth={2}
              dot={{stroke: "#F765A3", strokeWidth: 2, fill: "#F765A3"}}
            />
            <Line
              type="linear"
              dataKey="uv"
              stroke="#A155B9"
              strokeWidth={2}
              dot={{stroke: "#A155B9", strokeWidth: 2, fill: "#A155B9"}}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="flex justify-between gap-x-8">
        <div className="flex items-center gap-2">
          <svg
            width="12"
            height="12"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#F765A3" />
          </svg>

          <div className="flex flex-col">
            <span className="font-outfit text-2xl font-bold text-[#2B3674]">
              2100
            </span>
            <span className="text-xs">Signups</span>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <svg
            width="12"
            height="12"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#A155B9" />
          </svg>

          <div className="flex flex-col">
            <span className="font-outfit text-2xl font-bold text-[#2B3674]">
              1000
            </span>
            <span className="text-xs">New applications </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUps;
