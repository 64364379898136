import {PhotoIcon, XCircleIcon} from "@heroicons/react/24/outline";
import React from "react";
import pdf from "@assets/svgs/pdf.svg";

function FilePicker() {
  const [file, setFile] = React.useState<File | null>(null);
  return (
    <>
      {file ? (
        <div className="border px-5 py-4 flex items-center justify-between rounded-xl">
          <div className="flex items-center rounded-xl">
            <img src={pdf} className="" />
            <div className="ms-4">
              <p className="text-sm font-semibold text-gray-900">{file.name}</p>
              {/* convert to MB */}
              <p className="text-xs text-gray-600">
                {(file.size / (1024 * 1024)).toFixed(2)} MB
              </p>
            </div>
          </div>
          <button onClick={() => setFile(null)}>
            <XCircleIcon className="text-red-600 h-8 w-8" />
          </button>
        </div>
      ) : (
        <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8">
          <div className="text-center">
            <PhotoIcon
              className="mx-auto h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files![0]);
                  }}
                  className="sr-only"
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              PNG, JPG, GIF up to 10MB
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default FilePicker;
