"use client";
 
import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";

// import {DM_Sans} from "next/font/google";
import {Cog6ToothIcon, XMarkIcon} from "@heroicons/react/24/outline";

// svgs
import DashbboardIcon from "@components/svgs/dashboardicon";
import NotificationIcon from "@assets/svgs/notifications";
import UsersIcon from "@components/svgs/usersicon";
import CRMIcon from "@components/svgs/candidates";
import WorkSpaceIcon from "@components/svgs/condidatepool";
import AreaIcon from "@components/svgs/leave";
import ReportsIcon from "@components/svgs/teams";
import CMSIcon from "@components/svgs/companies";
import SettingsIcon from "@components/svgs/leads";
// import Jobs from "@components/svgs/jobs";
// import InternalProjects from "@components/svgs/internalprojects";
// import Notes from "@components/svgs/notes";
// import TimeReports from "@components/svgs/timereports";
import DesktopSidebar from "@components/sidebars/desktop";
import logo from "@assets/logo.png";
import TopBar from "@components/topbar/topbar";

// const dm_sans = DM_Sans({
//   subsets: ["latin"],
//   display: "swap",
//   variable: "--font-dmsans",
// });

const navigation = [
  {name: "Dashboard", href: "/dashboard", icon: DashbboardIcon},
  {
    name: "People",
    current: true,
    icon: UsersIcon,
    children: [
      {name: "Employees", href: "/people/employees"},
      {name: "Candidates", href: "/people/candidates"},
      {
        name: "Candidate Pool",
        href: "/people/candidate_pool",
      },
      {name: "Leave", href: "/people/leave"},
      {name: "Teams", href: "/people/teams"},
    ],
  },
  {
    name: "Sales",
    current: false,
    icon: CRMIcon,
    children: [
      {name: "Companies", href: "/sales/companies"},
      {name: "Leads", href: "/sales/leads"},
      {name: "Partners", href: "/sales/partners"},
    ],
  },
  {
    name: "Workspace",
    current: false,
    icon: WorkSpaceIcon,
    children: [{name: "Jobs", href: "/workspace/jobs"}],
  },
  {
    name: "Area",
    current: false,
    icon: AreaIcon,
    children: [
      {
        name: "Current Positions",
        href: "/area/current_positions",
      },
      {
        name: "Internal Projects",
        href: "/area/internal_projects",
      },
      {name: "Tasks", href: "/area/tasks"},
    ],
  },
  {
    name: "Reports",
    current: false,
    icon: ReportsIcon,
    children: [
      {
        name: "Notes",
        href: "/reports/notes",
      },
      {
        name: "Time Projects",
        href: "/reports/time-reports",
      },
    ],
  },
  {
    name: "CMS",
    current: false,
    icon: CMSIcon,
    children: [
      {
        name: "Blogs",
        href: "/cms/blogs",
      },
      {
        name: "Testimonials",
        href: "/cms/testimonials",
      },
    ],
  },
  {
    name: "Settings",
    current: false,
    icon: SettingsIcon,
    children: [
      {
        name: "Products",
        href: "/settings/products",
      },
      {
        name: "Holiday settings",
        href: "/settings/holiday-settings",
      },
      {
        name: "Blog settings",
        href: "/settings/blog-settings",
      },
    ],
  },

  {name: "Notifications", href: "/notifications", icon: NotificationIcon},
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className={`font-dm_sans`}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-8 w-auto" src={logo} alt="Your Company" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                href={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-50 text-indigo-600"
                                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li className="mt-auto">
                        <span className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                          Settings
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <DesktopSidebar navigation={navigation} />
      </div>

      <TopBar setSidebarOpen={setSidebarOpen} />
    </div>
  );
}

export default DashboardLayout;
