"use client";

import CandidateIcon from "@components/svgs/candidatebigicon";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";

import CandidateStats from "@components/stats/employeestats";
import Tabs from "@components/tabs";
import {Outlet, useNavigate, useParams} from "react-router-dom";
function ViewCandidate() {
  const {id} = useParams();

  const tabs = [
    {
      name: "Candidate profile",
      href: `/people/candidates/view/${id}`,
    },

    {
      name: "Notes",
      href: `/people/candidates/view/${id}/notes`,
    },
    {
      name: "Job applications",
      href: `/people/candidates/view/${id}/jobapplications`,
    },
    {
      name: "LMS",
      href: `/people/candidates/view/${id}/lms`,
    },
    {
      name: "Preview CV",
      href: `/people/candidates/view/${id}/previewcv`,
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div className="gap-y-4 font-outfit flex flex-col pb-12">
        <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
          <div className="flex justify-between items-center">
            <div className="w-2/4">
              <CandidateStats />
            </div>
            <CandidateIcon />
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={() => navigate("/people/candidates")}
            className="text-custom_secondary font-poppins hover:underline flex gap-x-3 items-center font-outfit font-light"
          >
            <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
            Back
          </button>
        </div>
        <div className="w-full flex-1 flex flex-col py-4 px-6 ">
          <div className="bg-custom_faded_blue  px-4">
            <Tabs tabs={tabs} />
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default ViewCandidate;
