import LMSTable from "./dataview";
import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import EmptyInterface from "@components/empty";

function Page() {
  return (
    <div className="bg-custom_faded_blue px-3 pb-4">
      <div className={` ${data && "bg-white"} rounded-xl`}>
        <div className="px-5 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-outfit font-semibold">Applications</h1>
          <div className="flex gap-x-3 items-center">
            <CopyIcon />
            <ShareIcon />
            <PrintIcon />
          </div>
        </div>
        <div className="">
          {data.length === 0 ? (
            <div className="mt-4 border border-custom_lighter_gray rounded-xl">
              <EmptyInterface
                description="No tasks assigned yet..."
                buttonText="Add a new task"
                showButton={false}
                buttonAction={() => null}
              />
            </div>
          ) : (
            <div>
              <div className="mt-4">
                <LMSTable />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Page;
