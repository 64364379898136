import Dropdown from "@components/dropdowns/dropdown";
import {useState} from "react";
import data from "../currentposition/tasks/dataview/data.json";
import CustomDatePicker from "@components/datepicker";

function Page() {
  const [contact, setContact] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [product, setProduct] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div className="mt-6">
      <div className="w-full grid lg:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-2 py-4 rounded-lg ">
        <div className="">
          <label className="text-xs text-[#82868C]">Name</label>
          <Dropdown
            list={data?.map((item) => ({
              name: item?.user,
              value: item?.user,
            }))}
            selected={contact}
            setSelected={setContact}
          />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Value:</label>
          <Dropdown
            list={data?.map((item) => ({
              name: item?.user,
              value: item?.user,
            }))}
            selected={title}
            setSelected={setTitle}
          />
        </div>

        <div className="col-span-2  w-full">
          <label className="text-xs text-[#82868C]">About:</label>

          <textarea
            rows={10}
            placeholder="Eg. Job description...."
            className="mt-1 w-full rounded-xl bg-transparent border placeholder:text-[#9CA1AD] border-[#657A9345]"
          />
        </div>

        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Expected date:{" "}
          </label>
          <CustomDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Type:</label>
          <Dropdown
            list={[
              {name: "Full time", value: "Full time"},
              {name: "Part time", value: "Part time"},
            ]}
            selected={type}
            setSelected={setType}
          />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Type:</label>
          <Dropdown
            list={[
              {name: "Intern", value: "Intern"},
              {name: "Permanent", value: "Permanent"},
              {name: "Contract", value: "Contract"},
            ]}
            selected={type}
            setSelected={setType}
          />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Product:</label>
          <Dropdown
            list={[
              {name: "Full-time", value: "full-time"},
              {name: "Part-time", value: "part-time"},
              {name: "Contract", value: "contract"},
            ]}
            selected={product}
            setSelected={setProduct}
          />
        </div>

        <div className="col-span-2 flex justify-end mt-3">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-light text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => null}
          >
            Update Position
          </button>
        </div>
      </div>
    </div>
  );
}

export default Page;
