import React from "react";
import "react-quill/dist/quill.snow.css"; // Import the styles
import ReactQuill from "react-quill"; // Import the component
import "react-quill/dist/quill.bubble.css"; // Optional: Use 'quill.bubble.css' for a more compact toolbar style
// interface Attachment {
//   url: string;
//   alt: string;
// }

const modules = {
  // style the toolbar

  toolbar: {
    // make the toolbar have a padding
    container: [
      [{header: [1, 2, 3, 4, 5, 6, false]}], // Headers
      ["bold", "italic", "underline", "strike"], // Toggle buttons for basic formatting
      [{list: "ordered"}, {list: "bullet"}], // Ordered and Bullet lists
      ["link", "image", "video", "attachment"], // Link, Image, Video, and Attachment
    ],
    handlers: {
      attachment: () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
          const file = input.files?.[0];
          if (file) {
            const formData = new FormData();
            formData.append("attachment", file);
          }
        };
      },
    },
  },
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "code-block",
  "list",
  "bullet",
  "indent",
  "header",
  "link",
  "image",
  "video",
  "attachment",
];

interface RichTextEditorProps {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  height?: string;
  className?: string;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  setValue,
  placeholder,
  className,
}) => {
  // const ReactQuill = useMemo(
  //   () => lazy(() => import("react-quill")),
  //   []
  // );
  // const uploadAttachment = async (
  //   formData: FormData
  // ): Promise<string | null> => {
  //   // Implement the logic to upload the attachment to your server
  //   // You can use fetch or any other method to handle the upload

  //   try {
  //     const response = await fetch("YOUR_ATTACHMENT_UPLOAD_URL", {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       return data.url; // Assuming the server responds with the attachment URL
  //     } else {
  //       console.error("Error uploading attachment");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error uploading attachment", error);
  //     return null;
  //   }
  // };

  return (
    <div className={`overflow-scroll  ${className}`}>
      <ReactQuill
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        className="h-full w-full no-scollbar"
        bounds=".app"
        style={{
          height: "100%",
          background: "none",
          border: "1px solid #657A9345",
        }}
        placeholder={placeholder || "Write something..."}
      />
    </div>
  );
};

export default RichTextEditor;
