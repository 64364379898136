import EmptyInterface from "@components/empty";
import {useNavigate} from "react-router-dom";
import data from "./dataview/data.json";
import HolidayLists from "./dataview";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";

function holidays() {
  const navigate = useNavigate();
  const handleAddHoliday = () => {
    navigate("/people/leave/leave-type/addholiday");
  };
  return (
    <div className={`mt-8 rounded-xl ${data && "bg-white"}`}>
      <div className="px-5 py-4 flex justify-between items-center">
        <form
          className="relative flex items-center flex-1"
          action="#"
          method="GET"
        >
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#B0BACC]"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block border bg-transparent  rounded-lg w-full lg:w-2/5 h-3/5 border-[#657A9345] py-3 pl-12 pr-0  placeholder:text-[#B0BACC] focus:ring-0 sm:text-sm"
            placeholder="Find team member..."
            type="search"
            name="search"
          />
        </form>
        <div className="flex gap-x-3 items-center">
          <CopyIcon />
          <ShareIcon />
          <PrintIcon />
          <button
            onClick={handleAddHoliday}
            className="bg-custom_deep_blue text-sm text-white px-4 py-2 rounded-lg flex items-center gap-3"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                fill="white"
              />
            </svg>
            <span>Add a holiday</span>
          </button>
        </div>
      </div>
      <div className="">
        {data.length === 0 ? (
          <div className="mt-4 border border-custom_lighter_gray rounded-xl">
            <EmptyInterface
              description="No tasks assigned yet..."
              buttonText="Add a new task"
              buttonAction={handleAddHoliday}
            />
          </div>
        ) : (
          <div>
            <div className="mt-4">
              <HolidayLists />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default holidays;
