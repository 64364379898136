import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#fff" fillOpacity={0.7} clipPath="url(#a)">
      <path d="M6 24s-1.5 0-1.5-1.5 1.5-6 7.5-6 7.5 4.5 7.5 6S18 24 18 24H6Zm6-8.926a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" />
      <path d="M3 1.5a3 3 0 0 0-3 3v14.25A2.25 2.25 0 0 0 2.25 21h.98a8.1 8.1 0 0 1 1.599-3H1.5V4.5A1.5 1.5 0 0 1 3 3h18a1.5 1.5 0 0 1 1.5 1.5V18h-3.328a8.06 8.06 0 0 1 1.598 3h.98A2.25 2.25 0 0 0 24 18.75V4.5a3 3 0 0 0-3-3H3Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
