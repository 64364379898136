import React, {Fragment} from "react";
import tasks from "./data.json";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import {TrashIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";

function TeamList() {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const navigate = useNavigate();

  const handleNavigate = (lead: any) => {
    navigate(`view/${lead?.id}`);
  };

  return (
    <div className="px-5 py-3">
      <div className="-mx-4  sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                ID
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Company
              </th>
              <th
                scope="col"
                className="hidden py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Description
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Country
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Lead
              </th>
              <th
                scope="col"
                className="py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Contact
              </th>
              <th
                scope="col"
                className="py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {tasks?.slice(start, end).map((task) => (
              <Fragment key={task?.id}>
                <tr key={task.id} className="cursor-pointer hover:bg-gray-50">
                  <td
                    onClick={() => handleNavigate(task)}
                    className="whitespace-nowrap py-4 pl-4  text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    {task.id}
                  </td>
                  <td
                    onClick={() => handleNavigate(task)}
                    className="whitespace-nowrap flex py-4 pl-4  text-sm font-medium underlie sm:pl-0"
                  >
                    <span className="">10PM Curfew </span>
                  </td>
                  <td
                    onClick={() => handleNavigate(task)}
                    className="whitespace-nowrap py-4 pl-4  text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    <span className={``}>Software development</span>
                  </td>
                  <td
                    onClick={() => handleNavigate(task)}
                    className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    <span className={` rounded-full py-1 `}>United States</span>
                  </td>
                  <td
                    onClick={() => handleNavigate(task)}
                    className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell"
                  >
                    <span className={`underline text-custom_blue`}>
                      10pmcurfew@gmail.com
                    </span>
                  </td>
                  <td
                    onClick={() => handleNavigate(task)}
                    className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell"
                  >
                    <span className={``}>Matthias Boampong</span>
                  </td>
                  <td
                    onClick={() => handleNavigate(task)}
                    className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell"
                  >
                    <span className={``}>+1 347 3484 8929</span>
                  </td>
                  <td className="py-4 text-sm sm:pl-0">
                    <TrashIcon className="text-[#E94A74] h-5 w-5" />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              disabled={start === 0}
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TeamList;
