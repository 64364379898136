import CandidatesIcon from "@components/svgs/teamsbig";
import {Outlet} from "react-router-dom";
import Tabs from "@components/tabs";
import NotificationsStats from "@components/stats/notifications";
function index() {
  const tabs = [
    {
      name: "New",
      href: `/notifications`,
      count: 40,
    },
    {
      name: "Read",
      href: `/notifications/read`,
    },
    {
      name: "All notifications",
      href: `/notifications/all-notifications`,
    },
  ];
  return (
    <div>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/3">
            <NotificationsStats />
          </div>

          <CandidatesIcon />
        </div>
      </div>
      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-1">
        <Tabs tabs={tabs} />
        <Outlet />
      </div>
    </div>
  );
}

export default index;
