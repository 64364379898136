import TimeReportStats from "@components/stats/reportsststats";
import CandidatesIcon from "@components/svgs/teamsbig";
import {Outlet} from "react-router-dom";
import Tabs from "@components/tabs";
import Graphs from "@components/stats/graphs/timereportcharts";
function TimeReport() {
  const tabs = [
    {
      name: "Time reports",
      href: `/reports/time-reports`,
    },
    {
      name: "Company time reports",
      href: `/reports/time-reports/company-time-reports`,
    },
    {
      name: "Users time",
      href: `/reports/time-reports/user-time-reports`,
    },
  ];
  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 px-3 py-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <TimeReportStats />
          </div>
          <CandidatesIcon />
        </div>
        <Graphs />
      </div>

      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-1">
        <Tabs tabs={tabs} />
        <Outlet />
      </div>
    </>
  );
}

export default TimeReport;
