import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M8.003 3a2.997 2.997 0 1 1 5.994 0 2.997 2.997 0 0 1-5.994 0ZM11 1.505a1.497 1.497 0 1 0 0 2.994 1.497 1.497 0 0 0 0-2.994Zm4.5 2.247a2.25 2.25 0 1 1 4.499 0 2.25 2.25 0 0 1-4.499 0ZM17.75 3a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM4.25 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM3.5 3.75a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM5.402 7.5A2.985 2.985 0 0 0 5 9H2v3.75a2.25 2.25 0 0 0 3.144 2.065c.111.497.285.97.51 1.413A3.75 3.75 0 0 1 .5 12.751V9A1.5 1.5 0 0 1 2 7.5h3.402Zm10.944 8.728a3.75 3.75 0 0 0 5.154-3.478V9A1.5 1.5 0 0 0 20 7.5h-3.402c.255.44.402.952.402 1.5h3v3.75a2.25 2.25 0 0 1-3.144 2.065c-.113.497-.285.97-.51 1.413ZM8 7.5A1.5 1.5 0 0 0 6.5 9v4.502a4.5 4.5 0 1 0 9 0V9A1.5 1.5 0 0 0 14 7.5l-6-.002ZM8 9h6v4.502a3 3 0 0 1-6 0V8.999Z"
    />
  </svg>
);
export default SvgComponent;
