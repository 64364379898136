import Tabs from "@components/tabs";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {Outlet, useNavigate} from "react-router-dom";

const tabs = [
  {
    name: "Hoidays",
    href: `/people/leave/leave-type`,
    count: "05",
  },
  {
    name: "Sickness",
    href: `/people/leave/leave-type/sickness`,
    count: "01",
  },
  {
    name: "Requests",
    href: `/people/leave/leave-type/requests`,
    count: "11",
  },
  {
    name: "Today",
    href: `/people/leave/leave-type/today`,
  },
];

function LeaveTypeLayout() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-white py-4 my-3">
        <button
          onClick={() => navigate("/people/leave")}
          className="flex text-gray-400 items-center"
        >
          <ChevronLeftIcon className="h-6 w-6  inline-block" />
          <span className="ms-2">Back</span>
        </button>
      </div>

      <div>
        <Tabs tabs={tabs} />
        <Outlet />
      </div>
    </div>
  );
}

export default LeaveTypeLayout;
