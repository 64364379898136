import CandidatesIcon from "@components/svgs/teamsbig";
import data from "@components/constants/data.json";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import EmptyInterface from "@components/empty";
import JobTable from "./dataview";
import {Suspense, useState} from "react";
import {TopLoader} from "@components/loaders/toploader";
import ViewJD from "./viewjd";
import {useNavigate, useParams} from "react-router-dom";
import avartar from "@assets/leadavartar.png";
import Badge from "@components/badge";
import Button from "@components/button";
import OpenIcon from "@assets/svgs/open.svg";
function JobCompany() {
  const [show, setShow] = useState(false);

  const showJD = () => {
    setShow(true);
  };

  const navigate = useNavigate();
  const handleAddJob = () => {
    navigate("/workspace/jobs/addjob");
  };

  const {id} = useParams();

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 px-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <h2 className="font-normal text-2xl">Applications</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex ps-4 justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>
      <div className="bg-[#DDEFFF33] mx-4 px-4 rounded-lg py-3 font-inter">
        <div className="grid grid-cols-1 lg:grid-cols-3 bg-white rounded-xl py-3">
          <div className="border-r py-3 ps-5">
            <h2 className="font-bold uppercase text-2xl text-custom_blue">
              {id}
            </h2>
            <p className="text-[#7C8DB5]">
              Learning and development coordinator
            </p>
          </div>
          <div className="px-4 col-span-2 flex items-center justify-between">
            <div className="flex px-4 items-center justify-between border bg-white py-3 rounded-xl">
              <div className="flex items-center gap-2">
                <img
                  src={avartar}
                  alt="user"
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-sm">Andrea Botwe</span>
              </div>
              <div className="ms-12">
                <Badge
                  status="Lead sales person"
                  text="Lead sales person"
                  colors={{
                    bg: "bg-custom_dark_blue",
                    text: "text-white",
                  }}
                />
              </div>
            </div>

            <div className="w-40">
              <Button onClick={() => null} title="Open" icon={OpenIcon} />
            </div>
          </div>
        </div>

        <div className="border text-sm leading-3 px-3 py-4 mt-4 rounded-xl§">
          <p>
            Key Responsibilities:
            <br />
            <br />
            1. Administrative Support: Provide administrative assistance to the
            team, including scheduling appointments, managing calendars,
            organizing meetings, and handling correspondence. <br />
            <br />
            2. Canva Design: Utilize Canva to create visually appealing
            graphics, presentations, and marketing materials for internal and
            external use, ensuring brand consistency and professionalism.
            <br />
            <br />
            3. Social Media Management: Assist in managing Remoteli's social
            media accounts, including content creation, scheduling posts,
            engaging with followers, and monitoring analytics to track
            performance.
            <br />
            <br />
            4. Document Creation: Prepare documents, reports, and presentations
            using Microsoft Office suite (Word, Excel, PowerPoint) and Canva,
            ensuring accuracy and professionalism in all deliverables.
            <br />
            <br />
            5. Data Entry and Management: Perform data entry tasks, update
            records, and maintain databases accurately and efficiently, ensuring
            data integrity and confidentiality.
            <br />
            <br />
            6. Communication Support: Assist in drafting emails, newsletters,
            and announcements, and coordinate communication efforts to keep team
            members informed and engaged.
            <br />
            <br />
            7. Event Coordination: Support the planning and execution of company
            events, workshops, and webinars, including logistics coordination,
            attendee management, and post-event follow-up.
            <br />
            <br />
            8. Research Assistance: Conduct research on various topics as
            needed, compile findings into comprehensive reports, and present
            insights to support decision-making processes.
            <br />
            <br />
            9. Social Media Analytics: Monitor social media metrics and
            analytics, analyze trends, and generate reports to assess the
            effectiveness of social media campaigns and inform future
            strategies.
            <br />
            <br />
            10. Collaborative Projects: Collaborate with cross-functional teams
            on special projects, initiatives, and campaigns, providing
            administrative and creative support as required.
            <br />
            <br />
          </p>
        </div>
      </div>

      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-1">
        <div className="font-outfit">
          <div className="">
            <div className={` ${data && "bg-white"} rounded-xl my-2`}>
              <div className="px-5 py-4 flex justify-between items-center">
                <h1 className="text-2xl font-outfit font-semibold">
                  Job items
                </h1>
                <div className="flex gap-x-3 items-start">
                  <CopyIcon />
                  <ShareIcon />
                  <NewPrint />
                  <button
                    onClick={handleAddJob}
                    className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                        fill="white"
                      />
                    </svg>
                    <span>Add Product </span>
                  </button>
                </div>
              </div>
              <div className="">
                {data.length === 0 ? (
                  <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                    <EmptyInterface
                      description="No positions created yet..."
                      buttonText="Add a new position"
                      showButton={false}
                      buttonAction={() => null}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="">
                      <JobTable showJD={showJD} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={<TopLoader />}>
        <ViewJD show={show} setShow={setShow} />
      </Suspense>
    </>
  );
}

export default JobCompany;
