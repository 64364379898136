import CandidatesIcon from "@components/svgs/companiesbig";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import data from "../dataview/data.json";
import Badge from "@components/badge";
import avartar from "@assets/leadavartar.png";
import EditIcon from "@components/svgs/edit";
import Tabs from "@components/tabs";

function ViewLead() {
  const navigate = useNavigate();
  const {id} = useParams();

  const [selected, setSelected] = useState<any>(null);

  useEffect(() => {
    const lead = data.find((item) => item?.id?.toString() === id);
    setSelected(lead);
  }, []);

  const tabs = [
    {
      name: "Job items",
      href: `/sales/leads/view/${id}`,
    },
    {
      name: "Notes",
      href: `/sales/leads/view/${id}/notes`,
    },
    {
      name: "Tasks",
      href: `/sales/leads/view/${id}/tasks`,
    },
  ];

  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">{selected?.name}</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="grid grid-cols-1 bg-[rgba(221,239,255,0.2)] gap-6 lg:gap-0 py-4 px-5 lg:grid-cols-5 rounded-xl">
        <div className="flex flex-col lg:col-span-2 pe-6 lg:border-r gap-3">
          <div className="flex items-center">
            <h1 className="text-2xl font-semibold">Catenae Innovations</h1>
            <div className="ms-4">
              <Badge status="Active" text="Active" />
            </div>
          </div>
          <span className="text-[#82868C] text-sm">Online Publishing</span>
          <span className="text-custom_blue text-sm font-light">
            https://www.10pmcurfew.com/
          </span>
          <h1 className="text-xl font-semibold">$60,000.00</h1>

          <div className="flex px-4 items-center justify-between bg-white py-3 rounded-xl">
            <div className="flex items-center gap-2">
              <img
                src={avartar}
                alt="user"
                className="w-10 h-10 rounded-full"
              />
              <span className="text-sm">John Doe</span>
            </div>
            <Badge
              status="Lead sales person"
              text="Lead sales person"
              colors={{
                bg: "bg-custom_dark_blue",
                text: "text-white",
              }}
            />
          </div>
        </div>

        <div className="lg:col-span-3 ps-6 flex flex-col gap-3">
          <div className="justify-between flex items-center">
            <span>About:</span>
            <EditIcon />
          </div>
          <span className="text-[#82868C]">
            Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </span>

          <div className="grid lg:grid-cols-3 grid-cols-1 mt-6 gap-y-4 lg:gap-y-0">
            <div className=" flex flex-col gapy-1">
              <p>Address</p>

              <span className="text-custom_gray text-sm">
                1120 Commerce Drive
              </span>
              <span className="text-custom_gray text-sm">
                Los Angeles, California
              </span>
            </div>

            <div className=" flex flex-col gapy-1">
              <p>Email address</p>

              <span className="text-custom_gray text-sm">
                partnerships@10pmcurfew.com
              </span>
            </div>

            <div className="flex flex-col gapy-1">
              <p>Phone number</p>
              <span className="text-custom_gray text-sm">+44 7508 397545</span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[rgba(221,239,255,0.2)] mt-5 px-5 rounded-xl">
        <Tabs tabs={tabs} />
        <Outlet />
      </div>
    </div>
  );
}

export default ViewLead;
