import {Disclosure, Transition} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import MeetingsTable from "./dataview";

function Work() {
  return (
    <>
      <Disclosure>
        {({open}) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-6 text-left text-sm font-medium text-black  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
              <span>Meetings</span>
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-6 w-6 text-black-500`}
              />
            </Disclosure.Button>
            <Transition
              enter="transition ease-out duration-300 transform"
              enterFrom="opacity-0 translate-y-[-10%]"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-[-10%]"
            >
              <Disclosure.Panel className=" pb-2 bg-white rounded-es-lg rounded-ee-lg  text-sm text-gray-500">
                <MeetingsTable />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  );
}

export default Work;
