 

function Productstatsicon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 20.5C5.55228 20.5 6 20.0523 6 19.5C6 18.9477 5.55228 18.5 5 18.5C4.44772 18.5 4 18.9477 4 19.5C4 20.0523 4.44772 20.5 5 20.5Z"
        fill="#6C63FF"
      />
      <path d="M4 4.5H6V13.5H4V4.5Z" fill="#6C63FF" />
      <path
        d="M7 2.5H3C2.73478 2.5 2.48043 2.60536 2.29289 2.79289C2.10536 2.98043 2 3.23478 2 3.5V21.5C2 21.7652 2.10536 22.0196 2.29289 22.2071C2.48043 22.3946 2.73478 22.5 3 22.5H7C7.26522 22.5 7.51957 22.3946 7.70711 22.2071C7.89464 22.0196 8 21.7652 8 21.5V3.5C8 3.23478 7.89464 2.98043 7.70711 2.79289C7.51957 2.60536 7.26522 2.5 7 2.5ZM7 21.5H3V3.5H7V21.5Z"
        fill="#6C63FF"
      />
      <path
        d="M12 20.5C12.5523 20.5 13 20.0523 13 19.5C13 18.9477 12.5523 18.5 12 18.5C11.4477 18.5 11 18.9477 11 19.5C11 20.0523 11.4477 20.5 12 20.5Z"
        fill="#6C63FF"
      />
      <path d="M11 4.5H13V13.5H11V4.5Z" fill="#6C63FF" />
      <path
        d="M14 2.5H10C9.73478 2.5 9.48043 2.60536 9.29289 2.79289C9.10536 2.98043 9 3.23478 9 3.5V21.5C9 21.7652 9.10536 22.0196 9.29289 22.2071C9.48043 22.3946 9.73478 22.5 10 22.5H14C14.2652 22.5 14.5196 22.3946 14.7071 22.2071C14.8946 22.0196 15 21.7652 15 21.5V3.5C15 3.23478 14.8946 2.98043 14.7071 2.79289C14.5196 2.60536 14.2652 2.5 14 2.5ZM14 21.5H10V3.5H14V21.5Z"
        fill="#6C63FF"
      />
      <path
        d="M19 20.5C19.5523 20.5 20 20.0523 20 19.5C20 18.9477 19.5523 18.5 19 18.5C18.4477 18.5 18 18.9477 18 19.5C18 20.0523 18.4477 20.5 19 20.5Z"
        fill="#6C63FF"
      />
      <path d="M18 4.5H20V13.5H18V4.5Z" fill="#6C63FF" />
      <path
        d="M21 2.5H17C16.7348 2.5 16.4804 2.60536 16.2929 2.79289C16.1054 2.98043 16 3.23478 16 3.5V21.5C16 21.7652 16.1054 22.0196 16.2929 22.2071C16.4804 22.3946 16.7348 22.5 17 22.5H21C21.2652 22.5 21.5196 22.3946 21.7071 22.2071C21.8946 22.0196 22 21.7652 22 21.5V3.5C22 3.23478 21.8946 2.98043 21.7071 2.79289C21.5196 2.60536 21.2652 2.5 21 2.5ZM21 21.5H17V3.5H21V21.5Z"
        fill="#6C63FF"
      />
    </svg>
  );
}

export default Productstatsicon;
