
import TotalEmployeesIcon from "@components/svgs/totalemployees";
import EmployeeIcon from "@components/svgs/employeesicon";
import IncreaseIcon from "@components/svgs/increase";
import DecreaseIcon from "@components/svgs/decrease";
const stats = [
  {
    name: "Total accounts",
    value: "5223",
    change: "+4.75%",
    changeType: "positive",
    icon: EmployeeIcon,
    subicon: DecreaseIcon,
  },
  {
    name: "Active users",
    value: "5000",
    change: "+54.02%",
    changeType: "negative",
    icon: TotalEmployeesIcon,
    subicon: IncreaseIcon,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function EmployeeStats() {
  return (
    <div>
      <div className="border-b py-4 bg-white border-custom_lighter_gray lg:border-t border rounded-xl">
        <dl className="mx-auto grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 lg:px-2 xl:px-0">
          {stats.map((stat, statIdx) => (
            <div
              key={stat.name}
              className={classNames(
                statIdx % 2 === 1
                  ? "sm:border-l"
                  : statIdx === 2
                  ? "lg:border-l"
                  : "",
                "flex  items-baseline flex-wrap justify-between gap-y-1 gap-x-4 border-t border-gray-900/5 px-4 py-2 sm:px-6 lg:border-t-0 xl:px-5"
              )}
            >
              <div className="flex justify-between w-full items-center">
                <dd className="font-outfit flex-none text-xl font-semibold leading-10 tracking-tight text-gray-900">
                  {stat.value}
                </dd>

                <div className="shadow-md p-2 rounded-lg">
                  <stat.icon
                    className={classNames(
                      stat.changeType === "negative"
                        ? "text-rose-600"
                        : "text-gray-700",
                      "text-xs font-medium "
                    )}
                  />
                </div>
              </div>
              <dt className="w-full font-normal font-outfit leading-6 text-gray-900">
                {stat.name}
              </dt>

              <div className="w-full mt-2 text-sm flex gap-x-2">
                <stat.subicon />
                <dt className="text-custom_gray">3.1</dt>
                <dt className="text-custom_gray ms-2">-1.01% this week</dt>
              </div>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

export default EmployeeStats;
