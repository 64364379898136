import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="m22.25 21.19-3.516-3.517a6.02 6.02 0 1 0-1.061 1.061l3.517 3.516 1.06-1.06ZM14 18.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm-9 0H2A1.502 1.502 0 0 1 .5 17v-3H2v3h3v1.5ZM.5 8H2v3H.5V8Zm18-3H17V2h-3V.5h3A1.501 1.501 0 0 1 18.5 2v3ZM8 .5h3V2H8V.5ZM2 5H.5V2A1.502 1.502 0 0 1 2 .5h3V2H2v3Z"
    />
  </svg>
);
export default SvgComponent;
