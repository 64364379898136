import {TrashIcon} from "@heroicons/react/24/outline";

function Card({data, onDelete}: any) {
  return (
    <div className="relative bg-white rounded-2xl py-3 px-2">
      <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
        <img
          src={data.image}
          alt="department-image"
          className="pointer-events-none w-full h-48 object-cover group-hover:opacity-75"
        />
      </div>
      <div className="flex justify-between mt-2 items-center">
        <p className="pointer-events-none  block truncate text-xl font-medium text-gray-900">
          {data.title}
        </p>
        <button onClick={() => onDelete(data)}>
          <TrashIcon className="h-5 w-5 text-red-500" />
        </button>
      </div>
      <p className="pointer-events-none block text-sm font-light text-[#7C8DB5]">
        {data.subtext}
      </p>
      <button
        type="button"
        onClick={() => data.onClick(data.title?.replace(/\s/g, "-"))}
        className="rounded-full z-10 bg-custom_orange text-white text-sm font-light px-3 py-1 mt-5"
      >
        {data.buttonText}
      </button>
    </div>
  );
}

export default Card;
