import TotalIncome from "./totalincome";
import PieChartGraph from "./piechart";
import SignUps from "./signups";

function Graphs() {
  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 lg:grid-cols-10 lg:px-2 xl:px-0">
        <TotalIncome />
        <PieChartGraph />
        <SignUps />
      </div>
    </div>
  );
}

export default Graphs;
