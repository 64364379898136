import InterternalProjects from "@components/stats/internalprojectstats";
import CandidatesIcon from "@components/svgs/teamsbig";

import {Outlet} from "react-router-dom";
import Tabs from "@components/tabs";
function CurrentPosition() {
  const tabs = [
    {
      name: "Projects",
      href: `/area/internal_projects`,
      count: 40,
    },
    {
      name: "Completed projects",
      href: `/area/internal_projects/completed_projects`,
    },
  ];

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <InterternalProjects />
          </div>
          <CandidatesIcon />
        </div>
      </div>
      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-1">
        <Tabs tabs={tabs} />
        <Outlet />
      </div>
    </>
  );
}

export default CurrentPosition;
