 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={181}
    height={133}
    fill="none"
    {...props}
  >
    <path
      fill="#F2F9FE"
      d="M12.223 127.016C-11.261 102.991-1.109 65.672 44.45 57.149c45.559-8.524 55.36-59.49 96.768-48.846 41.409 10.643 57.126 88.947 14.613 118.713H12.224Z"
    />
    <path
      fill="#F2F9FE"
      d="M121.485 127.016c0 1.06 9.227 1.899 20.572 1.899 11.346 0 20.573-.839 20.573-1.899s-9.227-1.899-20.573-1.899c-11.345 0-20.572.883-20.572 1.899ZM65.773 132.978c-22.09 0-39.996-1.759-39.996-3.93 0-2.171 17.907-3.931 39.996-3.931 22.09 0 39.996 1.76 39.996 3.931 0 2.171-17.907 3.93-39.996 3.93Z"
    />
    <path
      fill="#61A9FF"
      d="M46.436 88.594c-.044 6.183.53 12.101 2.163 17.4 4.503 14.884 16.953 25.13 46.133 24.202 39.599-1.28 46.795-38.114 50.106-72.562 2.693-27.956-5.695-48.492-30.902-55.25C108.064.796 101.31 0 93.54 0 57.914 0 46.57 65.054 46.436 88.594Z"
    />
    <path
      fill="#7EB9FF"
      d="M48.6 106.039c4.503 14.883 16.952 25.129 46.133 24.202 39.599-1.281 46.795-38.114 50.106-72.562 2.692-27.956-5.695-48.493-30.903-55.25 21.632 13.205 27.018 37.23 18.188 73.843-8.829 36.568-31.167 47.211-45.602 47.256-14.48 0-32.624-4.726-37.922-17.489Z"
    />
    <path
      fill="#61A9FF"
      d="M103.562 34.536c28.253 5.035 15.937 45.401 9.668 56.663-6.225 11.306-8.918 24.688-23.31 25.262-14.435.574-32.844-5.962-34.654-10.688-1.766-4.77 6.357-29.546 6.357-29.546s1.148-25.085 8.962-31.665c7.858-6.58 23.618-11.704 32.977-10.026Z"
    />
    <path
      fill="#283252"
      d="M65.773 102.594c5.165 9.628 22.735 13.028 28.783 11.527 3.532-.884 7.814-4.108 11.655-8.745 2.472-4.372 4.459-9.407 7.063-14.132.971-1.767 2.119-4.24 3.179-7.2 2.163-10.378 2.075-22.655-1.943-26.851-5.474-5.697-10.816-9.672-11.213-13.205-.309-2.782 2.825-6.404 2.207-9.01a13.15 13.15 0 0 0-1.898-.397c-7.725-1.37-19.777 1.81-28.165 6.669-.795 3.047-.883 7.154-2.781 8.48-2.914 2.03-7.417 14.794-8.565 20.756-1.192 5.963 1.678 32.108 1.678 32.108Z"
      opacity={0.05}
    />
    <path
      fill="#27CE88"
      d="M144.176 103.345c5.474-2.032 8.476-1.237 10.153-3.445 0 0-4.458-.795-6.931 0l-3.222 3.445Z"
    />
    <path
      fill="#27CE88"
      d="M147.399 99.856c6.666-3.49 8.608-7.42 10.33-10.158 1.677-2.738 2.648-4.99 3.311-5.167.662-.177-2.737.485-5.916 3.665-3.178 3.18-4.503 3.666-7.063 6.227-2.517 2.518-4.812 6.272-5.077 8.922h1.192l3.223-3.49Z"
    />
    <path
      fill="#27CE88"
      d="M145.5 98.928c3.576-5.52.353-8.7 4.68-14.971 5.076-7.332 3.487-14.663 2.737-19.3 0 0 1.147 5.344-3.311 10.6-4.415 5.21-8.741 7.242-9.139 15.015-.397 7.773 1.148 13.028 1.148 13.028h2.517l1.368-4.372Z"
    />
    <path
      fill="#27CE88"
      d="M142.233 103.345c1.192-5.168-1.147-14-1.324-22.745-.177-8.745 4.68-15.237-2.516-23.407 0 0 1.501 1.502-.574 7.508-2.075 6.006-6.666 12.322-4.415 20.404 2.252 8.082 7.108 14.706 7.064 18.24h1.765Z"
    />
    <path
      fill="#27CE88"
      d="M140.468 103.344c.75-6.227-5.563-15.899-10.948-18.063 0 0-.574 4.77 2.913 8.612 3.488 3.887 6.048 9.407 6.048 9.407h1.987v.044Z"
    />
    <path
      fill="#27CE88"
      d="M138.525 103.344c-.22-4.107-3.796-5.564-7.46-6.624 0 0 4.414 4.46 5.165 6.624h2.295Z"
    />
    <path
      fill="#0D6942"
      d="M136.804 103.786c-.619-3.136-2.031-4.107-1.766-4.284.265-.177 1.854 2.473 2.648 3.798 0 0 1.06-3.577-.529-6.624a59.302 59.302 0 0 0-3.135-5.389s5.165 5.963 5.43 9.01c0 0 .53-3.047-.353-6.978-.883-3.93-3.223-7.684-2.384-12.322.839-4.637 1.766-9.583 1.766-9.583s-.397 4.725-.839 8.788c-.441 4.108.751 6.227 1.943 10.6 1.192 4.372.529 10.599 1.015 10.555.53-.044 1.324 1.148 1.324 1.148s.53-6.713 1.325-12.41c.794-5.697.706-6.492 4.194-9.893 3.487-3.4 3.929-5.476 3.929-5.476s-2.34 4.681-4.282 7.729c-1.943 3.047-2.561 4.372-2.561 9.716 0 5.344-.265 8.347-.706 9.23-.442.839 3.311-5.079 6.269-7.11 2.957-2.032 5.341-4.77 5.606-5.256.265-.441-2.869 5.167-5.165 6.713-2.34 1.502-6.357 6.713-6.357 6.713l6.622-1.767-7.505 3.136h-6.489v-.044Z"
      opacity={0.36}
    />
    <path
      fill="#3A3852"
      d="m130.888 105.023 3.532 20.315c.044.265.132.486.265.663a2.022 2.022 0 0 0 1.765 1.016h12.67c1.016 0 1.855-.751 2.031-1.723l3.311-20.315c.221-1.237-.75-2.385-2.031-2.385h-19.512a2.063 2.063 0 0 0-2.031 2.429Z"
    />
    <path
      fill="#232433"
      d="M134.64 126.001a2.024 2.024 0 0 0 1.766 1.016h12.714c1.016 0 1.855-.751 2.031-1.723l3.311-20.315c.221-1.237-.75-2.385-2.031-2.385h-6.71c6.225 9.672-4.15 20.934-11.081 23.407Z"
      opacity={0.54}
    />
    <path
      fill="#AF2F48"
      d="M27.41 117.919c-.22 3.621-10.728-1.016-12.096-3.003 0 0 5.739 16.517 8.74 16.517 3.003 0 11.17-8.612 11.17-8.612l-.971-4.902H27.41Z"
    />
    <path
      fill="#D35473"
      d="M24.055 131.433c3.267 1.413-4.9-19.168-8.741-18.682-3.885.442 4.503 16.871 8.74 18.682Z"
    />
    <path
      fill="#113867"
      d="M26.174 94.114c.22 10.644 2.957 23.805 2.957 23.805h6.799s4.194-8.966 6.136-17.975c.486-2.252.84-4.549.971-6.669 0 0 10.552 3.18 20.705 1.28 4.238-.794 9.05-2.119 13.2-3.444 5.827-1.81 10.418-3.577 10.418-3.577l-.353-.442-7.416-9.407-19.999-3.003-2.34-.353-14.215 4.416c-12.625 1.325-17.084 4.726-16.863 15.37Z"
    />
    <path
      fill="#022A53"
      d="M42.066 99.9c.486-2.253.84-4.55.972-6.67 0 0 10.55 3.18 20.704 1.281 4.238-.794 9.05-2.12 13.2-3.444l10.065-4.02.883-.353 1.678-6.978-21.5-7.507-8.476 2.428-14.215 4.064c3.444 6.183-3.487 13.514-4.988 15.678-1.501 2.208-5.828-1.37-8.565 0-2.78 1.369 8.08.75 10.242 5.52Z"
      opacity={0.54}
    />
    <path
      fill="#AF2F48"
      d="M38.049 123.836c-3.135 6.802-7.77 10.026-10.242 8.966l1.06-11.262 4.458-1.943 4.724 4.239Z"
    />
    <path
      fill="#D35473"
      d="M27.807 132.802c5.518.927 4.459-9.716 4.15-14.884-.31-5.167-4.635-4.725-5.077-.706-.441 4.019-2.737 14.972.927 15.59Z"
    />
    <path
      fill="#113867"
      d="m33.37 119.597 4.68 4.24c19.114-5.521 30.372-22.966 30.372-22.966 10.639 12.322 22.514 15.105 32.094 0 0 0-.618-6.712-8.344-11.88-1.5-1.016-3.266-1.943-5.385-2.782-8.3-3.268-19.337-3.93-26.135-3.975-4.106-.044-7.946 1.988-10.33 5.344-4.018 5.653-7.814 12.675-10.816 18.681-3.708 7.464-6.136 13.338-6.136 13.338Z"
    />
    <path
      fill="#022A53"
      d="m33.37 119.597 4.68 4.239c19.114-5.52 30.372-22.965 30.372-22.965 10.639 12.322 22.514 15.104 32.094 0 0 0-.618-6.713-8.344-11.88l-7.99-1.502c9.359 18.373-13.112 14.089-17.173 10.732-4.061-3.356-7.902-6.713-9.712-6.89-1.81-.176 7.725 6.89 1.015 14.928-6.754 8.038-22.293 13.073-18.762 0-3.752 7.464-6.18 13.338-6.18 13.338Z"
      opacity={0.54}
    />
    <path
      fill="#09315C"
      d="M79.988 28.133h.265s2.825 10.864 7.681 14.883c.883.707 1.81 1.237 2.782 1.413 6.533 1.193 12.184-6.227 13.243-11.306.618-2.826-.441-4.593-1.501-5.564-.838-.796-1.633-1.105-1.633-1.105s-.441-8.038-3.223-7.905c-2.693.133-2.251 1.192-2.207 1.28-.176-.22-6.004-7.993-10.065-7.154-4.106.883-1.236 6.89-1.236 6.89s-5.165-5.212-6.887-2.208c-1.722 3.003 3.355 6.668 3.355 6.668s-4.37-.088-4.812 1.502c-.353 1.546.265 3.047 4.238 2.606Z"
    />
    <path
      fill="#0B2B4E"
      d="M87.934 43.016c.883.707 1.81 1.237 2.782 1.413 6.533 1.193 12.184-6.227 13.243-11.306.618-2.826-.441-4.593-1.501-5.564-.971 1.236-2.251 3.798-5.209.574h-1.368l-7.947 14.883Z"
    />
    <path
      fill="#8C8DFF"
      d="m70.629 75.256 29.754 27.603c1.457-1.281 2.693-2.65 3.797-4.063a28.768 28.768 0 0 0 4.503-8.303c.795-2.164 1.324-4.417 1.678-6.713.794-5.212.706-10.688.353-16.12-.795-12.72-4.592-21.2-19.866-21.42 0 0-6.975-1.943-12.184.84-4.062 2.207-6.71 18.371-7.682 25.217-.22 1.81-.353 2.959-.353 2.959Z"
    />
    <path
      fill="#4142B5"
      d="M76.942 62.713c15.672 5.874 22.161-5.52 22.161-5.52l5.077 18.195-10.816 3.357-18.85-1.943 2.428-14.089Z"
      opacity={0.36}
    />
    <path
      fill="#4142B5"
      d="m70.63 75.256 29.754 27.603c1.456-1.281 2.693-2.65 3.796-4.064-5.165-.397-16.29-12.763-13.376-13.425 2.605-.575 11.61 5.564 17.879 5.123.795-2.164 1.324-4.417 1.678-6.713l-9.536-8.347-29.754-3.136c-.31 1.81-.442 2.959-.442 2.959Z"
      opacity={0.36}
    />
    <path
      fill="#F4B8AB"
      d="M87.36 71.59c-3.487-2.34-7.549-.397-9.005.663-1.457 1.06 0 3.798 0 3.798s1.589-.927 2.118-1.06c.53-.132 3.047 1.855 8.08 1.06 5.032-.795-1.193-4.46-1.193-4.46Z"
    />
    <path
      fill="#8C8DFF"
      d="M107.8 55.117c-6.843-5.123-10.727-.618-10.418 8.215l.353 8.833s-8.741-2.783-10.86-2.783c0 0-.177 6.05 0 8.215 0 0 10.55 10.246 17.526 10.997 6.975.795 5.562-13.029 6.18-19.388.618-6.36-.397-12.322-2.781-14.089Z"
    />
    <path
      fill="#C5E5FC"
      d="m79.59 76.448-5.209-21.066a4.815 4.815 0 0 0-4.68-3.666H39.86c-1.942 0-3.4 1.855-2.914 3.754l6.004 22.038 36.642-1.06Z"
    />
    <path
      fill="#EAF6FF"
      d="m77.472 76.448-5.21-21.066a4.815 4.815 0 0 0-4.679-3.666H37.74c-1.942 0-3.399 1.855-2.913 3.754l6.004 22.038 36.641-1.06Z"
    />
    <path
      fill="#C5E5FC"
      d="M49.527 78.568H87.45c.574 0 1.06-.486 1.06-1.06 0-.574-.486-1.06-1.06-1.06H49.527c-.574 0-1.06.486-1.06 1.06 0 .574.486 1.06 1.06 1.06Z"
    />
    <path
      fill="#EAF6FF"
      d="M40.61 78.568H78.53c.574 0 1.06-.486 1.06-1.06 0-.574-.486-1.06-1.06-1.06H40.609c-.573 0-1.059.486-1.059 1.06 0 .574.442 1.06 1.06 1.06Z"
    />
    <path
      fill="#C5E5FC"
      d="M53.28 64.568c.485 2.738 1.721 4.814 2.78 4.637 1.06-.176 1.458-2.561.972-5.3-.486-2.738-1.722-4.813-2.781-4.637-1.06.177-1.457 2.562-.971 5.3Z"
    />
    <path
      fill="#F4B8AB"
      d="M78.266 29.855c-.662 9.716.883 14 6.401 14.53v2.252a3.053 3.053 0 0 0 3.046 3.048 3.053 3.053 0 0 0 3.047-3.048v-2.252s4.414-1.855 5.297-4.637c.883-2.738 0-.442 0-.442l.397-11.218h-18.32l.132 1.767Z"
    />
    <path
      fill="#F4B8AB"
      d="M95.704 35.11c1.369-2.517 3.002-1.457 3.355 0 .31 1.458-2.604 5.035-3.355 3.798-.706-1.236 0-3.798 0-3.798Z"
    />
    <path
      fill="#09315C"
      d="M98.75 26.852c-2.958 3.622-11.301 5.476-14.347 4.593-3.047-.84 1.28-3.666 1.28-3.666s-5.607 3.887-8.167 2.562c-2.56-1.28 4.37-4.46 4.37-4.46l16.864.971Z"
    />
    <path
      fill="#DD6595"
      d="M84.667 44.43c2.428.22 5.254.132 5.254.132l-5.254 2.12v-2.253ZM95.88 35.817c1.237-1.28 1.855-.75 1.767.574-.089 1.37-1.722 2.297-2.252 1.944-.53-.398.486-2.518.486-2.518Z"
      opacity={0.53}
    />
    <path
      fill="#61A9FF"
      d="M113.23 91.243c-2.605 4.726-4.591 9.805-7.064 14.133 2.693-3.224 5.21-7.155 7.196-11.439 1.236-2.738 2.296-6.183 3.09-9.893-1.103 2.915-2.207 5.389-3.222 7.2Z"
    />
  </svg>
);
export default SvgComponent;
