
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={279}
    height={127}
    fill="none"
    {...props}
  >
    <path
      fill="#61A9FF"
      d="M273.4 126.929s16.789-41.7-7.773-66.602c-31.679-32.111-72.608-5.266-87.967-6.055-15.359-.777-16.226-31.593-62.115-35.28-35.477-2.85-93.746-3.075-81.319 47.272C43.676 104.536-8.907 84.71 1.328 127l272.072-.071Z"
      opacity={0.08}
    />
    <path
      fill="#7EB9FF"
      d="M215.612 106.076s3.266-3.821.201-6.255c0 0-2.231-1.357-4.061-1.251 0 0 2.586 4.349 1.743 4.782-.833.433-2.528-5.062-2.528-5.062s-5.086-.5-6.724.299c0 0 4.349 3.58 3.439 3.791-.92.212-5.613-3.3-5.613-3.3s-4.741.808-4.482 3.714c.249 2.906 1.886 3.801 2.605 3.724.718-.077 4.913-1.684 5.009-1.097.096.587-2.433 2.522-4.205 2.608 0 0 3.343 3.696 5.555 2.637 2.223-1.068 2.405-2.762 3.793-3.397 1.38-.635 2.567-.635 1.993.01-.575.644-3.075 1.279-3.87 2.684-.795 1.405-1.504 2.358.795 2.012 2.299-.347 6.025-2.031 6.187-3.965l.163-1.934Z"
    />
    <path
      fill="#7EB9FF"
      d="M199.905 102.871s12.863-.5 15.708 3.195c0 0 .632 1.886 2.557 3.609l.297.828s-2.232-2.358-3.027-2.502c0 .009 1.255-4.571-15.535-5.13Z"
    />
    <path
      fill="#7EB9FF"
      d="M218.17 109.685s2.758 2.05 2.95 5.716l-.498.048s-.882-3.791-2.472-5.273c-1.599-1.482.02-.491.02-.491ZM227.02 101.264s-5.191-2.916-2.768-6.881c0 0 1.935-2.435 4.013-3.06 0 0-1.111 5.909 0 6.053 1.111.135.757-6.679.757-6.679s5.469-2.646 7.614-2.434c0 0-3.39 5.774-2.279 5.63 1.111-.136 4.913-5.977 4.913-5.977s5.603-1.04 6.503 2.29c.901 3.34-.555 5.005-1.379 5.216-.833.212-6.158.135-6.024.838.134.692 3.735 1.809 5.746 1.183 0 0-2.212 5.495-5.124 5.216-2.902-.279-3.802-2.088-5.603-2.223-1.8-.135-3.113.346-2.212.837.9.491 3.946.164 5.402 1.405 1.455 1.242 2.634 2.002-.067 2.56-2.701.558-7.548.212-8.515-1.877l-.977-2.097Z"
    />
    <path
      fill="#7EB9FF"
      d="M243.206 91.256s-14.529 4.725-16.196 10.008c0 0 .067 2.367-1.379 5.071v1.049s1.523-3.541 2.356-4.032c0 0-3.256-4.59 15.219-12.096Z"
    />
    <path
      fill="#7EB9FF"
      d="M225.631 106.335s-2.242 3.416-.968 7.583l.575-.154s-.556-4.58.613-6.89c1.178-2.309-.22-.539-.22-.539ZM218.764 97.232s6.838-3.85 3.649-9.075c0 0-2.557-3.205-5.287-4.032 0 0 1.456 7.795 0 7.978-1.456.182-1.006-8.796-1.006-8.796s-7.202-3.484-10.037-3.205c0 0 4.472 7.603 3.007 7.42-1.456-.183-6.475-7.882-6.475-7.882s-7.384-1.376-8.572 3.022c-1.188 4.398.728 6.602 1.82 6.871 1.092.28 8.122.183 7.94 1.097-.182.914-4.923 2.387-7.567 1.56 0 0 2.922 7.236 6.753 6.87 3.831-.366 5.019-2.752 7.384-2.935 2.376-.183 4.109.462 2.922 1.097-1.188.645-5.201.212-7.117 1.848-1.915 1.636-3.467 2.646.096 3.377 3.553.732 9.942.279 11.216-2.473l1.274-2.742Z"
    />
    <path
      fill="#7EB9FF"
      d="M197.415 84.038s19.156 6.236 21.349 13.194c0 0-.096 3.117 1.82 6.688v1.376s-2.012-4.677-3.103-5.312c0 0 4.29-6.044-20.066-15.946Z"
    />
    <path
      fill="#7EB9FF"
      d="M220.583 103.929s2.95 4.504 1.274 9.998l-.756-.202s.737-6.043-.805-9.084c-1.551-3.051.287-.712.287-.712Z"
    />
    <path
      fill="#283252"
      d="M218.994 125.822c-7.404 0-13.4-6.024-13.4-13.463h26.799c0 7.429-6.005 13.463-13.399 13.463Z"
    />
    <path
      fill="#283252"
      d="M215.689 125.716c.546.068 1.102.106 1.667.106 7.404 0 13.399-6.024 13.399-13.463h-3.333c.01 6.862-5.114 12.53-11.733 13.357Z"
    />
    <path
      fill="#283252"
      d="M205.403 126.506h27.172a1.66 1.66 0 0 0-1.657-1.665H207.05c-.91 0-1.647.741-1.647 1.665ZM205.288 112.35h27.546v.413h-27.546v-.413Z"
    />
    <path
      fill="#060632"
      d="M63.806 120.921s-.34 5.513-1.395 5.584c-.14.011-5.381.011-5.381.011l-6.683-.035s.047-1.614 1.993-1.967c1.958-.342 5.23-1.72 6.413-4.323 1.231-2.721 5.053.73 5.053.73Z"
    />
    <path
      fill="#FFB8B8"
      d="M67.37 55.769s-3.153 1.802-4.044 1.802c-.88 0-4.35-.672-5.323-.177-.961.495-2.72 2.497-3.048 3.145-.587 1.178 2.38.66 3.775-.341.2-.142.457-.2.703-.142.727.165 2.24.436 2.967.165.984-.365 6.143-.765 6.143-.765l-1.172-3.687Z"
    />
    <path
      fill="#C6D3ED"
      d="M91.394 31.196s-3.272 23.37-8.688 25.467c-2.673 1.037-14.491 3.37-17.985 3.605 0 0-.856-.695-.645-3.534 0 0 12.38-4.57 14.14-7.056 2.614-3.699-1.63-15.101 1.664-18.576 3.271-3.464 8.805-4.96 11.514.094Z"
    />
    <path
      fill="#1F2844"
      d="M90.291 80.447c-.95 1.626-2.063 3.157-3.2 4.594-3.517 4.465-7.351 8.14-8.02 11.615-.129.695-.34 1.437-.62 2.226a28.144 28.144 0 0 1-1.02 2.498c-1.49 3.263-3.73 6.914-5.957 10.224a257.632 257.632 0 0 1-7.48 10.52l-6.648-.283c.27-.672 3.166-8.588 7.047-18.27 1.57-3.935 3.247-8.105 4.865-12.145.211-.542.434-1.072.645-1.602.493-1.237.985-2.45 1.454-3.628 3.634-9.094 6.542-16.327 6.542-16.327s14.55-5.537 16.062-1.944c1.255 2.969.235 5.867-3.67 12.522Z"
    />
    <path
      fill="#060632"
      d="M79.189 105.184s4.619 2.98 4.173 3.958c-.058.129-2.614 4.723-2.614 4.723l-3.295 5.843s-1.383-.825-.727-2.709c.657-1.885 1.056-5.431-.633-7.728-1.758-2.426 3.096-4.087 3.096-4.087Z"
    />
    <path
      fill="#283252"
      d="M76.926 110.426s2.884-2.556 3.4-4.44c0 0-11.9-14.325-14.48-17.54-.117-.142-.093-.307.07-.483 1.947-2.203 23.602-6.703 26.439-11.12a13.8 13.8 0 0 0 1.829-4.241c.645-2.698.129-4.559.129-4.559l-21.631-.624S54.673 78.68 52.633 86.36c-2.04 7.68 24.293 24.065 24.293 24.065Z"
    />
    <path
      fill="#1F2844"
      d="M65.905 87.962c1.946-2.202 23.601-6.702 26.438-11.12a13.795 13.795 0 0 0 1.83-4.24v-2.745l-9.192-1.449s-2.603 8.058-10.33 11.085c-7.233 2.827-8.593 7.845-8.746 8.47Z"
    />
    <path
      fill="#FFB8B8"
      d="M78.603 29.723c4.373 1.131 11.63-2.933 11.63-2.933s-2.814-1.896-3.857-6.078a5.78 5.78 0 0 1-.141-.648c-.457-.094-1.325-.283-2.251-.483l-3.341 3.004s.082 1.248.492 3.392c.07.377.152.778.246 1.202-1.794 1.13-2.778 2.544-2.778 2.544Z"
    />
    <path
      fill="#C6D3ED"
      d="M94.864 47.947c-.621 8.47.2 21.344-.516 23.582-.61 1.92-7.609.778-10.4.142a50.143 50.143 0 0 1-3.575-.978c-3.6-1.12-7.527-2.721-7.95-3.91-.01-.048-.034-.095-.034-.142a1.007 1.007 0 0 1 .011-.495c.141-.6.68-1.26.75-2.756.071-1.673.06-3.652.13-5.772 0-.236.011-.495.035-.742V56.652c.058-2.368.187-4.854.328-7.316.094-1.543.2-3.086.317-4.547.316-3.793.738-7.162 1.301-9.305.915-3.475 2.931-5.749 4.772-7.41l1.325-1.142c1.489.33 3.236.106 4.83-.648 1.724-.825 2.31-1.402 2.614-1.19 3.94 3.074 6.8 12.498 6.062 22.852Z"
    />
    <path
      fill="#C6D3ED"
      d="M80.373 70.682c-3.6-1.12-7.527-2.721-7.95-3.911-.01-.047-.034-.094-.034-.142 0-.164 0-.33.011-.494.141-.601.68-1.26.75-2.757.071-1.672.06-3.651.13-5.772 0-.235.011-.494.035-.742V56.64l14.573-18.647c-.023.012 9.778 20.497-7.515 32.689Z"
    />
    <path
      fill="#EA8888"
      d="M80.63 22.597s.083 1.248.493 3.392c3.26-1.06 4.748-4.017 5.24-5.277a5.736 5.736 0 0 1-.14-.648c-.457-.094-1.325-.283-2.251-.483l-3.341 3.016Z"
    />
    <path
      fill="#FFB8B8"
      d="M80.197 24.481s7.117-1.19 6.307-5.63c-.809-4.441-.433-7.586-5.006-7.01-4.572.578-5.147 2.427-5.24 3.982-.094 1.543 2.063 8.859 3.939 8.658Z"
    />
    <path
      fill="#283252"
      d="m52.17 62.505-.624.204-5.34-16.512.625-.204 5.34 16.512Z"
    />
    <path
      fill="#283252"
      d="m69.698 62.301-18.133.39-.024-1.096 18.134-.389.023 1.095Z"
    />
    <path
      fill="#FFB8B8"
      d="M69.317 63.178s-5.827 1.543-8.008 1.379c-2.18-.165-3.998-1.202-4.28-1.756-.269-.541-.855-1.507.634-1.33 1.489.176 3.517.494 3.576.211.059-.282-1.208-.259-1.208-.259s-.387-.565.106-.589c.48-.023 1.9-.412 2.427-.141.527.259 1.008 1.237 1.395 1.201.387-.035 4.877-2.038 4.877-2.038l.48 3.322Z"
    />
    <path
      fill="#C6D3ED"
      d="M89.951 32.162s-.691 23.594-5.85 26.28c-2.544 1.32-14.034 4.96-17.48 5.583 0 0-.927-.6-1.032-3.45 0 0 11.806-5.914 13.283-8.577 2.192-3.97-3.271-14.83-.387-18.647 2.884-3.816 8.219-5.913 11.466-1.19Z"
    />
    <path
      fill="#283252"
      d="M86.364 20.712s.082-1.52-.856-2.391c0 0 .328-1.532-.281-1.779-.598-.247-.727 1.037-1.009.836-.281-.2-1.5-1.154-2.028-2.568-.528-1.413-5.346.295-5.909.554-.563.26-2.368-.683-2.72-1.437 0 0 1.008-.801 1.454-1.413.445-.601 3.61-4.689 8.02-3.523 4.407 1.155 5.017 3.275 4.76 5.09-.224 1.801.374 6.019-1.431 6.631Z"
    />
    <path
      fill="#DDE8FD"
      d="M69.985 112.051c1.36 8.764 10.259 14.336 19.38 14.336h18.079l-1.419-51.065s-17.246-5.56-21.572-.271c-3.799 4.63-14.08 22.982-14.632 33.843a16.42 16.42 0 0 0 .164 3.157Z"
    />
    <path
      fill="#AEC4F2"
      d="M117.973 80.729s-24.387-12.51-29.006-4.335c-2.45 4.335-9.204 21.64-9.696 32.5-.48 10.555 8.617 17.493 17.973 17.493h16.625l4.104-45.658Z"
    />
    <path
      fill="#BED2FA"
      d="M113.388 87.668c.211 1.06 2.181 2.05 5.112 2.921a40.13 40.13 0 0 0 1.63.448c9.965 2.556 27.633 3.981 29.31 3.227 2.321-1.048 16.602-52.007 16.602-52.007s-13.624-.518-24.457-.766c-3.705-.082-7.082-.14-9.462-.13a5.96 5.96 0 0 0-.82.06c-1.032.153-1.806.577-2.404 1.237-1.747 1.884-2.169 5.595-3.787 9.706a744.91 744.91 0 0 0-5.405 14.16c-.48 1.295-.949 2.59-1.406 3.863-.129.365-.258.719-.387 1.072-2.744 7.727-4.854 14.489-4.526 16.209ZM69.985 112.051c1.36 8.764 10.259 14.336 19.38 14.336h8.864c-5.897-4.028-11.244-16.432-28.244-14.336Z"
    />
    <path
      fill="#ABBDE3"
      d="M117.914 71.47c.457-.07 1.043-.176 1.7-.282.14-.141.246-.283.316-.424.27-.589.082-2.285-.223-4.229a744.936 744.936 0 0 1 5.405-14.16c1.618-4.11 2.04-7.82 3.787-9.706.61-.66 1.372-1.095 2.404-1.236 1.442.023 2.954.047 4.525.07.551 1.108 1.513 2.156 3.178 3.028 7.398 3.91 1.911 11.968 1.911 11.968-.059.035-2.931 5.795-2.931 5.795.011-.4-.376-1.119-1.044-2.037-.492 3.44-1.29 6.95-2.239 9.47.527 2.663 1.864 6.609 2.579 9.636.575 2.415.75 4.229-.199 4.323a1.765 1.765 0 0 1-.352 0c.059.837-.105 1.732-.574 2.65-1.853 3.605-10.763 4.253-17.657 4.265-2.931-.872-4.901-1.861-5.112-2.921-.328-1.732 1.782-8.494 4.526-16.21Z"
    />
    <path
      fill="#BED2FA"
      d="M84.218 118.542c3.494 4.865 8.84 7.998 14.808 7.892l25.629.012 12.275.012-13.811-45.717s-34.973-3.499-39.276 1.166c-2.005 2.168-3.13 11.968-3.752 21.84-.363 5.571 1.255 10.778 4.127 14.795Z"
    />
    <path
      fill="#AEC4F2"
      d="M84.218 118.542c3.494 4.865 8.84 7.998 14.808 7.892l25.629.012c-3.013-2.544-12.498-9.765-22.651-9.424-7.703.271-13.823.154-17.786 1.52Z"
    />
    <path
      fill="#FCA09E"
      d="M115.733 48.735s-2.134 3.17-3.341 9.825c-1.067 5.83-1.993 7.986-3.529 9.176-1.536 1.19-10.095 4.724-10.095 4.724l.645 1.507s12.545-1.72 13.952-3.828c1.407-2.109 6.776-14.548 6.366-17.422-.434-2.85-3.998-3.982-3.998-3.982Z"
    />
    <path
      fill="#FDB3B1"
      d="M85.016 118.271c.117 1.225-1.595 1.531-3.565 1.625-1.97.095-1.371.507-4.08 1.002-2.708.494-3.786-.248-3.986-.625-.176-.33 1.079-.836 2.427-1.413.164-.071.34-.142.504-.224 1.513-.672 2.357-2.026 3.53-3.287 1.113-1.189 2.168-5.018 2.168-5.018l3.67-.789s-1.032 3.44-1.395 4.9c-.211.86.105 1.826.375 2.568.164.424.305.825.352 1.261Z"
    />
    <path
      fill="#FD396D"
      d="M81.451 119.897c-1.97.094-1.371.506-4.08 1.001-2.708.495-3.786-.247-3.986-.624-.176-.33 1.079-.837 2.427-1.414l.023.024s1.9.648 4.092.047c1.794-.483 3.518-1.567 4.749-1.909.152.401.293.813.34 1.237.105 1.237-1.595 1.543-3.565 1.638Z"
    />
    <path
      fill="#353F61"
      d="M121.595 74.498s-24.41-1.85-29.005 1.072c-4.596 2.91-11.97 36.858-11.97 36.858s.456.365 1.465.471c.95.106 3.025-.459 3.025-.459s10.598-15.184 11.337-19.012c.75-3.829.563-7.245 2.263-7.327 1.7-.095 22.874 2.591 26.086-3.087 3.201-5.666.551-11.284.551-11.284l-3.752 2.768Z"
    />
    <path
      fill="#FDB3B1"
      d="M94.348 122.205c.118 1.225-1.594 1.532-3.564 1.626-1.97.094-1.372.506-4.08 1.001-2.708.495-3.787-.247-3.986-.624-.176-.33 1.078-.837 2.427-1.414.164-.07.34-.141.504-.224 1.512-.671 2.356-2.026 3.529-3.286 1.114-1.19 2.169-5.018 2.169-5.018l3.67-.789s-1.032 3.439-1.396 4.9c-.21.86.106 1.826.376 2.568.164.424.316.836.351 1.26Z"
    />
    <path
      fill="#FD396D"
      d="M90.784 123.843c-1.97.094-1.372.507-4.08 1.001-2.708.495-3.787-.247-3.986-.624-.176-.33 1.078-.836 2.427-1.414l.023.024s1.9.648 4.092.047c1.794-.483 3.517-1.567 4.748-1.908.153.4.293.813.34 1.237.118 1.236-1.582 1.531-3.564 1.637Z"
    />
    <path
      fill="#353F61"
      d="M126.379 76.018s-21.69 1.036-26.169 4.123c-4.478 3.086-10.528 37.294-10.528 37.294s.469.353 1.489.424c.95.071 3.001-.577 3.001-.577s10.001-15.597 10.599-19.448c.598-3.852.281-7.257 1.981-7.41 1.7-.153 22.968 1.696 25.946-4.1 2.978-5.783-6.319-10.306-6.319-10.306Z"
    />
    <path
      fill="#E94A74"
      d="M118.652 28.074c.962-.636 2.158-.777 3.002-.79-.235-.211-.657-.258-.657-.258.516-.118 1.712.33 1.712.33 7.046-3.287 8.899 7.338 8.91 9.835.012 2.498.188 5.36 3.94 7.35 7.398 3.912 1.911 11.97 1.911 11.97-.059.034-2.931 5.795-2.931 5.795.047-2.026-9.884-12.004-14.902-14.854-1.289-1.65-1.911-4.689-1.958-4.924.364 2.415.868 3.746 1.29 4.488-4.338-3.51-2.872-17.257-.317-18.942Z"
    />
    <path
      fill="#FDB3B1"
      d="M127.293 45.096s-1.911.082-3.939.27c-1.372.13-2.837.283-3.611.613-1.911.801-1.712 4.206 2.298 3.57 4.009-.625 5.534-3.452 5.252-4.453Z"
    />
    <path
      fill="#FDB3B1"
      d="M119.919 47.57c-.024.011-.575 2.92 2.051 2.803 1.724-.07 5.44-2.167 5.112-3.652-.152-.706-1.454-2.532-1.723-6.714l-.317.082-5.276 1.426s.235 1.684.34 3.274c.094 1.343.106 2.627-.187 2.78Z"
    />
    <path
      fill="#FCA09E"
      d="M119.766 41.526s.235 1.684.34 3.275c2.99-.283 4.385-3.134 4.936-4.7l-5.276 1.425Z"
    />
    <path
      fill="#E94A74"
      d="M125.558 39.476s1.407-2.591 1.641-4.747c.164-1.543-.973-7.115-6.554-5.925 0 0-1.629.27-3.095 1.414-1.172.907-2.814 2.827-.516 7.61l.622 1.625 7.902.023Z"
    />
    <path
      fill="#FDB3B1"
      d="M118.875 43.105s7.082-.353 6.8-4.782c-.293-4.43.434-7.457-4.103-7.421-4.538.035-5.3 1.79-5.569 3.286-.27 1.496 1.02 8.894 2.872 8.917Z"
    />
    <path
      fill="#E94A74"
      d="M118.336 30.995s2.286 5.065 5.311 5.643c3.025.565 4.268-.319 4.268-.319s-2.404-1.672-3.084-4.818c-.012 0-5.205-3.074-6.495-.506Z"
    />
    <path
      fill="#E94A74"
      d="M118.84 31.384s-1.372 1.26-1.653 2.414c-.281 1.155-.281 3.487-.961 3.452 0 0-1.7-3.44-.188-5.548 1.606-2.239 2.802-.318 2.802-.318Z"
    />
    <path
      fill="#FF9777"
      d="M111.782 74.875c.574.683 2.368.895 4.279 1.213 1.501.247 3.084.565 4.209 1.225 2.556 1.508 10.904 6.597 13.354 6.373.95-.094.774-1.909.199-4.323-.902-3.817-2.802-9.106-2.802-11.403 0-1.26-.41-4.229-.973-7.633l-.14-.825c-1.102-6.608-2.627-14.395-2.627-14.395s-1.7 3.723-5.006 3.723-2.544-2.84-2.544-2.84-3.013.354-3.728 1.132c-.715.777-2.521 4.11-2.884 5.018 0 0-.071 1.154 2.778 1.708 0 0-.692 3.145-.774 5.242-.058 1.543 1.056 6.385 1.372 9.4.117 1.072.129 1.908-.047 2.285-.223.483-.938.966-1.747 1.45-1.594.918-3.599 1.825-2.919 2.65Z"
    />
    <path
      fill="#FF9777"
      d="M114.713 72.201c-1.829 3.134 4.96 4.076 6.085 4.747 2.556 1.508 10.364 6.974 12.815 6.738.949-.094.773-1.908.199-4.323-.903-3.817-2.802-9.106-2.802-11.403 0-1.26-.41-4.228-.973-7.633l-2.943-2.839s-3.154 8.199-6.167 9.542c-1.571.707-3.224 1.166-4.432 1.449.118 1.072.129 1.908-.047 2.285-.211.471-.926.966-1.735 1.437Z"
    />
    <path
      fill="#FDB3B1"
      d="M128.196 47.546s2.521-1.531 4.948 1.379c2.438 2.91-1.735 23.747-5.335 24.82-4.01 1.189-15.581-.19-15.581-.19l.222-1.813s12.323-2.38 12.838-3.04c.505-.66 1.63-20.814 2.908-21.156Z"
    />
    <path
      fill="#FF9777"
      d="M127.293 45.096s5.171.47 6.671 2.179c1.501 1.708 1.947 6.337 1.947 6.337s-4.549 3.7-10.036 2.262l1.418-10.778Z"
    />
    <path fill="#4E5A7E" d="M104.185 74.522h6.835v.812h-6.835v-.813Z" />
    <path
      fill="#4E5A7E"
      d="M105.639 75.228c-.071-.271-6.285-10.072-6.285-10.072H85.531l6.93 9.8 13.178.272Z"
    />
    <path
      fill="#283252"
      d="M105.052 75.334c-.07-.271-6.296-10.178-6.296-10.178H85.532l6.6 10.178h12.92Z"
    />
    <path
      fill="#7EB9FF"
      d="M93.399 70.28c.14.4.562.719.961.719.399 0 .61-.318.469-.719-.14-.4-.563-.719-.961-.719-.387 0-.598.319-.47.72Z"
    />
    <path
      fill="#FDB3B1"
      d="M113.459 71.623s-4.01-.777-4.467-.589c-.458.2-2.978 2.474-2.72 2.922.258.46 1.289.777 2.462.388 1.16-.388 3.693-.589 4.138-1.166.458-.577.587-1.555.587-1.555Z"
    />
    <path
      fill="#DDE8FD"
      d="M105.463 112.499c1.465 8.493 9.508 13.876 17.75 13.876h16.625l-1.301-51.065s-15.863-5.56-19.85-.27c-3.493 4.629-12.955 22.981-13.447 33.842a16.47 16.47 0 0 0 .223 3.617Z"
    />
    <path
      fill="#BED2FA"
      d="M105.463 112.499c1.465 8.493 9.508 13.876 17.75 13.876h13.307c-6.226-4.229-14.608-15.95-31.057-13.876Z"
    />
    <path
      fill="#BED2FA"
      d="M131.232 126.351c.129.012.247.012.376.024.257.012.527.024.785.024h16.883v-.012c.316 0 .633.012.961.012 1.712.023 3.494.047 5.159.058.281 0 .551 0 .821.012h.293c1.641.012 3.083.012 4.115.012a28.385 28.385 0 0 0 4.115-.318c1.665-.259 3.189-.695 4.584-1.319.504-.224.985-.472 1.454-.743a14.49 14.49 0 0 0 1.806-1.236c1.477-1.178 2.755-2.686 3.833-4.524.751-1.284 1.419-2.733 1.994-4.358.727-2.05 1.301-4.371 1.747-6.997.633-3.758 1.008-8.128 1.113-13.182.047-2.038.047-4.182.012-6.443-.059-3.617-.223-7.516-.481-11.733-.363-5.925-.926-12.45-1.664-19.636-.669-6.562-1.454-11.156-2.58-12.204-1.665-1.543-5.135-2.168-7.55-2.415-1.501-.153-2.591-.165-2.591-.165-2.955 0-5.581 2.132-6.519 5.29-1.934 6.443-5.37 17.963-8.957 30.014-.024.058-.035.117-.059.176-.211.695-.41 1.39-.621 2.085-.035.106-.059.212-.094.318-.199-.094-.41-.188-.633-.282-.012 0-.012-.012-.024-.012a93.958 93.958 0 0 0-2.884-1.225c-.539-.224-1.114-.448-1.688-.672a67.366 67.366 0 0 0-5.206-1.79 60.27 60.27 0 0 0-2.391-.66 37.49 37.49 0 0 0-3.025-.636c-.035-.012-.059-.012-.082-.012-.117-.023-.235-.035-.352-.059-4.268-.624-7.972-.188-9.508 2.521-2.451 4.347-9.204 21.71-9.696 32.595-.457 10.048 7.714 16.821 16.554 17.492Z"
    />
    <path
      fill="#DDE8FD"
      d="M157.893 81.13s8.535-34.927 9.145-37.86c.27-1.308 1.126-1.78 1.97-1.909-1.501-.153-2.591-.165-2.591-.165-2.955 0-5.581 2.12-6.519 5.278-2.063 6.879-5.874 19.566-9.731 32.5.199 0 7.726 2.155 7.726 2.155Z"
    />
    <path
      fill="#283252"
      d="m197.834 29.035-3.798-.365a.71.71 0 0 0-.716.424l-2.872 6.938a.7.7 0 0 0 .575.966l3.951.377a.703.703 0 0 0 .715-.435l2.708-6.95a.684.684 0 0 0-.563-.955Z"
    />
    <path
      fill="#1F2844"
      d="m149.695 120.633 2.016 1.072s10.892-11.603 13.518-22.746c.469-1.991.657-3.947.458-5.808 0 0 .164-.506.433-1.401.012-.036.024-.083.035-.118.118-.389.27-.848.434-1.379.528-1.684 1.243-3.981 2.075-6.608 1.9-6.031 6.238-19.648 8.09-25.303l-13.319-4.841s-1.512 3.463-3.681 12.887a70.283 70.283 0 0 0-.985 4.076 76.057 76.057 0 0 0-.961 5.5 85.12 85.12 0 0 0-.797 11.286c-.012 1.684.035 3.44.129 5.242.07 1.319-.118 3.086-.493 5.088-1.7 9.165-6.952 23.053-6.952 23.053Z"
    />
    <path
      fill="#0D0D38"
      d="M142.391 125.769h10.774s.399-1.731-.305-2.898c-.621-1.036-.387-1.625.082-2.485-.492.2-1.113.389-1.817.342-.539-.036-1.102-.271-1.606-.566-.469.53-2.451 2.792-3.072 3.24-.692.494-3.834.318-4.056 2.367Z"
    />
    <path
      fill="#1F2844"
      d="m157.784 75.978 8.371 15.666c.118-.388.27-.848.434-1.378.528-1.684 1.243-3.981 2.075-6.608 1.9-6.031 6.238-19.649 8.09-25.303l-13.319-4.841s-1.512 3.463-3.681 12.887a70.283 70.283 0 0 0-.985 4.075 60.737 60.737 0 0 0-.985 5.502Z"
    />
    <path
      fill="#FDB3B1"
      d="M194.047 41.415c.071.06 3.26-3.216 4.96-5.041l-1.712-1.049-9.321 5.878 6.073.212Z"
    />
    <path
      fill="#0D0D38"
      d="M176.954 122.377s.246 1.838-.446 3.393h-4.467s-.035-1.343 1.102-3.016c1.137-1.684 3.811-.377 3.811-.377Z"
    />
    <path
      fill="#283252"
      d="M168.16 2.79c-.058-.283-.82-3.357-5.557-2.697 0 0 2.368-.13 2.943.824 0 0-1.794-.99-3.013-.353-1.22.636-1.583 3.027-1.454 3.77.117.741 1.254 3.015 2.099 3.074.316.023.68-.06.984-.177-.152.377-.304.79-.433 1.249-1.208 4.205 1.383 7.656 2.907 6.961.973-.447 1.571 1.25 1.571 1.25l8.524-9.802c-.012 0-4.971-6.926-8.571-4.099Z"
    />
    <path
      fill="#FDB3B1"
      d="M176.872 29.071c-1.114.777-2.509 1.413-4.256 1.814-6.554 1.52-9.075-4.052-10.013-7.869.891-.518 1.735-.624 2.216-.683a21.24 21.24 0 0 1 1.384-.118c.738-.565 1.829-2.886 2.075-6.49l.305.082 5.17 1.425s-.235 1.65-.352 3.216c-.094 1.225-.105 2.392.129 2.674.399.083.645.142.645.142.082.117 1.583 2.603 2.697 5.807Z"
    />
    <path
      fill="#FC9492"
      d="M173.764 17.232s-.234 1.65-.351 3.216c-2.931-.295-4.291-3.098-4.819-4.63l5.17 1.414Z"
    />
    <path
      fill="#FDB3B1"
      d="M174.644 18.787s-6.953-.39-6.648-4.736c.305-4.347-.387-7.315 4.068-7.256 4.456.059 5.194 1.779 5.441 3.251.246 1.484-1.044 8.729-2.861 8.74Z"
    />
    <path
      fill="#283252"
      d="M177.469 7.62c-.984-1.39-1.805-1.155-2.297-.802-3.647-2.367-6.308.554-6.308.554-.692 3.086-3.048 4.712-3.048 4.712s1.207.872 4.185.33c.903-.165 1.747-.742 2.474-1.473-1.325 1.909-3.037 3.216-3.037 3.216 3.6-1.366 5.347-6.137 5.522-6.644.399.413 1.173 1.308 1.372 2.132.27 1.131.258 3.417.926 3.393 0 .024 1.689-3.345.211-5.419Z"
    />
    <path
      fill="#FDB3B1"
      d="M175.769 22.91s-11.325-2.757-16.249 1.319l2.462 34.479 22.147-5.372s-.622-2.18-4.866-7.986c-4.256-5.796-2.403-14.313-3.494-22.44Z"
    />
    <path
      fill="#283252"
      d="M169.169 54.868s.011.118.035.342c.011.094.023.212.035.341.094.895.281 2.603.504 4.842.61 5.842 1.559 15.242 2.216 22.652.504 5.796.832 10.378.656 11.12-.527 2.214 0 28.707 0 28.707 1.888.907 4.737 0 4.737 0s5.006-23.877 4.315-27.188c-.704-3.321.469-6.148.469-6.148 3.892-18.212 3.47-28.307 2.696-33.137-.223-1.401-.481-2.367-.668-2.945-.153-.494-.27-.73-.27-.73-8.582-2.332-14.725 2.144-14.725 2.144Z"
    />
    <path
      fill="#0D0D38"
      d="M161.982 58.708c2.637 1.119 5.275 1.602 7.761 1.684 2.274.083 4.443-.188 6.39-.612 4.244-.93 7.48-2.65 8.687-3.393-.222-1.401-.48-2.367-.668-2.945-2.614-1.873-9.075-.082-14.362 1.909l-.563.212c-4.033 1.543-7.245 3.145-7.245 3.145Z"
    />
    <path
      fill="#FF6B93"
      d="M157.995 34.065c.552 4.146 2.732 9.224 3.518 11.956 0 0-1.302 9.483-.059 12.086.141.295.317.507.528.601 4.619 2.026 22.604.059 23.354-5.772.282-2.12-6.847-9.565-7.269-10.472-.176-.377-.351-1.59.481-4.1 1.184 3.664 3.377 7.905 5.569 8.623 2.415.79 11.97-6.36 11.97-6.36s.059-.872-1.149-1.697c-.773-.53-2.555-.707-2.555-.707l-6.109 2.745c-.762-1.32-2.673-10.437-4.807-14.56-1.606-3.098-5.885-3.546-5.885-3.546s-1.782 2.84-6.097 2.439c-4.314-.4-5.1-3.051-5.1-3.051s-3.822.506-5.006 1.46c-.199.19-2.099 4.925-1.384 10.355Z"
    />
    <path
      fill="#FF6B93"
      d="M161.513 46.022s-1.302 9.482-.059 12.086c3.365-1.697 7.75-5.36 6.413-11.085-1.934-8.316-9.871-12.958-9.871-12.958.551 4.147 2.731 9.224 3.517 11.957Z"
    />
    <path
      fill="#FDB3B1"
      d="M164.385 39.648s4.784.695 5.17 1.06c.387.354 1.7 2.45 1.536 3.17-.164.73-.14 1.472-.433 1.425-.294-.047-1.255-1.897-1.607-1.98-.363-.082-.656-.33-.703-.176-.047.165.739.942.692 1.154-.036.224-.657-.035-.927-.47-.269-.425-.961-.637-1.067-.908-.105-.27-.574-1.154-1.219-1.107-.656.047-1.442-2.168-1.442-2.168Z"
    />
    <path
      fill="#FDB3B1"
      d="m159.719 39.46 4.666.188 1.595 2.18-6.343.212.082-2.58Z"
    />
    <path
      fill="#FF6B93"
      d="M164.385 22.262s-5.557.66-7.081 3.05c-1.935 3.028-8.758 13.052-8.618 16.092 0 0 4.256 1.708 15.699.789 0 0 .211-1.743-2.204-3.133 0 0-6.144-.849-6.824-1.45l4.561-4.864 4.467-10.484Z"
    />
    <path
      fill="#283252"
      d="m198.667 29.106-3.799-.366a.708.708 0 0 0-.715.425l-2.873 6.938a.7.7 0 0 0 .575.966l3.951.377a.702.702 0 0 0 .715-.436l2.708-6.95a.684.684 0 0 0-.562-.954Z"
    />
    <path
      fill="#FDB3B1"
      d="M199.007 36.374s1.008-1.743.926-2.167c-.07-.425-1.114-2.415-1.641-2.616-.95-.353-2.287-.565-1.923.436.246.66 1.207 1.626 1.032 1.932-.165.306-.106 1.367-.106 1.367l1.712 1.048Z"
    />
  </svg>
);
export default SvgComponent;
