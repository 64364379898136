import EmptyInterface from "@components/empty";
import {useNavigate} from "react-router-dom";
import data from "../../dataview/data.json";
import HolidayLists from "./dataview";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import EmptyBox from "@assets/svgs/emptybox.svg";

function holidays() {
  const navigate = useNavigate();
  const handleAddHoliday = () => {
    navigate("/people/leave/leave-type/sickness/addsickness");
  };
  return (
    <div className={`mt-8  ${data && "bg-white"}`}>
      <div className="px-5 py-4 flex justify-between items-center">
        <h1 className="text-2xl font-outfit font-semibold">Requests Today</h1>
        <div className="flex gap-x-3 items-center">
          <CopyIcon />
          <ShareIcon />
          <PrintIcon />
          <button
            onClick={handleAddHoliday}
            className="bg-custom_deep_blue text-sm text-white px-4 py-3 rounded-lg flex items-center gap-3"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                fill="white"
              />
            </svg>
            <span>Add a holiday</span>
          </button>
        </div>
      </div>
      <div className="">
        {[].length === 0 ? (
          <div className="mt-4  border-custom_lighter_gray rounded-xl">
            <EmptyInterface
              description="No holiday requests made today"
              buttonText="Add a holiday "
              buttonAction={handleAddHoliday}
              icon={EmptyBox}
            />
          </div>
        ) : (
          <div>
            <div className="mt-4">
              <HolidayLists />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default holidays;
