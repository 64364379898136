 
import TimeReportsTable from "./reports/timereports";
import TotalTimesTables from "./times/totaltime";

function Page() {
  return (
    <div className="bg-custom_faded_blue px-3">
      <TimeReportsTable />
      <TotalTimesTables />
    </div>
  );
} 

export default Page;
