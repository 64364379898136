import Pie2 from "./pie2";
import Pie1 from "./pie1";

function Graphs() {
  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 lg:grid-cols-10 lg:px-2 xl:px-0">
        <Pie1 />
        <Pie2 />
      </div>
    </div>
  );
}

export default Graphs;
