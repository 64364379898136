"use client";

import MonthSelector from "@components/dropdowns/monthselector";
import { useState } from "react";
 
import {LineChart, ResponsiveContainer, Line, XAxis} from "recharts";
function TotalIcome() {
  const data = [
    {
      name: "W1",
      uv: 2500,
      pv: 1000,
    },
    {
      name: "W2",
      uv: 2800,
      pv: 1398,
    },
    {
      name: "W3",
      uv: 1300,
      pv: 500,
    },
    {
      name: "W4",
      uv: 1800,
      pv: 800,
    },
    {
      name: "W5",
      uv: 1100,
      pv: 500,
    },
  ];

  const [selectedMonth, setSelectedMonth] = useState("January");
  return (
    <div className="col-span-3 flex flex-col justify-between bg-white rounded-xl px-3 py-3">
      <div className="flex flex-col gap-y-4">
        <div>
          <MonthSelector
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
        </div>
        <div>
          <h1 className="font-outfit text-3xl font-bold text-[#2B3674]">
            $37.5K
          </h1>
          <span className="">Total Income</span>
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <ResponsiveContainer width="100%" height={150}>
          <LineChart data={data}>
            <Line
              type="monotone"
              dataKey="uv"
              stroke="#8884d8"
              strokeWidth={3}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#FF9A40"
              strokeWidth={3}
              dot={false}
            />
            <XAxis
              dataKey="name"
              style={{fontSize: 10, color: "#A3AED0", textAlign: "center"}}
              color="#A3AED0"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default TotalIcome;
