import Meetings from "./meetings/meetings";
import Achievements from "./achievements/achievements";
import Courses from "./courses/courses";

export default function PersonalDevelopmentAccordion() {
  return (
    <div className="w-full py-2">
      <div className="mx-auto w-ful rounded-2xl gap-y-2 flex flex-col">
        <div>
          <Meetings />
        </div>
        <div>
          <Achievements />
        </div>
        <div>
          <Courses />
        </div>
      </div>
    </div>
  );
}
