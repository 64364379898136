 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={135}
    height={123}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#283252"
        d="M116.85 104.22H17.82V87.384h1.527V74.853a11.156 11.156 0 0 1 3.26-7.87 11.125 11.125 0 0 1 7.86-3.266h73.914a11.127 11.127 0 0 1 7.86 3.265 11.158 11.158 0 0 1 3.261 7.87v12.532h1.348v16.836Z"
      />
      <path
        fill="#283252"
        d="M119.533 122.75h-4.93v-.09a16.498 16.498 0 0 0-4.822-11.639 16.454 16.454 0 0 0-11.623-4.829H36.51a16.454 16.454 0 0 0-11.623 4.829 16.497 16.497 0 0 0-4.822 11.639v.09h-4.93a4.248 4.248 0 0 1-4.236-4.242v-27.52c-1.432-.81-2.596-5.21-1.84-8.722.475-2.205 1.845-4.867 5.677-5.032 3.73-.16 6.5 3.346 6.746 6.722a7.289 7.289 0 0 1-1.464 4.958h.047v3.926c8.089-2.596 16.19-3.916 24.079-3.926a73.605 73.605 0 0 1 23.73 3.955c15.884-5.145 31.605-5.245 46.728-.296v-3.659h.047a7.286 7.286 0 0 1-1.465-4.958c.272-3.703 3.296-6.718 6.743-6.722h.011c4.065 0 5.502 2.45 5.993 4.507.867 3.64-.726 8.43-2.163 9.246v27.521a4.248 4.248 0 0 1-4.236 4.242Z"
      />
      <path
        fill="#FFB6B6"
        d="m91.99 115.385 2.38 1.703 7.689-8.382-3.512-2.512-6.556 9.191Z"
      />
      <path
        fill="#2F2E41"
        d="m91.939 114.174.67.479 3.374.806.642 2.067a3.677 3.677 0 0 1 .853 5.127l-.07.097-7.672-5.488 2.203-3.088ZM108.115 92.947H90.643c-1.63-.001-3.192-.65-4.344-1.804a6.159 6.159 0 0 1 0-8.7 6.141 6.141 0 0 1 4.344-1.804h17.472a6.14 6.14 0 0 1 4.346 1.802 6.156 6.156 0 0 1 0 8.703 6.141 6.141 0 0 1-4.346 1.803Z"
      />
      <path
        fill="#61A9FF"
        d="m99.543 49.975 2.818 2.149 3.399 1.046s1.405 19.792 2.671 21.06c1.267 1.268.125 1.302.379 2.949.254 1.647.873 5.023.873 5.023-8.546 4.53-15.914 4.652-21.832-.65a1.478 1.478 0 0 1 .804-1.665c1.102-.586-1.015-3.317-.115-3.774.9-.457-.366-4.419-.366-4.419l.83-8.266-.656-1.322 1.602-8.085 2.778-1.016 1.11-2.678 5.705-.352Z"
      />
      <path
        fill="#FFB6B6"
        d="M101.309 31.496a2.452 2.452 0 0 0 .807 1.906 2.45 2.45 0 0 0 1.978.603l3.972 7.73 2.213-3.936-4.111-6.738a2.459 2.459 0 0 0-2.623-1.944 2.458 2.458 0 0 0-2.236 2.38Z"
      />
      <path
        fill="#61A9FF"
        d="m107.998 33.387 8.519 13.222-.028.06c-.033.07-1.962 4.242-3.831 8.064a219.795 219.795 0 0 1-1.207 2.429c-.785 1.55-1.471 2.83-1.88 3.44a7.214 7.214 0 0 1-3.821 2.902l-.04.01-.037-.016a3.66 3.66 0 0 1-2.178-4.03 25.966 25.966 0 0 1 4.81-10.89l1.381-1.823-4.8-10.45 3.112-2.918Z"
      />
      <path
        fill="#FFB6B6"
        d="M93.279 29.876a2.448 2.448 0 0 1-2.785 2.509l-3.972 7.73-2.213-3.937 4.111-6.737a2.46 2.46 0 0 1 2.623-1.944 2.456 2.456 0 0 1 2.236 2.379Z"
      />
      <path
        fill="#61A9FF"
        d="m86.59 31.767-8.519 13.222.028.06c.032.07 1.961 4.242 3.83 8.064a223.258 223.258 0 0 0 1.207 2.428c.785 1.55 1.471 2.832 1.88 3.44a7.212 7.212 0 0 0 3.822 2.903l.04.01.037-.016a3.661 3.661 0 0 0 2.178-4.03 25.974 25.974 0 0 0-4.81-10.89l-1.381-1.823 4.8-10.45-3.112-2.918Z"
      />
      <path
        fill="#FFB6B6"
        d="M96.658 48.778c3.079 0 5.575-2.5 5.575-5.582a5.579 5.579 0 0 0-5.575-5.583 5.579 5.579 0 0 0-5.575 5.583 5.579 5.579 0 0 0 5.575 5.582Z"
      />
      <path
        fill="#2F2E41"
        d="M92.117 38.4c.459-1.335 2.741-2.262 6.44-2.39 3.7-.13 4.639 4.237 4.639 4.237 2.47 2.006-2.638 7.378-2.885 7.184l.111-1.009c.184-1.671.966-2.976-.028-4.718l-.955.011-1.81.349a.523.523 0 0 1-.445-.909l.016-.015-.04-.063c-.639.293-1.296.564-1.993.605-.785.046-1.642-.266-2.018-.957-.1-.19-.162-.398-.18-.613-1.058.424-1.542 1.73-1.542 1.73s.232-2.106.69-3.442Z"
      />
      <path
        fill="#FFB6B6"
        d="m107.173 113.011-2.232 1.892-8.35-7.719 3.293-2.793 7.289 8.62Z"
      />
      <path
        fill="#2F2E41"
        d="m109.574 114.695-7.196 6.102-.077-.091a3.682 3.682 0 0 1 .429-5.18l.47-2.114 3.297-1.081.628-.532 2.449 2.896ZM93.38 91.666l1.845-4.006c-.02-3.654-3.075-6.622-6.716-6.393-2.028.128-3.928 1.264-4.712 4.64-2.018 8.681 17.779 26.747 17.779 26.747l3.638-2.251.167-2.378-2.037-1.258-.002-2.028-1.942-.923-8.02-12.15Z"
      />
      <path
        fill="#2F2E41"
        d="m105.5 90.563-2.281-3.358c-.279-3.643 2.521-6.852 6.17-6.924 2.031-.04 4.017.936 5.076 4.236 2.724 8.486-15.522 28.121-15.522 28.121l-3.81-1.944-.363-2.357 1.927-1.42-.165-2.022 1.86-1.079 7.108-13.253Z"
      />
      <path
        fill="#fff"
        d="M97.474 63.069v1.072h-3.551V63.07h1.1v-2.585l-1.028.59v-1.158l1.581-.9h.79v4.053h1.108ZM98.276 63.53c-.338-.451-.507-1.104-.507-1.958 0-.84.169-1.484.507-1.933a1.913 1.913 0 0 1 2.911 0c.338.449.507 1.093.507 1.933 0 .854-.169 1.507-.507 1.958a1.91 1.91 0 0 1-1.456.677 1.904 1.904 0 0 1-1.455-.677Zm1.951-.716c.122-.402.173-.822.151-1.242a3.528 3.528 0 0 0-.151-1.217.514.514 0 0 0-.806-.27.517.517 0 0 0-.186.27 3.53 3.53 0 0 0-.15 1.217c-.022.42.029.84.15 1.242a.516.516 0 0 0 .992 0Z"
      />
      <path
        fill="#A0616A"
        d="m59.6 119.902-2.727-.001-1.297-10.533H59.6v10.534Z"
      />
      <path
        fill="#2F2E41"
        d="m59.696 122.75-8.386-.001v-.106a3.27 3.27 0 0 1 3.264-3.268l1.532-1.164 2.858 1.164h.733v3.375Z"
      />
      <path
        fill="#A0616A"
        d="m78.896 119.902 2.727-.001 1.297-10.533h-4.025v10.534Z"
      />
      <path
        fill="#2F2E41"
        d="m78.8 122.75 8.386-.001v-.106a3.27 3.27 0 0 0-3.264-3.268l-1.532-1.164-2.858 1.164H78.8v3.375ZM55.456 101.628c.04-.601.36-3.925.36-3.925l.767-7.05 1.352-18.685.088-1.224 7.082-1.36 4.84-2.324 5.753 1.841 4.48 1.682s.007.72.041 1.59c.046 1.139.127 2.535.319 2.898.32.641.24 4.084.24 4.084s.318 7.005.718 7.773c.401.768.482 2.368.361 2.689-.12.32.4 8.733.4 8.733s1.34 14.887.5 17.132c-.84 2.244-4.014.774-4.014.774s.153-.599-.767-.881c-.92-.283-3.724-21.392-3.724-21.392s-.522-4.328-1.001-5.569c-.48-1.24-.92-4.725-.92-4.725s-2.961-5.769-3-7.051c0 0-.387-1.2-.753.12-.366 1.322-1.446 4.486-1.446 4.486l-1.053 3.887-1.238 5.807-.802 4.889s-1.2 6.488-1.392 6.768c-.192.281 0 6.209 0 6.209s-.928 5.048-2.24 5.929c-1.311.882-4.786.014-4.786.014s-.205-12.518-.165-13.119Z"
      />
      <path
        fill="#2F2E41"
        d="m58.477 51.252 1.574 4.128-1.942 2.013.855.767-.63 1.083-1.646 11.354 9.02 2.978 3.038-3.315 2.395 3.315 10.437-2.22-2.662-14.479v-.947l-.751-1.827v-3.87l3.451-11.517-7.39-1.807-2.832-1.951-.917 2.744-3.902-.604-.102-2.062-2.985 2.456-3.981.624-3.01.6-.384.834.767-.167 1.342 10.859.255 1.011Z"
      />
      <path
        fill="#3F3D56"
        d="m64.547 40.107 1.926-5.06 4.92-.09 1.634 5.15 5.868 32.83s-17.979 5.013-17.797-1.013c.192-6.337 1.057-14.755 1.057-14.755l2.392-17.062Z"
      />
      <path
        fill="#A0616A"
        d="M68.824 33.631a5.8 5.8 0 0 0 5.796-5.804 5.8 5.8 0 0 0-5.796-5.805 5.8 5.8 0 0 0-5.797 5.805 5.8 5.8 0 0 0 5.797 5.804Z"
      />
      <path
        fill="#2F2E41"
        d="M62.685 23.616c.944-1.028 2.124-2.91 5.7-3.701 4.417-.977 6.505 3.019 5.39 3.084 3.258.988 1.165 7.739.232 7.173a4.592 4.592 0 0 1-1.856-3.153l-.178-1.598c-.025 1.3-1.352 1.472-2.707.993a1.702 1.702 0 0 1 1.201-1.184c-.829.765-1.908 1.313-3.415 1.488.185-.717.553-1.245 1.13-1.558l-.053-.053c-.552.421-1.128.826-1.792 1.016-.747.215-1.642.099-2.154-.487a1.594 1.594 0 0 1-.306-.552c.11 1.03-.579 2.205-1.115 2.003-.445-.087-.705-2.786-.077-3.47Z"
      />
      <path
        fill="#A0616A"
        d="m75.97 27.507 5.272 8.881 3.896-1.843s-6.714-8.397-6.928-8.639a2.068 2.068 0 0 0-2.047-2.518 2.06 2.06 0 0 0-1.904 1.36 2.069 2.069 0 0 0 1.711 2.759Z"
      />
      <path
        fill="#2F2E41"
        d="m78.523 49.578 8.18-5.902a4.732 4.732 0 0 0 1.934-3.3 4.742 4.742 0 0 0-1.133-3.654l-5.888-6.763-2.565 3.48 1.977 4.14-3.828 1.01 1.323 10.989Z"
      />
      <path
        fill="#A0616A"
        d="m64.48 28.342-5.27 8.881-3.897-1.843s6.714-8.397 6.928-8.639a2.069 2.069 0 0 1 .871-2.174 2.062 2.062 0 0 1 3.138 2.242 2.066 2.066 0 0 1-1.77 1.533Z"
      />
      <path
        fill="#2F2E41"
        d="m61.928 50.413-8.18-5.902a4.733 4.733 0 0 1-1.753-5.247c.196-.629.52-1.21.952-1.707l5.888-6.763 2.565 3.48-1.977 4.14 3.828 1.01-1.323 10.989Z"
      />
      <path
        fill="#fff"
        d="M70.206 48.76c.184.218.282.498.273.784.006.284-.085.56-.26.785a1.624 1.624 0 0 1-.714.507 3.19 3.19 0 0 1-2.092 0c-.28-.1-.528-.276-.716-.507a1.236 1.236 0 0 1-.259-.785c-.009-.29.088-.573.274-.795.203-.226.472-.381.769-.443a1.433 1.433 0 0 1-.669-.443 1.074 1.074 0 0 1-.244-.702c-.005-.268.082-.53.248-.741a1.57 1.57 0 0 1 .676-.486 2.848 2.848 0 0 1 1.933 0c.265.098.499.266.676.486.166.211.253.473.248.741.005.26-.083.515-.248.717-.17.207-.4.359-.657.435.293.067.56.223.762.446Zm-1.187 1.094a.471.471 0 0 0 .216-.418.526.526 0 0 0-.208-.432.815.815 0 0 0-1.136 0 .524.524 0 0 0-.209.432.473.473 0 0 0 .216.418 1.164 1.164 0 0 0 1.121 0Zm-1.017-2.941a.477.477 0 0 0-.176.407c0 .316.21.528.633.633.421-.105.632-.317.632-.633a.478.478 0 0 0-.176-.407.849.849 0 0 0-.913 0Z"
      />
      <path
        fill="#9E616A"
        d="M5.406 31.686a1.536 1.536 0 0 0 2.341.24l4.873 2.452-.332-2.818-4.576-1.896a1.542 1.542 0 0 0-2.564.955c-.047.375.045.755.258 1.067Z"
      />
      <path
        fill="#6C63FF"
        d="m25.567 43.061-2.427-1.676s-2.779-.188-3.196-1.073c-.418-.885-.489-.587-.934-.693-.446-.106-.676-1.437-1.422-1.28-1.68.353-9.582-4.875-8.73-5.529l.635-2.676c.67.25 1.368.416 2.079.494 1.074.098 3.606 1.327 3.981 1.73.375.404 1.04.247 1.93.459.892.212 1.204 1.647 2.681 1.265 2.08-.538 12.852 4.579 14.017 5.056 1.279.525 2.172 2.087 2.172 2.087l-.352 1.487-10.434.349Z"
      />
      <path
        fill="#9E616A"
        d="M43.078 119.081h2.114l1.007-8.168h-3.122v8.168Z"
      />
      <path
        fill="#2F2E41"
        d="m42.387 117.631 3.379-.202v1.449l3.212 2.222a.903.903 0 0 1 .35 1.014.901.901 0 0 1-.864.636h-4.022l-.693-1.434-.271 1.434H41.96l.426-5.119Z"
      />
      <path
        fill="#9E616A"
        d="m16.573 117.282 1.965.783 3.951-7.216-2.9-1.156-3.016 7.589Z"
      />
      <path
        fill="#2F2E41"
        d="m16.467 115.679 3.214 1.063-.535 1.347 2.163 3.254a.904.904 0 0 1-1.086 1.343l-3.738-1.49-.114-1.589-.781 1.232-1.41-.562 2.287-4.598ZM38.815 94.776l-3.526-16.48-5.507 18.31-8.72 17.363-.046.092-2.761-1.489c-.39-7.05.536-13.656 3.922-19.433l1.603-15.856c.049-.542 1.245-13.26 5.032-15.517l1.11-2.532 11.03-2.728.04.045a8.45 8.45 0 0 1 2.02 4.242l3.297 19.995.002.012-.884 13.95 1.21 20.579h-4.375c-2.706-5.099-3.855-11.95-3.447-20.553Z"
      />
      <path
        fill="#9E616A"
        d="M48.31 25.662a1.537 1.537 0 0 1-.062 2.356l1.802 5.155-2.749-.692-1.289-4.79a1.547 1.547 0 0 1 1.276-2.422c.377.001.741.141 1.023.393Z"
      />
      <path
        fill="#6C63FF"
        d="M26.67 64.6c-.297 0-.466-.06-.531-.184-.096-.184.07-.424.263-.702.134-.193.526-.578.333-.602-2.926-.358-1.521-18.424-.253-20.812.258-.485-.015-1.23.275-2.053.793-2.249 1.875-2.94 4.03-4.317l.547-.35c.245-.158.231-.68.217-1.234-.016-.615-.033-1.252.268-1.65l.02-.025.031-.008c.728-.168 3.177-.481 4.627.003l.02.006.015.015c.345.345.376 1.013.406 1.66.025.53.049 1.03.244 1.248 1.49 1.664 3.18 1.631 3.197 1.63l.083-.002.009.084c1.044 1.99.226 9.385.953 13.979.737 4.666 7.037 8.639 1.643 9.344a.118.118 0 0 1 .003.121c-.326.61-15.28 3.848-16.4 3.848Z"
      />
      <path
        fill="#6C63FF"
        d="m51.056 41.844-2.994 1.487c-5.77 2.22-8.785 1.679-10.3.835-1.616-.9-1.896-2.28-1.907-2.338l-.001-.029.37-2.93a2.65 2.65 0 0 1 2.163-2.274 2.64 2.64 0 0 1 1.11.041l6.888 1.756-.125-3.349.272-1.205-.502-1.466.206-2.943 2.47-.606 2.592 4.65-.465 1.282.438.639v.03l-.215 6.42Z"
      />
      <path
        fill="#fff"
        d="M36.036 44.192V45.2l-2.23 4.118H32.37l2.221-4.053h-2.358v-1.073h3.804Z"
      />
      <path
        fill="#CACACA"
        d="M134.786 122.944.214 123a.218.218 0 0 1-.15-.063.215.215 0 0 1 .15-.366l134.572-.055a.213.213 0 0 1 .213.214.213.213 0 0 1-.213.214Z"
      />
      <path
        fill="#CCC"
        d="M93.007 19.073c1.655-3.524.316-7.645-2.077-8.771-2.392-1.127-6.416.467-8.071 3.992-1.532 3.262-.39 7.084 1.574 8.476a.268.268 0 0 0-.091.047l-.472.39a.273.273 0 0 0-.045.375.273.273 0 0 0 .108.086l1.19.53a.273.273 0 0 0 .385-.263l-.028-.611a.267.267 0 0 0-.012-.063c2.326.64 6.003-.918 7.539-4.188Z"
      />
      <path
        fill="#fff"
        d="M91.65 14.018a4.512 4.512 0 0 1-2.57 5.647c-.243.102-.049.464.193.363a4.934 4.934 0 0 0 2.778-6.1c-.082-.248-.484-.16-.4.09Z"
      />
      <path
        fill="#6C63FF"
        d="M122.052 30.884c3.35-1.98 4.559-6.14 3.215-8.42-1.343-2.28-5.565-3.23-8.915-1.25-3.101 1.832-4.306 5.634-3.464 7.892a.273.273 0 0 0-.102-.013l-.609.056a.271.271 0 0 0-.22.15.278.278 0 0 0 .012.266l.687 1.11a.275.275 0 0 0 .366.095.282.282 0 0 0 .099-.095l.321-.522a.25.25 0 0 0 .025-.058c1.563 1.84 5.478 2.626 8.585.789Z"
      />
      <path
        fill="#fff"
        d="M123.773 25.941a4.504 4.504 0 0 1-2.008 2.668 4.494 4.494 0 0 1-3.291.55c-.258-.053-.301.356-.044.409a4.912 4.912 0 0 0 3.543-.613 4.929 4.929 0 0 0 2.182-2.862c.071-.253-.31-.406-.382-.152Z"
      />
      <path
        fill="#CCC"
        d="M100.73 14.694a6.475 6.475 0 0 1 .266-5.87c.144-.25.287-.553.602-.6a.535.535 0 0 1 .588.315.706.706 0 0 1-.312.793.7.7 0 0 1-.847-.093c-.597-.482-.522-1.331-.464-2.014.131-1.557.129-3.191-.532-4.64-.612-1.34-1.862-2.462-3.37-2.584-.264-.022-.279.39-.016.41 1.496.122 2.667 1.356 3.164 2.707a7.53 7.53 0 0 1 .413 2.53c.005.441-.015.883-.05 1.323a7.36 7.36 0 0 0-.04 1.294c.071.705.496 1.46 1.257 1.572.709.105 1.402-.638 1.21-1.344a.964.964 0 0 0-1.541-.458c-.267.223-.44.605-.592.911a6.884 6.884 0 0 0-.098 5.942c.113.239.474.045.362-.194Z"
      />
      <path
        fill="#3F3D56"
        d="M38.792 33.846c-.154-.282-.42-1.09-.719-1.08a3.553 3.553 0 0 1-1.005-.097 16.956 16.956 0 0 0-3.851-.503c-1.048-.015-2.198.111-2.93.862-.731.75-.623 2.298.385 2.584a12.986 12.986 0 0 1-9.64-2.14c-1.785-1.256-3.216-2.939-4.73-4.512-1.513-1.575-3.18-3.088-5.22-3.859-2.042-.772-4.539-.664-6.183.774-2.006 1.753-2.16 4.77-2.162 7.435-.002.997.309.787 1.188 1.256.816.436 1.673 1.332 2.17.553.499-.78.587-1.742.69-2.662.1-.92.249-1.888.831-2.606 1.001-1.235 2.973-1.247 4.355-.464 1.382.782 2.311 2.152 3.19 3.477.88 1.323 1.807 2.696 3.187 3.484 1.813 1.035 4.09.882 5.983 1.764 1.203.561 2.175 1.503 3.172 2.376 1 .873 2.094 1.713 3.39 1.996 1.294.284 2.83-.144 3.467-1.309.573-1.053.293-2.4.803-3.488.394-.838 1.197-1.395 1.922-1.974.298-.24.375.585.629.293.731-.146 1.418-1.539 1.078-2.16Z"
      />
      <path
        fill="#2F2E41"
        d="M33.753 32.357a5.683 5.683 0 0 0 5.679-5.687 5.683 5.683 0 0 0-5.68-5.687 5.683 5.683 0 0 0-5.679 5.687 5.683 5.683 0 0 0 5.68 5.687Z"
      />
      <path
        fill="#9E616A"
        d="M34.21 32.827a4.085 4.085 0 0 0 3.964-4.2 4.084 4.084 0 0 0-4.195-3.97 4.085 4.085 0 0 0-3.964 4.2 4.084 4.084 0 0 0 4.195 3.97Z"
      />
      <path
        fill="#2F2E41"
        d="m34.134 22.51-1.655.046a3.936 3.936 0 0 0-2.747 1.233 3.947 3.947 0 0 0-1.075 2.816l.001.037.03.019c.9.595 1.961.898 3.039.865l.093-.002.914-.042c.154-.396.205-.825.15-1.247.138.4.245.81.322 1.225l4.102-.187a.915.915 0 0 0 .87-.938 3.946 3.946 0 0 0-1.232-2.75 3.935 3.935 0 0 0-2.812-1.076ZM30.837 21.484c-.374.46-.973.653-1.512.903a6.467 6.467 0 0 0-3.62 4.834c-.127.847-.083 1.718-.298 2.546-.213.828-.777 1.65-1.614 1.817-.567.114-1.144-.09-1.685-.292l-.894-.332.855-2.342a11.735 11.735 0 0 1-1.65 2.048l-3.206-1.189c2.602-.064 2.086-2.58 2.597-5.134.28-1.403 2.977-2.425 3.474-3.766a4.059 4.059 0 0 1 3.485-2.642 4.049 4.049 0 0 1 3.855 2.063c.54.198.58 1.03.213 1.486Z"
      />
      <path
        fill="#2F2E41"
        d="M31.097 23.175a2.116 2.116 0 0 0 2.115-2.118c0-1.17-.947-2.117-2.115-2.117a2.116 2.116 0 0 0-2.115 2.117c0 1.17.947 2.118 2.115 2.118Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h135v123H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
