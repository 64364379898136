import PersonalDevelopmentAccordion from "@components/accordion/personalDevelopment";

function PersonalDevelopment() {
  return (
    <div>
      <div className=" w-full  rounded-lg flex flex-col gap-6 ">
        <div className="bg-custom_faded_blue py-4 px-3">
          <PersonalDevelopmentAccordion />
        </div>
      </div>
    </div>
  );
}

export default PersonalDevelopment;
