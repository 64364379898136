import Button from "@components/button";
import {Disclosure, Transition} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
 


function Contact() {
  return (
    <Disclosure>
      {({open}) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-6 text-left text-sm font-medium text-black  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
            <span>Contract</span>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-6 w-6 text-black-500`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition ease-out duration-300 transform"
            enterFrom="opacity-0 translate-y-[-10%]"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-[-10%]"
          >
            <Disclosure.Panel className="px-4 pb-2 bg-white pt-4 rounded-es-lg rounded-ee-lg  text-sm text-gray-500">
              <div className="flex gap-3 flex-col">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-xs font-medium leading-6 text-custom_gray"
                  >
                    Address:
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="eg. john.doe@remoteli.co.uk"
                      required
                      value={"86-90 Paul Street, London, England, EC2A 4NE"}
                      className="block bg-transparent text-black w-full rounded-lg border-gray border py-3 px-4 shadow-sm focus:ring-0 placeholder:text-gray-400 sm:text-base sm:leading-6 "
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-xs font-medium leading-6 text-custom_gray"
                  >
                    Post code:
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="eg. john.doe@remoteli.co.uk"
                      required
                      value={"030"}
                      className="block bg-transparent text-black w-full rounded-lg border-gray border py-3 px-4 shadow-sm focus:ring-0 placeholder:text-gray-400 sm:text-base sm:leading-6 "
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-xs font-medium leading-6 text-custom_gray"
                  >
                    Email:
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="eg. john.doe@remoteli.co.uk"
                      required
                      value={"immaculate@gmail.com"}
                      className="block bg-transparent text-black w-full rounded-lg border-gray border py-3 px-4 shadow-sm focus:ring-0 placeholder:text-gray-400 sm:text-base sm:leading-6 "
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-xs font-medium leading-6 text-custom_gray"
                  >
                    Phone number:
                  </label>
                  <div className="mt-1">
                    <input
                      id="phone"
                      name="phone"
                      type="phone-number"
                      autoComplete="email"
                      placeholder="eg. john.doe@remoteli.co.uk"
                      required
                      value={"0550118711"}
                      className="block bg-transparent text-black w-full rounded-lg border-gray border py-3 px-4 shadow-sm focus:ring-0 placeholder:text-gray-400 sm:text-base sm:leading-6 "
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end mt-8">
                <div>
                  <Button
                    title="Edit"
                    className="w-1/6 px-8"
                    onClick={() => null}
                  />
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

export default Contact;
