import CandidatesIcon from "@components/svgs/companiesbig";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import Input from "@components/input";
import {useNavigate} from "react-router-dom";
import Avartar from "@assets/candidate.jpg";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import PhonePicker from "@components/phonepicker";

function Page() {
  const navigate = useNavigate();
  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add a new lead</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="py-4 bg-custom_faded_blue rounded-2xl my-2 flex flex-col lg:flex-row px-4">
        <div className="flex flex-col items-center justify-center">
          <img
            src={Avartar}
            className=" h-auto w-24 rounded-full"
            alt="Picture of the Candidate"
          />

          <div className="flex gap-x-3 items-center mt-2">
            <LinkedIn />
            <Instagram />
            <FacebookIcon />
          </div>
        </div>

        <div className="lg:ms-12  w-full items-start mt-8 lg:mt-0">
          <div className="flex justify-between items-start">
            <div className="flex">
              <div>
                <h2 className="text-xl">ALWAYS 49 LIMITED</h2>
                <p className="font-light text-[#969EAE] text-sm my-1">
                  Online Publishing
                </p>

                <a
                  href="https://www.10pmcurfew.com/"
                  target="__blank"
                  className="underline text-custom_deep_blue text-sm"
                >
                  https://www.10pmcurfew.com/
                </a>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-4 grid-cols-1 mt-6 gap-y-4 lg:gap-y-0">
            <div className=" flex flex-col gapy-1">
              <p>Main contact</p>

              <span className="text-custom_gray text-sm">Mathias Boampong</span>
              <span className="text-custom_gray text-sm">+233 58678989</span>
            </div>

            <div className=" flex flex-col gapy-1">
              <p>Address</p>

              <span className="text-custom_gray text-sm">
                1120 Commerce Drive
              </span>
              <span className="text-custom_gray text-sm">
                Los Angeles, California
              </span>
            </div>

            <div className=" flex flex-col gapy-1">
              <p>Email address</p>

              <span className="text-custom_gray text-sm">
                partnerships@10pmcurfew.com
              </span>
            </div>

            <div className=" flex flex-col gapy-1">
              <p>Phone number</p>

              <span className="text-custom_gray text-sm">+44 7508 397545</span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full grid lg:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-2 py-4 px-6 rounded-lg  bg-custom_faded_blue">
        <div className="">
          <label className="text-xs text-[#82868C]">Name</label>
          <Input placeholder="Eg. Jane doe" className="mt-1" />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Email:</label>
          <Input placeholder="Eg. mathboam@gmaail.com" className="mt-1" />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Phone:</label>
          <PhonePicker />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Post code:</label>
          <Input placeholder="Eg. 12345" className="mt-1" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Address:</label>
          <Input placeholder="Eg. 123 Caliber street" className="mt-1" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Country</label>
          <Input placeholder="Eg. United Kingdom" className="mt-1" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Address line 1</label>
          <Input placeholder="Eg. London street" className="mt-1" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Instagram</label>
          <Input placeholder="Eg. www.****.com" className="mt-1" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Address line 2</label>
          <Input placeholder="Eg. West street" className="mt-1" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Facebook</label>
          <Input placeholder="Eg. www.****.com" className="mt-1" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">LinkedIn</label>
          <Input placeholder="Eg. www.****.com" className="mt-1" />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Twitter</label>
          <Input placeholder="Eg. www.****.com" className="mt-1" />
        </div>

        <div className="col-span-2 flex justify-end mt-3">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-light text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => null}
          >
            Add a new lead
          </button>
        </div>
      </div>
    </div>
  );
}

export default Page;
