import CandidatesStats from "@components/stats/candidatespoolstats";
import CandidatesIcon from "@components/svgs/candidatepool";
import { Outlet } from "react-router-dom";
function layout() {
  return (
    <div>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <CandidatesStats />
          </div>
          <CandidatesIcon />
        </div>
      </div>
      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-3">
        <Outlet />
      </div>
    </div>
  );
}

export default layout;
