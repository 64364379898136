import EmptyIcon from "./svgs/emptyicon";
function EmptyInterface({
  description,
  buttonText,
  buttonAction,
  showButton = true,
  icon,
}: {
  description: string;
  buttonText?: string;
  buttonAction?: () => void;
  showButton?: boolean;
  icon?: string | JSX.Element;
}) {
  return (
    <div className="min-h-96 bg-transparent flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <img src={(icon as any) || EmptyIcon} alt="" />

        <p className="mt-2 text-sm leading-6 text-center text-custom_gray">
          {description}
        </p>
        {showButton && (
          <button
            onClick={buttonAction}
            className="mt-8 flex items-center gap-3 bg-custom_deep_blue rounded-xl text-sm px-8 font-outfit py-4 text-white"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                fill="white"
              />
            </svg>
            <span>{buttonText}</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default EmptyInterface;
