import {Fragment, useState} from "react";
import {countries} from "./countries.json";
import {Menu, Transition} from "@headlessui/react";

function PhonePicker() {
  const [selectedCode, setSelectedCode] = useState(countries[0]);
  return (
    <div className="flex items-center">
      <Menu as="div" className="relative text-left">
        <div>
          <Menu.Button className="inline-flex justify-between py-3.5 w-full rounded-lg rounded-se-none rounded-ee-none border-r-0 bg-transparent border border-[#657A9345]  px-3 text-xs  focus:outline-none  focus-visible:ring-white/75 items-center">
            <div className="flex gap-x-1 items-center">
              <img
                className="w-5 h-5 rounded-full"
                src={selectedCode?.flag_image_url}
              />
              <span className="">{selectedCode?.phone_code || "Select"}</span>
            </div>
            <svg
              className="ml-6"
              width="14"
              height="6"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.497 0.489027L8.99999 7.79703L1.50299 0.489027C1.36905 0.358193 1.18923 0.284947 1.00199 0.284947C0.814751 0.284947 0.634939 0.358193 0.500992 0.489027C0.436135 0.552573 0.384611 0.628421 0.349436 0.712131C0.314261 0.79584 0.296143 0.885727 0.296143 0.976526C0.296143 1.06733 0.314261 1.15721 0.349436 1.24092C0.384611 1.32463 0.436135 1.40048 0.500992 1.46403L8.47649 9.24003C8.61655 9.37655 8.8044 9.45295 8.99999 9.45295C9.19558 9.45295 9.38343 9.37655 9.52349 9.24003L17.499 1.46553C17.5643 1.40193 17.6162 1.3259 17.6517 1.24191C17.6871 1.15792 17.7054 1.06769 17.7054 0.976526C17.7054 0.885366 17.6871 0.795128 17.6517 0.711142C17.6162 0.627155 17.5643 0.55112 17.499 0.487526C17.365 0.356693 17.1852 0.283447 16.998 0.283447C16.8108 0.283447 16.6309 0.356693 16.497 0.487526V0.489027Z"
                fill="#969EAE"
              />
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-20 max-h-60 overflow-scroll right-0 mt-1 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            {countries?.length === 0 && (
              <div className="p-2 text-sm text-center text-gray-500">
                No Countries found
              </div>
            )}
            {countries?.map((user: any) => (
              <div className="px-1 py-1" key={user?.id}>
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => setSelectedCode(user)}
                      className={` group gap-2 flex items-center rounded-md px-2 py-2 text-xs`}
                    >
                      <img
                        className="w-5 h-5 rounded-full"
                        src={user?.flag_image_url}
                      />
                      {user?.phone_code}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      <input
        autoComplete="on"
        type="number"
        className={`border border-[#657A9345] w-full py-3 px-4 rounded-lg border-l-0 rounded-ss-none rounded-es-none bg-transparent`}
      />
    </div>
  );
}

export default PhonePicker;
