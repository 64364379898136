import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeOpacity={0.7}
      strokeWidth={1.5}
      d="M7 19h6m-6 0v-5m0 5H1.6a.6.6 0 0 1-.6-.6v-3.8a.6.6 0 0 1 .6-.6H7m6 5V7m0 12h5.4a.6.6 0 0 0 .6-.6V1.6a.6.6 0 0 0-.6-.6h-4.8a.6.6 0 0 0-.6.6V7m-6 7V7.6a.6.6 0 0 1 .6-.6H13"
    />
  </svg>
);
export default SvgComponent;
