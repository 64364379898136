import CandidatesIcon from "@components/svgs/companiesbig";
import Avartar from "@assets/candidate.jpg";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import EditIcon from "@components/svgs/edit";
import {Suspense, lazy, useState} from "react";
import {TopLoader} from "@components/loaders/toploader";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import Tabs from "@components/tabs";

const EditProfile = lazy(() => import("./components/edit/editprofile"));

function Page() {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const {id} = useParams();

  const tabs = [
    {
      name: "Current positions",
      href: `/sales/companies/view/${id}`,
    },
    {
      name: "Leads",
      href: `/sales/companies/view/${id}/leads`,
    },
    {
      name: "Contacts",
      href: `/sales/companies/view/${id}/contacts`,
    },
    {
      name: "Notes",
      href: `/sales/companies/view/${id}/notes`,
    },
    {
      name: "Tasks",
      href: `/sales/companies/view/${id}/tasks`,
    },
  ];
  return (
    <div>
      <div className="bg-[#DDEFFF33]  rounded-2xl font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">ALWAYS 49 LIMITED</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="py-4 bg-custom_faded_blue rounded-2xl my-2 flex flex-col lg:flex-row px-4">
        <div className="flex flex-col items-center justify-center">
          <img
            src={Avartar}
            className=" h-auto w-24 rounded-full"
            alt="Picture of the Candidate"
          />

          <div className="flex gap-x-3 items-center mt-2">
            <LinkedIn />
            <Instagram />
            <FacebookIcon />
          </div>
        </div>

        <div className="lg:ms-12  w-full items-start mt-8 lg:mt-0">
          <div className="flex justify-between items-start">
            <div className="flex">
              <div>
                <h2 className="text-xl">ALWAYS 49 LIMITED</h2>
                <p className="font-light text-[#969EAE] text-sm my-1">
                  Online Publishing
                </p>

                <a
                  href="https://www.10pmcurfew.com/"
                  target="__blank"
                  className="underline text-custom_deep_blue text-sm"
                >
                  https://www.10pmcurfew.com/
                </a>
              </div>
            </div>
            <button
              onClick={() => setShow(true)}
              className="bg-transparent focus:outline-none"
            >
              <EditIcon />
            </button>
          </div>
          <div className="grid lg:grid-cols-3 grid-cols-1 mt-6 gap-y-4 lg:gap-y-0">
            <div className=" flex flex-col gapy-1">
              <p>Address</p>

              <span className="text-custom_gray text-sm">
                1120 Commerce Drive
              </span>
              <span className="text-custom_gray text-sm">
                Los Angeles, California
              </span>
            </div>

            <div className=" flex flex-col gapy-1">
              <p>Email address</p>

              <span className="text-custom_gray text-sm">
                partnerships@10pmcurfew.com
              </span>
            </div>

            <div className=" flex flex-col gapy-1">
              <p>Phone number</p>

              <span className="text-custom_gray text-sm">+44 7508 397545</span>
            </div>
          </div>
        </div>
      </div>

      <div className="py-4 bg-custom_faded_blue rounded-2xl my-2 px-4">
        <Tabs tabs={tabs} />

        <Outlet />
      </div>

      <Suspense fallback={<TopLoader />}>
        <EditProfile open={show} setOpen={setShow} />
      </Suspense>
    </div>
  );
}

export default Page;
