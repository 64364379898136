import Dzifa from "@assets/dzifa.png";
import Martaa from "@assets/martaa.png";
import BadgeDropdown from "@components/dropdowns/badgedropdown";
import {STATUSLIST} from "@components/utils";
const data = [
  {
    image: Dzifa,
    current: "Sena Can",
    new: "Eugene Kofi",
    status: "Pending",
    sendReminder: true,
  },
  {
    image: Martaa,
    current: "Maataa Sarfoaa",
    new: "Eugene Kofi",
    status: "Accepted",
    sendReminder: false,
  },
  {
    image: Martaa,
    current: "Kelvin Austin",
    new: "Linda Parker",
    status: "Pending",
    sendReminder: true,
  },
  {
    image: Dzifa,
    current: "Kwame Nkrumah",
    new: "Eliane Koffi",
    status: "Accepted",
    sendReminder: false,
  },
];

interface IHandingOvers {
  image: string;
  current: string;
  new: string;
  status: string;
  sendReminder: boolean;
}

function HandingOvers() {
  return (
    <div className="px-5 py-4">
      <h1 className="text-2xl font-outfit font-semibold">Handing overs</h1>
      <div className="flex flex-col gap-y-2 mt-6">
        {data?.map((item, index) => <ListCard key={index} item={item} />)}
      </div>
    </div>
  );
}

export const ListCard = ({item}: {item: IHandingOvers}) => {
  return (
    <div className="bg-white py-5 px-4 flex items-center rounded-2xl">
      <div className="flex items-center w-2/4">
        <img src={item.image} alt="avatar" className="h-10 w-10 rounded-full" />
        <p className="ms-5">
          <span className="text-custom_blue">{item?.current}</span> is handing
          over her tasks and responsibilities to{" "}
          <span className="text-custom_blue">{item?.new} </span>
        </p>
      </div>
      <div className="w-2/4 flex justify-between">
        <BadgeDropdown
          selected={STATUSLIST?.find((item) => item.name === "Pending")}
          setSelected={() => null}
        />
        {item.sendReminder ? (
          <button className="text-orange-500">Send a reminder</button>
        ) : null}
      </div>
    </div>
  );
};

export default HandingOvers;
