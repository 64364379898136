 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#61A9FF"
      d="M.548 17.938h16.9a.5.5 0 1 0 0-1H.548a.5.5 0 0 0 0 1ZM6.71 14.18a2.587 2.587 0 0 0 1.12-.65l9.54-9.54a1.75 1.75 0 0 0 0-2.47l-.94-.93a1.788 1.788 0 0 0-2.47 0l-9.54 9.53c-.309.31-.53.696-.64 1.12L3.04 14a.737.737 0 0 0 .19.72c.142.139.332.218.53.22l2.95-.76Zm.41-1.36a1.468 1.468 0 0 1-.67.39l-.97.26-1-1 .26-.97a1.52 1.52 0 0 1 .39-.67l.38-.37 1.99 1.99-.38.37Zm1.09-1.08L6.22 9.75l6.73-6.73 1.99 1.99-6.73 6.73Zm8.45-8.45L15.65 4.3l-1.99-1.99 1.01-1.02a.748.748 0 0 1 1.06 0l.93.94a.754.754 0 0 1 0 1.06Z"
    />
  </svg>
);
export default SvgComponent;
