import React, {Fragment} from "react";
import tasks from "./data.json";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import Badge from "@components/badge";
import moment from "moment";

function EmployeeLeaveList({handlShowReturn}: {handlShowReturn: () => void}) {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(2);

  const handleNext = () => {
    setStart(start + 2);
    setEnd(end + 2);
  };

  const handlePrev = () => {
    setStart(start - 2);
    setEnd(end - 2);
  };

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 sm:-mx-0">
        <table className="min-w-full divide-y divide-[#E6EDFF]">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                ID
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Notes
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Start date
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                End date
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-[#E6EDFF] bg-white">
            {tasks?.slice(start, end).map((task, index) => (
              <Fragment key={task?.id}>
                <tr
                  // onClick={() => navigate(`/dashboard/updatetask/${task?.id}`)}
                  key={task.id}
                  className="cursor-pointer hover:bg-gray-50"
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {task.id}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {task.user}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    Annual leave
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell">
                    {moment()?.format("DD/MM/YYYY")}
                  </td>
                  <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-900 lg:table-cell">
                    {moment()?.format("DD/MM/YYYY")}
                  </td>
                  <td
                    onClick={() => {
                      index % 2 ? null : handlShowReturn();
                    }}
                    className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-900 lg:table-cell"
                  >
                    <Badge
                      colors={{
                        bg: index % 2 ? "bg-green-100" : "bg-[#FFB8A1]",
                        text: index % 2 ? "text-green-500" : "text-[#E84A4A]",
                      }}
                      status={index % 2 ? "Yes" : "No"}
                      text={index % 2 ? "Yes" : "No"}
                    />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default EmployeeLeaveList;
