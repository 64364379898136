import { MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {toast} from "sonner";
import Avartar from "@assets/avartar.png";
import {Menu, Transition} from "@headlessui/react";
import {Fragment} from "react";
import NotificationIcon from "@components/svgs/notifications";
import {Outlet, useNavigate} from "react-router-dom";
import Timer from "@components/Timer";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface TopBarProps {
  setSidebarOpen: (value: boolean) => void;
}

function TopBar({setSidebarOpen}: Readonly<TopBarProps>) {
  const navigate = useNavigate();

  const userNavigation = [
    {
      name: "Account",
      href: "/account",
      action: () => {
        navigate("/account");
      },
    },
    {
      name: "Log out",
      href: "/",
      action: () => {
        toast?.success("You have been logged out", {
          description: "See you soon!",
        });

        navigate("/");
      },
    },
  ];
  return (
    <div className="lg:pl-60">
      <div className="sticky bg-custom_dark_blue top-0 z-40 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200  px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <NotificationIcon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div className="flex flex-1 gap-x-4 self-stretch items-center lg:gap-x-6 ">
          <div className="w-full flex ">
            <form
              className="relative h-full flex items-center flex-1"
              action="#"
              method="GET"
            >
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-white"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block bg-custom_light_gray rounded-lg w-full lg:w-2/5 h-3/5 border-0 py-4 pl-12 pr-0 text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search here..."
                type="search"
                name="search"
              />
              <Timer />
            </form>
          </div>

          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">View notifications</span>
              <NotificationIcon aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
              aria-hidden="true"
            />

            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                <img
                  src={Avartar}
                  alt="avatar"
                  className="h-8 w-8 rounded-full"
                />
                <span className="hidden lg:flex lg:items-center">
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({active}) => (
                        <button
                          onClick={item.action}
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900"
                          )}
                        >
                          {item.name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <main className="py-6">
        <div className=" sm:mx-6 lg:mx-6">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default TopBar;
