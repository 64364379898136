import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Suspense} from "react";
import {TopLoader} from "@components/loaders/toploader";
import {routes} from "./routes";
import {ProtectedRoutes} from "./protectedroute";
import AuthLayout from "@pages/auth/layout";
import Login from "@pages/auth/login/page";
import ForgotPassword from "@pages/auth/forgotpassword/page";
const RenderRoutes = ({route}: any) => {
  if (route?.layout) {
    return (
      <Route
        index={route?.index}
        key={route?.index}
        element={route?.layout}
        path={route?.path}
      >
        {route?.routes?.map((rout: any) => RenderRoutes({route: rout}))}
      </Route>
    );
  } else {
    return (
      <Route key={route?.index} element={<ProtectedRoutes />}>
        <Route
          element={route?.element}
          path={route?.path}
          index={route?.index}
        />
      </Route>
    );
  }
};

function index() {
  return (
    <Router>
      <Suspense fallback={<TopLoader />}>
        <Routes>
          <Route path="/login" element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/login/forgotpassword" element={<ForgotPassword />} />
          </Route>
          {routes?.map((route: any) => RenderRoutes({route}))}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default index;
