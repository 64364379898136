import DashboardLayout from "@pages/dashboard/layout";
import DashbordPage from "@pages/dashboard/dashboard/page";
import AddNewTask from "@pages/dashboard/dashboard/addtask/page";
import UpdateTask from "@pages/dashboard/dashboard/updatetask/[id]/page";
import Employee from "@pages/dashboard/people/employees/page";
import AddNewEmployee from "@pages/dashboard/people/employees/addemployee/page";
import UpdateEmployee from "@pages/dashboard/people/employees/updateemployee/[id]/page";
import Candidates from "@pages/dashboard/people/candidates/page";
import AddNewCandidate from "@pages/dashboard/people/candidates/addcandidate/page";
import CandidatePool from "@pages/dashboard/people/candidate_pool/page";
import CandidatePoolLayout from "@pages/dashboard/people/candidate_pool/layout";
import SingleCandidatePool from "@pages/dashboard/people/candidate_pool/[department]/page";
import Teams from "@pages/dashboard/people/teams/page";
import SingleTeam from "@pages/dashboard/people/teams/[team]/page";
import Companies from "@pages/dashboard/sales/companies/page";
import ViewCompany from "@pages/dashboard/sales/companies/view/[id]/page";
import AddCompany from "@pages/dashboard/sales/companies/add/page";
import Blogs from "@pages/dashboard/admin/blogs/page";
import Testimonials from "@pages/dashboard/admin/testimonials/page";
import AddTestimonial from "@pages/dashboard/admin/testimonials/addtestimonial/page";
import AddBlog from "@pages/dashboard/admin/blogs/addblog/page";
import Products from "@pages/dashboard/settings/products/page";
import AddProduct from "@pages/dashboard/settings/products/addproduct/page";
import EditProduct from "@pages/dashboard/settings/products/editproduct/page";
import Leave from "@pages/dashboard/people/leave/page";
import Leads from "@pages/dashboard/sales/leads/page";
import Partners from "@pages/dashboard/sales/partners/page";
import LeaveLayout from "@pages/dashboard/people/leave/layout";
import LeaveTypeLayout from "@pages/dashboard/people/leave/tabs/layout";
import Holidays from "@pages/dashboard/people/leave/tabs/holidays/page";
import Sickness from "@pages/dashboard/people/leave/tabs/sickness/page";
import Requests from "@pages/dashboard/people/leave/tabs/requests/page";
import Today from "@pages/dashboard/people/leave/tabs/today/page";
import AddHoliday from "@pages/dashboard/people/leave/tabs/holidays/addholiday/page";
import RequestSickness from "@pages/dashboard/people/leave/tabs/sickness/requestsickness/page";
import RequestTimeOff from "@pages/dashboard/people/leave/tabs/requests/requesttimeoff/page";
import Contacts from "@pages/dashboard/sales/companies/view/[id]/components/contacts/contacts";
import Tasks from "@pages/dashboard/sales/companies/view/[id]/components/tasks";
import ViewCompanyLead from "@pages/dashboard/sales/companies/view/[id]/components/leads/leads";
import ViewCompanyCurrentPosition from "@pages/dashboard/sales/companies/view/[id]/components/currentpositions/leads";
import ViewCompanyAddPosition from "@pages/dashboard/sales/companies/view/[id]/components/currentpositions/addposition/page";
import ViewCompanyAddLead from "@pages/dashboard/sales/companies/view/[id]/components/leads/addposition/page";
import ViewCompanyNotes from "@pages/dashboard/sales/companies/view/[id]/components/notes";
import CurrentPositions from "@pages/dashboard/area/current_positions/page";
import AddPosition from "@pages/dashboard/area/current_positions/addposition/page";
import CurrentPosition from "@pages/dashboard/area/current_positions/view/currentposition/page";
import ViewCurrentPosition from "@pages/dashboard/area/current_positions/view/page";
import EditPosition from "@pages/dashboard/area/current_positions/view/editposition/page";
import InternalProjects from "@pages/dashboard/area/internal_projects/page";
import Projects from "@pages/dashboard/area/internal_projects/tabs/projects";
import AddProject from "@pages/dashboard/area/internal_projects/addposition/page";
import CompletedProjects from "@pages/dashboard/area/internal_projects/tabs/completed";
import TasksPage from "@pages/dashboard/area/tasks/page";
import TaskList from "@pages/dashboard/area/tasks/tabs/tasks";
import AddTask from "@pages/dashboard/area/tasks/addtask/page";
import AssignedTask from "@pages/dashboard/area/tasks/tabs/assigned";
import Notes from "@pages/dashboard/reports/notes/page";
import ViewEmployee from "@pages/dashboard/people/employees/viewemployee/[id]/layout";
import EmployeeProfile from "@pages/dashboard/people/employees/viewemployee/[id]/page";
import EmployeeTimeReports from "@pages/dashboard/people/employees/viewemployee/[id]/timereports/page";
import EmployeeHolidays from "@pages/dashboard/people/employees/viewemployee/[id]/holidays/page";
import EmployeeNotes from "@pages/dashboard/people/employees/viewemployee/[id]/notes/page";
import EmployeePersonalDevelopment from "@pages/dashboard/people/employees/viewemployee/[id]/personaldevelopment/page";
import EmployeeLMS from "@pages/dashboard/people/employees/viewemployee/[id]/lms/page";
import ViewCandidateLayout from "@pages/dashboard/people/candidates/view/[id]/layout";
import ViewCandidateProfile from "@pages/dashboard/people/candidates/view/[id]/page";
import ViewCandidatePreviewCV from "@pages/dashboard/people/candidates/view/[id]/previewcv/page";
import ViewCandidateJobApplications from "@pages/dashboard/people/candidates/view/[id]/jobapplications/page";
import HolodaySettings from "@pages/dashboard/settings/holiday-settings/page";
import BlogSettings from "@pages/dashboard/settings/blog-settings/page";
import AddHolidayType from "@pages/dashboard/settings/holiday-settings/addholidaysettings";
import EditHolidayType from "@pages/dashboard/settings/holiday-settings/edit";
import AddBlogType from "@pages/dashboard/settings/blog-settings/addblogsettings";
import EditBlog from "@pages/dashboard/settings/blog-settings/edit";
import ViewBlog from "@pages/viewblog";
import Account from "@pages/dashboard/account/index";
import TimeReportsPage from "@pages/dashboard/reports/time_reports/page";
import TimeReportPage from "@pages/dashboard/reports/time_reports/tabs/timereports";
import AddNewLead from "@pages/dashboard/sales/leads/addlead/page";
import ViewJobItems from "@pages/dashboard/sales/leads/view/components/jobitems";
import ViewNotes from "@pages/dashboard/sales/leads/view/components/notes";
import ViewLeadsTasks from "@pages/dashboard/sales/leads/view/components/tasks";
import ViewLead from "@pages/dashboard/sales/leads/view/page";
import ViewPartnerJobItems from "@pages/dashboard/sales/partners/view/components/jobitems";
import ViewPartnerNotes from "@pages/dashboard/sales/partners/view/components/notes";
import ViewPartnerTasks from "@pages/dashboard/sales/partners/view/components/tasks";
import ViewPartner from "@pages/dashboard/sales/partners/view/page";
import Jobs from "@pages/dashboard/workspace/jobs/page";
import AddJob from "@pages/dashboard/workspace/jobs/addjob/page";
import ViewJobDetails from "@pages/dashboard/workspace/jobs/viewjob/page";
import ViewJobCompanyDetails from "@pages/dashboard/workspace/jobs/viewjobcompany/page";
import CompanyTimeReport from "@pages/dashboard/reports/time_reports/tabs/companytimereport";
import UserTimeReport from "@pages/dashboard/reports/time_reports/tabs/usertimereport";
import Notification from "@pages/notifications/index";
import NewNotifications from "@pages/notifications/components/newnotifications";
import Read from "@pages/notifications/components/read";
import All from "@pages/notifications/components/all";

export const routes = [
  {
    name: "Dashboard Layout",
    layout: <DashboardLayout />,
    path: "/",
    routes: [
      {
        name: "Notifications",
        path: "/notifications",
        layout: <Notification />,
        routes: [
          {
            name: "New Notifications",
            index: true,
            path: "/notifications/all",
            element: <NewNotifications />,
          },
          {
            name: "Company Time Reports",
            index: false,
            path: "/notifications/read",
            element: <Read />,
          },
          {
            name: "User Time Reports",
            index: false,
            path: "/notifications/all-notifications",
            element: <All />,
          },
        ],
      },
      {
        name: "Dashboard",
        index: true,
        path: "/dashboard",
        element: <DashbordPage />,
      },
      {
        name: "Add Task",
        index: false,
        path: "/dashboard/addtask",
        element: <AddNewTask />,
      },
      {
        name: "Update Task",
        index: false,
        path: "/dashboard/updatetask/:id",
        element: <UpdateTask />,
      },
      {
        name: "Employees",
        index: false,
        path: "/people/employees",
        element: <Employee />,
      },
      {
        name: "Add Employee",
        index: false,
        path: "/people/employees/addemployee",
        element: <AddNewEmployee />,
      },
      {
        name: "Update Employee",
        index: false,
        path: "/people/employees/updateemployee/:id",
        element: <UpdateEmployee />,
      },
      {
        name: "View Employee",
        index: false,
        path: "/people/employees/view/:id",
        layout: <ViewEmployee />,
        routes: [
          {
            name: "Employee Profile",
            index: true,
            path: "/people/employees/view/:id/profile",
            element: <EmployeeProfile />,
          },
          {
            name: "Employee time reports",
            index: false,
            path: "/people/employees/view/:id/timereports",
            element: <EmployeeTimeReports />,
          },
          {
            name: "Employee Holidays",
            index: false,
            path: "/people/employees/view/:id/holidays",
            element: <EmployeeHolidays />,
          },
          {
            name: "Employee Notes",
            index: false,
            path: "/people/employees/view/:id/notes",
            element: <EmployeeNotes />,
          },
          {
            name: "Employee Personal Development",
            index: false,
            path: "/people/employees/view/:id/personaldevelopment",
            element: <EmployeePersonalDevelopment />,
          },
          {
            name: "Employee LMS",
            index: false,
            path: "/people/employees/view/:id/lms",
            element: <EmployeeLMS />,
          },
        ],
      },
      {
        name: "Candidates",
        index: false,
        path: "/people/candidates",
        element: <Candidates />,
      },
      {
        name: "Add Candidate",
        index: false,
        path: "/people/candidates/addcandidate",
        element: <AddNewCandidate />,
      },
      {
        name: "View Candidate",
        index: false,
        path: "/people/candidates/view/:id",
        layout: <ViewCandidateLayout />,
        routes: [
          {
            name: "Candidate Profile",
            index: true,
            path: "/people/candidates/view/:id/profile",
            element: <ViewCandidateProfile />,
          },
          {
            name: "Candidate time reports",
            index: false,
            path: "/people/candidates/view/:id/timereports",
            element: <EmployeeTimeReports />,
          },

          {
            name: "Candidate Notes",
            index: false,
            path: "/people/candidates/view/:id/notes",
            element: <EmployeeNotes />,
          },
          {
            name: "Candidate Job Applications",
            index: false,
            path: "/people/candidates/view/:id/jobapplications",
            element: <ViewCandidateJobApplications />,
          },
          {
            name: "Candidate LMS",
            index: false,
            path: "/people/candidates/view/:id/lms",
            element: <EmployeeLMS />,
          },
          {
            name: "Candidate PreviewCV",
            index: false,
            path: "/people/candidates/view/:id/previewcv",
            element: <ViewCandidatePreviewCV />,
          },
        ],
      },
      {
        layout: <CandidatePoolLayout />,
        path: "/people/candidate_pool",
        name: "Candidate Pool Layout",
        routes: [
          {
            name: "Candidate Pool",
            index: true,
            path: "/people/candidate_pool/candidate",
            element: <CandidatePool />,
          },
          {
            name: "Single Candidate Pool",
            index: true,
            path: "/people/candidate_pool/:department",
            element: <SingleCandidatePool />,
          },
        ],
      },

      {
        name: "Leave Layout",
        path: "/people/leave",
        layout: <LeaveLayout />,
        routes: [
          {
            name: "Leave",
            path: "/people/leave/requests",
            element: <Leave />,
          },
          {
            name: "Holidays Layout",
            path: "/people/leave/leave-type/",
            layout: <LeaveTypeLayout />,
            routes: [
              {
                name: "Holidays",
                index: true,
                element: <Holidays />,
              },
              {
                name: "Sickness",
                index: false,
                path: "/people/leave/leave-type/sickness",
                element: <Sickness />,
              },
              {
                name: "Requests",
                index: false,
                path: "/people/leave/leave-type/requests",
                element: <Requests />,
              },
              {
                name: "Today",
                index: false,
                path: "/people/leave/leave-type/today",
                element: <Today />,
              },
            ],
          },
        ],
      },
      {
        name: "Add Holiday",
        index: false,
        path: "/people/leave/leave-type/addholiday",
        element: <AddHoliday />,
      },
      {
        name: "Request Sickness",
        index: false,
        path: "/people/leave/leave-type/sickness/addsickness",
        element: <RequestSickness />,
      },
      {
        name: "Request Timeoff",
        index: false,
        path: "/people/leave/leave-type/requests/addrequests",
        element: <RequestTimeOff />,
      },

      {
        name: "Teams",
        index: false,
        path: "/people/teams",
        element: <Teams />,
      },
      {
        name: "View Team",
        index: false,
        path: "/people/teams/:team",
        element: <SingleTeam />,
      },

      {
        name: "Companies",
        index: false,
        path: "/sales/companies",
        element: <Companies />,
      },
      {
        name: "View Company",
        index: false,
        path: "/sales/companies/view/:id",
        layout: <ViewCompany />,
        routes: [
          {
            name: "Current positions",
            index: true,
            path: "/sales/companies/view/:id/positions",
            element: <ViewCompanyCurrentPosition />,
          },
          {
            name: "Leads",
            index: false,
            path: "/sales/companies/view/:id/leads",
            element: <ViewCompanyLead />,
          },
          {
            name: "Contacts",
            index: false,
            path: "/sales/companies/view/:id/contacts",
            element: <Contacts />,
          },
          {
            name: "Notes",
            index: false,
            path: "/sales/companies/view/:id/notes",
            element: <ViewCompanyNotes />,
          },
          {
            name: "Tasks",
            index: false,
            path: "/sales/companies/view/:id/tasks",
            element: <Tasks />,
          },
        ],
      },

      {
        name: "View Company Add Position",
        index: false,
        path: "/sales/companies/view/:id/addposition",
        element: <ViewCompanyAddPosition />,
      },
      {
        name: "View Company Add Lead",
        index: false,
        path: "/sales/companies/view/:id/leads/addlead",
        element: <ViewCompanyAddLead />,
      },
      {
        name: "Add Company",
        index: false,
        path: "/sales/companies/add",
        element: <AddCompany />,
      },

      {
        name: "Leads",
        index: false,
        path: "/sales/leads",
        element: <Leads />,
      },

      {
        name: "Add Lead",
        index: false,
        path: "/sales/leads/addlead",
        element: <AddNewLead />,
      },
      {
        name: "View Lead",
        index: false,
        path: "/sales/leads/view/:id",
        layout: <ViewLead />,
        routes: [
          {
            name: "Job items",
            index: true,
            path: "/sales/leads/view/:id/jobitem",
            element: <ViewJobItems />,
          },
          {
            name: "Notes",
            index: false,
            path: "/sales/leads/view/:id/notes",
            element: <ViewNotes />,
          },
          {
            name: "Tasks",
            index: false,
            path: "/sales/leads/view/:id/tasks",
            element: <ViewLeadsTasks />,
          },
        ],
      },
      {
        name: "Partners",
        index: false,
        path: "/sales/partners",
        element: <Partners />,
      },

      {
        name: "View Partner",
        index: false,
        path: "/sales/partners/view/:id",
        layout: <ViewPartner />,
        routes: [
          {
            name: "Partner Job items",
            index: true,
            path: "/sales/partners/view/:id/jobitem",
            element: <ViewPartnerJobItems />,
          },
          {
            name: "Partner Notes",
            index: false,
            path: "/sales/partners/view/:id/notes",
            element: <ViewPartnerNotes />,
          },
          {
            name: "Partner Tasks",
            index: false,
            path: "/sales/partners/view/:id/tasks",
            element: <ViewPartnerTasks />,
          },
        ],
      },

      {
        name: "Jobs",
        index: false,
        path: "/workspace/jobs",
        element: <Jobs />,
      },
      {
        name: "View Job Details",
        index: false,
        path: "/workspace/jobs/view/:id",
        element: <ViewJobDetails />,
      },
      {
        name: "View Job Company Details",
        index: false,
        path: "/workspace/jobs/view/company/:id",
        element: <ViewJobCompanyDetails />,
      },
      {
        name: "Add Job",
        index: false,
        path: "/workspace/jobs/addjob",
        element: <AddJob />,
      },
      {
        name: "Current Position",
        index: false,
        path: "/area/current_positions",
        element: <CurrentPositions />,
      },
      {
        name: "Add Position",
        index: false,
        path: "/area/current_positions/addposition",
        element: <AddPosition />,
      },
      {
        name: "View Position",
        path: "/area/current_positions/:id",
        layout: <ViewCurrentPosition />,
        routes: [
          {
            name: "Current positions",
            index: true,
            path: "/area/current_positions/:id/view",
            element: <CurrentPosition />,
          },
          {
            name: "Edit Current Position",
            index: false,
            path: "/area/current_positions/:id/edit",
            element: <EditPosition />,
          },
        ],
      },
      {
        name: "Internal Projects",
        path: "/area/internal_projects",
        layout: <InternalProjects />,
        routes: [
          {
            name: "Projects",
            index: true,
            path: "/area/internal_projects/projects",
            element: <Projects />,
          },
          {
            name: "Completed Projects",
            index: false,
            path: "/area/internal_projects/completed_projects",
            element: <CompletedProjects />,
          },
        ],
      },
      {
        name: "Add Project",
        index: false,
        path: "/area/internal_projects/addproject",
        element: <AddProject />,
      },
      {
        name: "Tasks",
        path: "/area/tasks",
        layout: <TasksPage />,
        routes: [
          {
            name: "All Tasks",
            index: true,
            path: "/area/tasks/",
            element: <TaskList />,
          },
          {
            name: "Assigned Tasks",
            index: false,
            path: "/area/tasks/assigned",
            element: <AssignedTask />,
          },
        ],
      },
      {
        name: "Add Task",
        index: false,
        path: "/area/tasks/addtask",
        element: <AddTask />,
      },

      {
        name: "Notes Page",
        index: false,
        path: "/reports/notes",
        element: <Notes />,
      },
      {
        name: "Time Reports",
        path: "/reports/time-reports",
        layout: <TimeReportsPage />,
        routes: [
          {
            name: "Time Reports",
            index: true,
            path: "/reports/time-reports/timereports",
            element: <TimeReportPage />,
          },
          {
            name: "Company Time Reports",
            index: false,
            path: "/reports/time-reports/company-time-reports",
            element: <CompanyTimeReport />,
          },
          {
            name: "User Time Reports",
            index: false,
            path: "/reports/time-reports/user-time-reports",
            element: <UserTimeReport />,
          },
        ],
      },
      {
        name: "Blogs",
        index: false,
        path: "/cms/blogs",
        element: <Blogs />,
      },
      {
        name: "Testimonials",
        index: false,
        path: "/cms/testimonials",
        element: <Testimonials />,
      },
      {
        name: "Add Testimonial",
        index: false,
        path: "/cms/testimonials/add-testimonial",
        element: <AddTestimonial />,
      },
      {
        name: "Add Blogs",
        index: false,
        path: "/cms/blogs/addblog",
        element: <AddBlog />,
      },
      {
        name: "Products",
        index: false,
        path: "/settings/products",
        element: <Products />,
      },
      {
        name: "Add Product",
        index: false,
        path: "/settings/products/addproduct",
        element: <AddProduct />,
      },
      {
        name: "Edit Product",
        index: false,
        path: "/settings/products/editproduct",
        element: <EditProduct />,
      },
      {
        name: "Holiday Settings",
        index: false,
        path: "/settings/holiday-settings",
        element: <HolodaySettings />,
      },
      {
        name: "Add Holiday Type",
        index: false,
        path: "/settings/holiday-settings/addholidaysettings",
        element: <AddHolidayType />,
      },
      {
        name: "Edit Holiday Type",
        index: false,
        path: "/settings/holiday-settings/:id",
        element: <EditHolidayType />,
      },
      {
        name: "Blog Settings",
        index: false,
        path: "/settings/blog-settings",
        element: <BlogSettings />,
      },
      {
        name: "Blog Settings",
        index: false,
        path: "/settings/blog-settings/addblogtype",
        element: <AddBlogType />,
      },
      {
        name: "Blog Settings",
        index: false,
        path: "/settings/blog-settings/:id",
        element: <EditBlog />,
      },
      {
        name: "Account",
        index: true,
        path: "/account",
        element: <Account />,
      },
    ],
  },
  {
    name: "View Blog",
    index: true,
    path: "/view-blog/:id",
    element: <ViewBlog />,
  },
];
