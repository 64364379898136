 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={29}
    fill="none"
    {...props}
  >
    <path
      stroke="#7E879F"
      strokeWidth={1.5}
      d="M9 14c0-2.828 0-4.243.879-5.121C10.757 8 12.172 8 15 8h3c2.828 0 4.243 0 5.121.879C24 9.757 24 11.172 24 14v5c0 2.828 0 4.243-.879 5.121C22.243 25 20.828 25 18 25h-3c-2.828 0-4.243 0-5.121-.879C9 23.243 9 21.828 9 19v-5Z"
    />
    <path
      stroke="#7E879F"
      strokeWidth={1.5}
      d="M9 22a3 3 0 0 1-3-3v-6c0-3.771 0-5.657 1.172-6.828C8.343 5 10.229 5 14 5h4a3 3 0 0 1 3 3"
    />
    <rect
      width={28}
      height={28}
      x={0.5}
      y={0.5}
      stroke="#657A93"
      strokeOpacity={0.27}
      rx={5.5}
    />
  </svg>
);
export default SvgComponent;
