import React, {Fragment} from "react";
import tasks from "./data.json";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import BadgeDropdown from "@components/dropdowns/badgedropdown";
import {STATUSLIST} from "@components/utils";
import {TrashIcon} from "@heroicons/react/24/outline";

function TaskList({onDelete}: {onDelete: (data: any) => void}) {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(3);

  const handleNext = () => {
    setStart(start + 3);
    setEnd(end + 3);
  };

  const handlePrev = () => {
    setStart(start - 3);
    setEnd(end - 3);
  };

  return (
    <div className="px-5">
      <div className="-mx-4 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-100">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Name
              </th>
              <th
                scope="col"
                className="py-3.5  text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Tasks
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Notes
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Assigned To
              </th>
              <th
                scope="col"
                className="hidden ps-4  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 bg-white">
            {tasks?.slice(start, end).map((task) => (
              <Fragment key={task?.id}>
                <tr key={task.id} className="cursor-pointer hover:bg-gray-50">
                  <td className="whitespace-nowrap  py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                    {task?.user}
                  </td>
                  <td className="hidden w-60 py-4 text-sm text-gray-900 sm:table-cell">
                    <span className="line-clamp-2 ">
                      Building the frontend architecture using React
                    </span>
                  </td>
                  <td className="hidden w-60  py-4 text-sm text-gray-900 sm:table-cell">
                    <span className="line-clamp-2 ">
                      Reduce technical debt by refactoring legacy code....
                    </span>
                  </td>

                  <td className="whitespace-nowrap py-4 text-sm font-medium sm:pl-0">
                    {task?.user}
                  </td>
                  <td className="py-4 text-sm font-medium sm:pl-0">
                    <div>
                      <BadgeDropdown
                        selected={STATUSLIST?.find(
                          (item) => item.name === "Pending"
                        )}
                        setSelected={() => null}
                      />
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm font-medium sm:pl-0">
                    <button
                      type="button"
                      onClick={() => onDelete(task)}
                      className="focus:outline-none z-10 text-white text-sm font-light"
                    >
                      <TrashIcon className="h-5 w-5 text-red-500" />
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              disabled={start === 0}
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TaskList;
