 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#F37C7C"
      d="M7.214 15.203a.831.831 0 0 1-.243-.59v-.101c0-.461.373-.834.833-.834h4.553L4.655 5.977a.834.834 0 0 1 1.179-1.179l7.702 7.702V7.947c0-.46.373-.834.833-.833h.101c.46 0 .834.373.833.833v6.666c0 .46-.373.834-.833.833H7.804a.832.832 0 0 1-.59-.243Z"
    />
  </svg>
);
export default SvgComponent;
