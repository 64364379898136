import Modal from "@components/modal/modal";

function ViewJD({show, setShow}: {show: boolean; setShow: any}) {
  return (
    <Modal open={show} setOpen={setShow} title="ADMINISTRATIVE ASSISTANT JD">
      <div className="text-sm font-light max-h-[80vh] overflow-scroll">
        Are you an organised and creative Administrative Assistant with
        experience in Canva and social media management? Remoteli is seeking a
        talented individual to join our team in Accra, Ghana. If you're
        passionate about providing administrative support and have a knack for
        creating engaging social media content using Canva, we want to hear from
        you! Summary: As an Administrative Assistant with Canva experience and
        social media knowledge at Remoteli's Accra office, you'll play a vital
        role in supporting administrative functions while leveraging your
        creative skills to enhance our social media presence. From managing
        schedules to designing eye-catching graphics, you'll contribute to our
        overall efficiency and brand visibility. Key Responsibilities:
        <br />
        <br />
        1. Administrative Support: Provide administrative assistance to the
        team, including scheduling appointments, managing calendars, organizing
        meetings, and handling correspondence. <br />
        <br />
        2. Canva Design: Utilize Canva to create visually appealing graphics,
        presentations, and marketing materials for internal and external use,
        ensuring brand consistency and professionalism.
        <br />
        <br />
        3. Social Media Management: Assist in managing Remoteli's social media
        accounts, including content creation, scheduling posts, engaging with
        followers, and monitoring analytics to track performance.
        <br />
        <br />
        4. Document Creation: Prepare documents, reports, and presentations
        using Microsoft Office suite (Word, Excel, PowerPoint) and Canva,
        ensuring accuracy and professionalism in all deliverables.
        <br />
        <br />
        5. Data Entry and Management: Perform data entry tasks, update records,
        and maintain databases accurately and efficiently, ensuring data
        integrity and confidentiality.
        <br />
        <br />
        6. Communication Support: Assist in drafting emails, newsletters, and
        announcements, and coordinate communication efforts to keep team members
        informed and engaged.
        <br />
        <br />
        7. Event Coordination: Support the planning and execution of company
        events, workshops, and webinars, including logistics coordination,
        attendee management, and post-event follow-up.
        <br />
        <br />
        8. Research Assistance: Conduct research on various topics as needed,
        compile findings into comprehensive reports, and present insights to
        support decision-making processes.
        <br />
        <br />
        9. Social Media Analytics: Monitor social media metrics and analytics,
        analyze trends, and generate reports to assess the effectiveness of
        social media campaigns and inform future strategies.
        <br />
        <br />
        10. Collaborative Projects: Collaborate with cross-functional teams on
        special projects, initiatives, and campaigns, providing administrative
        and creative support as required.
        <br />
        <br />
        Skills & Experience: Proven experience as an Administrative Assistant or
        Office Administrator, with strong organizational and time management
        skills. Proficiency in Canva for graphic design, with experience
        creating visual content such as social media graphics, presentations,
        and marketing materials. Knowledge of social media platforms (e.g.,
        Facebook, Instagram, LinkedIn, Twitter) and experience managing social
        media accounts for businesses or organizations. Excellent written and
        verbal communication skills, with the ability to craft engaging content
        and interact professionally with internal and external stakeholders.
        Detail-oriented mindset, with a keen eye for design aesthetics and
        accuracy in administrative tasks and data management. Ability to
        multitask, prioritize workload, and meet deadlines in a fast-paced
        environment while maintaining a positive attitude and professional
        demeanour. Proficiency in Microsoft Office suite (Word, Excel,
        PowerPoint) and familiarity with collaboration tools like Google
        Workspace and project management platforms. Creative thinking and
        problem-solving skills, with the ability to generate innovative ideas
        and solutions to support organizational goals and initiatives.
        Bachelor's degree in Business Administration, Communications, Marketing,
        or a related field is preferred.
      </div>
    </Modal>
  );
}

export default ViewJD;
