import FilePicker from "@components/filepicker";
import Input from "@components/input";
import {XMarkIcon} from "@heroicons/react/24/solid";

interface SubtaskProps {
  setName: (index: number, name: string) => void;
  setFile?: (index: number, file: string) => void;
  setNotes: (index: number, notes: string) => void;
  subTask: {
    name: string;
    notes: string;
    file: string;
  };
  index: number;
  removeSubTask: (index: number) => void;
}

function Subtask({
  setName,
  setNotes,
  subTask,
  index,
  removeSubTask,
}: SubtaskProps) {
  return (
    <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg  bg-custom_faded_blue">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-outfit font-medium">
          SUB Task {index + 1}
        </h1>
        <button onClick={() => removeSubTask(index)}>
          <XMarkIcon className="w-5 h-5 text-red-500 " />
        </button>
      </div>

      <div className="my-4 gap-y-4 flex flex-col">
        <div className="">
          <label className="text-xs text-[#82868C]">Name</label>
          <Input
            value={subTask.name}
            onChange={(e: any) => {
              setName(index, e.target.value);
            }}
            placeholder="Eg. Jane doe"
            className="mt-1"
          />
        </div>

        <div>
          <FilePicker />
        </div>

        <div>
          <textarea
            rows={8}
            value={subTask.notes}
            onChange={(e: any) => {
              setNotes(index, e.target.value);
            }}
            className="w-full rounded-lg bg-transparent text-sm py-3 px-4 border-[#657A9345]"
            placeholder="Write Notes Here..."
          />
        </div>
      </div>
    </div>
  );
}

export default Subtask;
