import Graphs from "@components/stats/graphs/leavegraph";
import {BellIcon} from "@heroicons/react/24/solid";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import HandingOvers from "./dataview/list";
import LeaveSummary from "./leavesummary";

function Leave() {
  const [showNotification, setShowNotification] = useState(true);
  return (
    <>
      <Graphs />
      {showNotification ? (
        <div className="bg-[#FF9A401A] rounded-xl flex items-start justify-between px-6 my-5 py-4">
          <div className="flex">
            <BellIcon className="h-6 w-6 text-[#FF9A40] inline-block" />
            <div className="ms-4">
              <p className=" text-black inline-block">
                Mi-lady Banfo is handing over her tasks and responsibilities you
                as she goes on a holiday.{" "}
              </p>
              <div className="flex gap-x-4 mt-2">
                <button className="text-[#FF9A40]">Learn more</button>
                <button className="text-[#FF9A40]">Accept</button>
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowNotification(false)}
            className="text-[#E94A74]"
          >
            <XCircleIcon className="h-6 w-6" />
          </button>
        </div>
      ) : null}

      <LeaveSummary />

      <div className="mt-4 bg-[#DDEFFF33] rounded-lg">
        <HandingOvers />
      </div>
    </>
  );
}

export default Leave;
