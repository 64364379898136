import {ITask} from "../initialdata";
import {TrashIcon} from "@heroicons/react/24/outline";
import {Draggable} from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import Avartar from "@assets/Avatar 1.png"
interface Props {
  key: string;
  index: number;
  task: ITask;
  handleDelete: any;
}
function Task(props: Props) {
  const navigate = useNavigate();

  return (
    <Draggable draggableId={props.task.id} index={props.index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`border-custom_faded_puple min-w-[361px] p-4 border bg-white rounded-2xl flex flex-col mb-4 ${
            snapshot?.isDragging
              ? snapshot.draggingOver === "column-2"
                ? "border-green-50"
                : snapshot.draggingOver === "column-3"
                  ? "border-orange-50"
                  : "border-purple-50"
              : " border-gray-100"
          }`}
        >
          <div {...provided.dragHandleProps}>
            <div className="flex justify-between items-center">
              <h3
                onClick={() => navigate(`view/${props.task.id}`)}
                className="font-semibold text-lg text-[#2B3674]"
              >
                10PM Curfew
              </h3>
              <TrashIcon
                onClick={() => props.handleDelete()}
                className="text-[#E94A74] h-5 w-5"
              />
            </div>
            <span
              onClick={() => navigate(`view/${props.task.id}`)}
              className="text-xs text-[#A3AED0] my-2"
            >
              Computer Software/Engineering
            </span>
            <div
              onClick={() => navigate(`view/${props.task.id}`)}
              className="flex text-xs my-3 justify-between"
            >
              <div className="flex items-center">
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 0.125C6.29293 0.125 7.53291 0.638615 8.44715 1.55285C9.36139 2.46709 9.875 3.70707 9.875 5C9.875 7.06 8.4275 9.305 5.57 11.759C5.41111 11.8955 5.20853 11.9705 4.99906 11.9703C4.7896 11.9701 4.58715 11.8948 4.4285 11.758L4.2395 11.594C1.5085 9.204 0.125 7.014 0.125 5C0.125 3.70707 0.638615 2.46709 1.55285 1.55285C2.46709 0.638615 3.70707 0.125 5 0.125ZM5 0.875C3.90598 0.875 2.85677 1.3096 2.08318 2.08318C1.3096 2.85677 0.875 3.90598 0.875 5C0.875 6.751 2.149 8.7685 4.732 11.0285L4.9185 11.19C4.94118 11.2095 4.97009 11.2202 5 11.2202C5.02991 11.2202 5.05882 11.2095 5.0815 11.19C7.7895 8.864 9.125 6.7925 9.125 5C9.125 4.4583 9.0183 3.9219 8.811 3.42143C8.6037 2.92096 8.29986 2.46623 7.91682 2.08318C7.53377 1.70014 7.07904 1.3963 6.57857 1.189C6.0781 0.981696 5.5417 0.875 5 0.875ZM5 3.125C5.49728 3.125 5.9742 3.32254 6.32583 3.67417C6.67746 4.02581 6.875 4.50272 6.875 5C6.875 5.49728 6.67746 5.97419 6.32583 6.32583C5.9742 6.67746 5.49728 6.875 5 6.875C4.50272 6.875 4.02581 6.67746 3.67417 6.32583C3.32254 5.97419 3.125 5.49728 3.125 5C3.125 4.50272 3.32254 4.02581 3.67417 3.67417C4.02581 3.32254 4.50272 3.125 5 3.125ZM5 3.875C4.70163 3.875 4.41548 3.99353 4.2045 4.2045C3.99353 4.41548 3.875 4.70163 3.875 5C3.875 5.29837 3.99353 5.58452 4.2045 5.7955C4.41548 6.00647 4.70163 6.125 5 6.125C5.29837 6.125 5.58452 6.00647 5.7955 5.7955C6.00647 5.58452 6.125 5.29837 6.125 5C6.125 4.70163 6.00647 4.41548 5.7955 4.2045C5.58452 3.99353 5.29837 3.875 5 3.875Z"
                    fill="#233A50"
                  />
                </svg>

                <span className="ms-1">Afghanistan</span>
              </div>
              <div className="flex items-center ">
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  className="me-1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33333 9.66732C1.0125 9.66732 0.737942 9.55318 0.509664 9.3249C0.281386 9.09662 0.167053 8.82187 0.166664 8.50065V1.50065C0.166664 1.17982 0.280997 0.905262 0.509664 0.676984C0.738331 0.448706 1.01289 0.334373 1.33333 0.333984H10.6667C10.9875 0.333984 11.2622 0.448318 11.4909 0.676984C11.7196 0.905651 11.8337 1.18021 11.8333 1.50065V8.50065C11.8333 8.82148 11.7192 9.09623 11.4909 9.3249C11.2626 9.55357 10.9879 9.66771 10.6667 9.66732H1.33333ZM6 5.58398L1.33333 2.66732V8.50065H10.6667V2.66732L6 5.58398ZM6 4.41732L10.6667 1.50065H1.33333L6 4.41732ZM1.33333 2.66732V1.50065V8.50065V2.66732Z"
                    fill="#94A4C4"
                  />
                </svg>

                <span className="text-[#A3AED0]">
                  partnerships@10pmcurfew.com
                </span>
              </div>
              {/* <span>+44 7508 397545</span> */}
            </div>

            <div
              onClick={() => navigate(`view/${props.task.id}`)}
              className="justify-between flex items-center text-xs"
            >
              <div className="flex items-center">
                <img
                  src={Avartar}
                  className="w-6 h-6 rounded-full"
                  alt="company logo"
                />

                <span className="text-[#82868C] ms-2">Liam Summers</span>
              </div>
              <div className="items-center flex">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.26795 1.05931L4.76605 1.21051C4.31916 1.34528 3.92006 1.60491 3.61574 1.95884C3.31141 2.31276 3.11453 2.74626 3.04825 3.20831C2.84035 4.65591 3.30515 6.34851 4.42585 8.28961C5.54375 10.2258 6.77435 11.4732 8.12885 12.0192C8.56406 12.1946 9.04048 12.2413 9.50148 12.1539C9.96248 12.0664 10.3887 11.8484 10.7294 11.5257L11.1088 11.1659C11.3551 10.9327 11.5084 10.6181 11.5402 10.2804C11.572 9.94264 11.4802 9.60487 11.2817 9.32981L10.3325 8.01381C10.2041 7.83614 10.0234 7.70309 9.81566 7.63329C9.60791 7.5635 9.38352 7.56046 9.17395 7.62461L7.73825 8.06351L7.70115 8.07051C7.54295 8.09361 7.17755 7.75131 6.72255 6.96311C6.24655 6.13851 6.14855 5.65621 6.27945 5.53161L7.00955 4.85051C7.27654 4.60163 7.45892 4.27542 7.5311 3.91763C7.60329 3.55984 7.56166 3.18843 7.41205 2.85551L6.94865 1.82581C6.80931 1.51595 6.56204 1.26733 6.25294 1.12631C5.94384 0.985281 5.59331 0.961468 5.26795 1.05931ZM6.31165 2.11281L6.77365 3.14251C6.86363 3.34217 6.88879 3.565 6.8456 3.7797C6.8024 3.9944 6.69303 4.19017 6.53285 4.33951L5.80065 5.02131C5.33165 5.46511 5.48705 6.22251 6.11705 7.31311C6.70925 8.33931 7.24965 8.84611 7.83065 8.75791L7.91745 8.73971L9.37905 8.29381C9.44892 8.27235 9.52375 8.2733 9.59304 8.29652C9.66234 8.31975 9.72263 8.36408 9.76545 8.42331L10.7147 9.73931C10.814 9.87682 10.8601 10.0458 10.8443 10.2147C10.8284 10.3836 10.7518 10.5411 10.6286 10.6577L10.2485 11.0175C10.0051 11.2478 9.70073 11.4034 9.37153 11.4659C9.04233 11.5283 8.70213 11.4949 8.39135 11.3696C7.20275 10.8908 6.07925 9.75191 5.03275 7.93961C3.98345 6.12311 3.55855 4.57821 3.74125 3.30771C3.78854 2.97761 3.92917 2.66789 4.14656 2.41503C4.36396 2.16217 4.64908 1.97668 4.96835 1.88041L5.47025 1.72921C5.63297 1.68031 5.80792 1.69227 5.96248 1.76285C6.11703 1.83343 6.24064 1.95781 6.31025 2.11281"
                    fill="#94A4C4"
                  />
                </svg>
                <span className="text-[#A3AED0] text-xs">+44 7508 397545</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default Task;
