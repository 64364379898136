import Stats from "@components/stats/leavestats";
import {Outlet} from "react-router-dom";

function LeaveLayout() {
  return (
    <div>
      <div className="bg-[#DDEFFF33] px-4 rounded-lg py-3 font-inter">
        <Stats />
        <Outlet />
      </div>
    </div>
  );
}

export default LeaveLayout;
