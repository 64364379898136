import CustomDatePicker from "@components/datepicker";
import Input from "@components/input";
import CandidatesIcon from "@components/svgs/teamsbig";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

function HolidaySettings() {
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();

  return (
    <div className="mt-4">
      <div className="bg-[#DDEFFF33] rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add a new holiday type</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>
      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-gray-400 hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 bg-[#DDEFFF33] px-4 py-4 rounded-xl mt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Name of holiday:{" "}
          </label>
          <Input placeholder={"eg. Saint Patrick Holiday"} className={"mt-1"} />
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Number of days allocated:
          </label>
          <Input placeholder={"eg. 2"} className={"mt-1"} />
        </div>

        <div className="col-span-4">
          <div className="mt-1">
            <textarea
              rows={6}
              placeholder="Eg. Holiday description here...."
              className="mt-1 w-full rounded-xl bg-transparent border placeholder:text-[#9CA1AD] border-[#657A9345]"
            />
          </div>
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 hover:bg-transparent border-gray-300 focus:border-0 focus:outline-0 hover:text-custom_blue bg-transparent checked:bg-custom_dark_blue checked:border-custom_gray"
            />
            <label
              htmlFor="remember-me"
              className="ml-3 block text-sm font-outfit leading-6 text-custom_gray"
            >
              Check the box if you want this leave to be deducted from the
              annual leave
            </label>
          </div>
        </div>

        <div className="col-span-4">
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Date:
          </label>
          <CustomDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>

        <div className="col-span-4">
          <div className="mt-8 flex justify-end">
            <div>
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => null}
              >
                Add leave type
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HolidaySettings;
