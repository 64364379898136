 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#087E53"
      d="M15 .5v5.469L12 7.75V4.719l-1.5.937L7 7.75V4.719l-1.5.937-5 3-.5.281V22.5h22V.5h-7Zm2 2h3v18H2V10.031L5 8.25v3.031l1.5-.937L10 8.25v3.031l1.5-.937 5-3 .5-.281V2.5Zm-13 10v2h2v-2H4Zm4 0v2h2v-2H8Zm4 0v2h2v-2h-2Zm4 0v2h2v-2h-2Zm-12 4v2h2v-2H4Zm4 0v2h2v-2H8Zm4 0v2h2v-2h-2Zm4 0v2h2v-2h-2Z"
    />
  </svg>
);
export default SvgComponent;
