 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#27CE88"
      d="M4 4.5a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9.883l-1 1.01V4.5a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h4.085c.071.2.185.389.344.55l.441.45H6a2 2 0 0 1-2-2v-12ZM8 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm1.5-.5a.5.5 0 1 0 0 1h4a.5.5 0 0 0 0-1h-4Zm0 4a.5.5 0 1 0 0 1h4a.5.5 0 0 0 0-1h-4ZM9 14a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 14Zm-2-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10.855.352a.5.5 0 0 0-.71-.704l-3.643 3.68-1.645-1.678a.5.5 0 1 0-.714.7l1.929 1.968a.6.6 0 0 0 .855.002l3.928-3.968Z"
    />
  </svg>
);
export default SvgComponent;
