function TableLoader() {
  return (
    <div className="flex flex-col gap-4 mt-5">
      {Array.from({length: 5}).map((_, i) => (
        <div
          key={i}
          className="whitespace-nowrap bg-gray-300 py-4 pl-4 h-10 w-full animate-pulse pr-3 text-xs font-medium text-gray-900 sm:pl-0"
        />
      ))}
    </div>
  );
}

export default TableLoader;
