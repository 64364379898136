// import EmptyInterface from "@components/empty";

import moment from "moment";
import chaticon from "@assets/svgs/chat.svg";
import gmail from "@assets/svgs/gmail.svg";
import call from "@assets/svgs/call.svg";
import meet from "@assets/svgs/meet.svg";
import {Fragment} from "react/jsx-runtime";
import {useState} from "react";

const notes = [
  {
    id: 1,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: chaticon,
    author: "Liam Summers",
    border: "border-custom_yellow",
    bg: "bg-custom_yellow",
    text: "text-custom_yellow",
  },
  {
    id: 2,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: gmail,
    author: "Liam Summers",
    border: "border-custom_deep_purple",
    bg: "bg-custom_deep_purple",
    text: "text-custom_deep_purple",
  },
  {
    id: 3,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: call,
    author: "Liam Summers",
    border: "border-card_pink",
    bg: "bg-card_pink",
    text: "text-card_pink",
  },
  {
    id: 4,
    companyName: "Diamond Logistics",
    title: "Meeting with customer",
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.Reduce technical debt by refactoring legacy code and improving architecture design.",
    icon: meet,
    author: "Liam Summers",
    border: "border-custom_green",
    bg: "bg-custom_green",
    text: "text-custom_green",
  },
];

function Notes() {
  const [selected, setSelected] = useState(notes[0]);
  return (
    <div className="mt-4 py-2 bg-custom_faded_blue rounded-xl">
      {/* left pane to contain cards */}
      <div className="grid grid-cols-8 gap-10">
        <div className="col-span-3 max-h-[75vh] pe-5 overflow-y-scroll gap-2 flex flex-col">
          {notes?.map((note) => (
            <Fragment key={note?.id}>
              <NoteCard note={note} handleSelection={() => setSelected(note)} />
            </Fragment>
          ))}
        </div>

        {/* right pane to contain details */}
        <div className="col-span-5">
          <h1
            className={`text-2xl ${selected?.text} font-outfit font-semibold`}
          >
            {selected?.companyName}
          </h1>
          <div className="mt-4">
            <div className="flex flex-col gap-3">
              <p className="text-black">{selected?.author}</p>
              <p className="text-black font-light text-sm">
                {moment()?.format("DD/MM/YYYY HH:MMa")}
              </p>
            </div>
          </div>

          <div className="mt-10">
            <p className="text-2xl font-semibold text-black mt-2">
              {selected?.title}
            </p>
            <p className="text-[#1C1C1C66] mt-2">{selected.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;

const NoteCard = ({note, handleSelection}: any) => {
  return (
    <div
      onClick={handleSelection}
      className={`bg-white rounded-xl cursor-pointer select-none border p-4 ${note?.border}`}
    >
      <div className="flex justify-between items-center">
        <span
          className={`"font-outfit text-sm font-light ${note?.bg} px-3 py-1 rounded-xl text-white`}
        >
          {note?.companyName}
        </span>
        <div className="">
          <img src={note.icon} alt="chat" />
        </div>
      </div>
      <div className="mt-4">
        <p className=" text-black mt-2">{note?.title}</p>
        <p className="text-sm text-[#1C1C1C66] mt-2 line-clamp-3">
          {note.description}
        </p>
      </div>

      <div className="mt-4">
        <div className="flex justify-between items-center gap-3">
          <p className="text-black font-light text-sm">{note.author}</p>
          <p className="text-black font-light text-sm">
            {moment()?.format("DD/MM/YYYY HH:MMa")}
          </p>
        </div>
      </div>
    </div>
  );
};
