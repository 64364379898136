 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={29}
    fill="none"
    {...props}
  >
    <path
      fill="#7E879F"
      d="M18.25 18.75c-.41 0-.75.34-.75.75V21c0 .69-.56 1.25-1.25 1.25h-7.5c-.69 0-1.25-.56-1.25-1.25V9c0-.69.56-1.25 1.25-1.25H12v4.75c0 .41.34.75.75.75h4.75v1.25c0 .41.34.75.75.75s.75-.34.75-.75v-2c0-.2-.08-.39-.22-.53l-5.5-5.5a.75.75 0 0 0-.53-.22h-4C7.23 6.25 6 7.48 6 9v12c0 1.52 1.23 2.75 2.75 2.75h7.5c1.52 0 2.75-1.23 2.75-2.75v-1.5c0-.41-.34-.75-.75-.75ZM13.5 8.81l2.94 2.94H13.5V8.81Zm10.44 8.48c-.04.09-.09.17-.16.24l-3 3c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l1.72-1.72h-8.69c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8.69l-1.72-1.72a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l3 3c.07.07.12.15.16.24.08.18.08.39 0 .57v.01Z"
    />
    <rect
      width={28}
      height={28}
      x={0.5}
      y={0.5}
      stroke="#657A93"
      strokeOpacity={0.27}
      rx={5.5}
    />
  </svg>
);
export default SvgComponent;
