import CustomDatePicker from "@components/datepicker";
import Dropdown from "@components/dropdowns/dropdown";
import FilePicker from "@components/filepicker";
import {useState} from "react";

function Step1() {
  const [type, setType] = useState("Doctors");
  const [startDate, setStartDate] = useState("");
  const [user, setUser] = useState("");
  return (
    <>
      <div>
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          User:{" "}
        </label>
        <Dropdown
          list={[
            {
              id: "me",
              name: "Mathias Boampong",
              value: "Mathias Boampong",
            },
          ]}
          selected={user}
          setSelected={setUser}
        />
      </div>
      <div>
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Type:
        </label>
        <Dropdown
          list={[
            {
              id: "Doctors",
              name: "Doctors",
              value: "Doctors",
            },
            {
              id: "Emergency",
              name: "Emergency",
              value: "Emergency",
            },
            {
              id: "Funeral",
              name: "Funeral",
              value: "Funeral",
            },
          ]}
          selected={type}
          setSelected={setType}
        />
      </div>
      <div>
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Start date:{" "}
        </label>
        <CustomDatePicker
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        />
      </div>

      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Reason:{" "}
        </label>
        <div className="mt-1 relative">
          <textarea
            rows={6}
            placeholder="Write Notes here...."
            className="mt-1 w-full rounded-xl bg-transparent border py-4 placeholder:text-[#9CA1AD] border-[#657A9345]"
          />
        </div>
      </div>

      <div className="col-span-2">
        <FilePicker />
      </div>
    </>
  );
}

export default Step1;
