 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#134270"
      d="M16 12.5h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2v-2h8m-10-2H6v-2h2m0 6H6v-2h2m0 6H6v-2h2m0 6H6v-2h2m-4-10H2v-2h2m0 6H2v-2h2m0 6H2v-2h2m0 6H2v-2h2m6-10v-4H0v18h20v-14H10Z"
    />
  </svg>
);
export default SvgComponent;
