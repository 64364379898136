function BlogStatsBigIcon() {
  return (
    <svg
      width="140"
      height="132"
      viewBox="0 0 140 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_486_35164)">
        <path
          d="M112.201 102.221C111.061 102.221 109.947 101.882 108.999 101.247C108.051 100.612 107.312 99.7096 106.876 98.6537C106.44 97.5978 106.325 96.436 106.548 95.3151C106.77 94.1942 107.319 93.1646 108.125 92.3565C108.932 91.5484 109.959 90.9981 111.077 90.7751C112.195 90.5521 113.354 90.6666 114.407 91.1039C115.46 91.5413 116.361 92.2819 116.994 93.2321C117.627 94.1824 117.966 95.2996 117.966 96.4424C117.964 97.9744 117.356 99.4431 116.275 100.526C115.195 101.61 113.73 102.219 112.201 102.221Z"
          fill="#CCCCCC"
        />
        <path
          d="M114.862 96.219H112.423V93.441C112.423 93.3821 112.4 93.3255 112.358 93.2838C112.317 93.2422 112.26 93.2188 112.201 93.2188C112.143 93.2188 112.086 93.2422 112.045 93.2838C112.003 93.3255 111.98 93.3821 111.98 93.441V96.219H109.541C109.482 96.219 109.426 96.2425 109.384 96.2841C109.343 96.3258 109.319 96.3823 109.319 96.4413C109.319 96.5002 109.343 96.5568 109.384 96.5984C109.426 96.6401 109.482 96.6635 109.541 96.6635H111.98V99.4416C111.98 99.5005 112.003 99.5571 112.045 99.5987C112.086 99.6404 112.143 99.6638 112.201 99.6638C112.26 99.6638 112.317 99.6404 112.358 99.5987C112.4 99.5571 112.423 99.5005 112.423 99.4416V96.6635H114.862C114.921 96.6635 114.977 96.6401 115.019 96.5984C115.06 96.5568 115.083 96.5002 115.083 96.4413C115.083 96.3823 115.06 96.3258 115.019 96.2841C114.977 96.2425 114.921 96.219 114.862 96.219Z"
          fill="white"
        />
        <path
          d="M134.399 129.296L131.865 129.296L130.66 119.496L134.4 119.496L134.399 129.296Z"
          fill="#FFB8B8"
        />
        <path
          d="M130.054 128.57H134.942V131.656H126.977C126.977 131.25 127.056 130.849 127.211 130.475C127.366 130.101 127.592 129.76 127.878 129.474C128.164 129.187 128.503 128.96 128.877 128.805C129.25 128.65 129.65 128.57 130.054 128.57Z"
          fill="#2F2E41"
        />
        <path
          d="M124.312 129.296L121.777 129.296L120.572 119.496L124.313 119.496L124.312 129.296Z"
          fill="#FFB8B8"
        />
        <path
          d="M119.967 128.57H124.855V131.656H116.889C116.889 131.25 116.969 130.849 117.124 130.475C117.278 130.101 117.505 129.76 117.791 129.474C118.077 129.187 118.416 128.96 118.789 128.805C119.163 128.65 119.563 128.57 119.967 128.57Z"
          fill="#2F2E41"
        />
        <path
          d="M136.352 95.4152C136.148 95.1894 135.996 94.9215 135.906 94.6303C135.817 94.3392 135.792 94.0319 135.834 93.7301C135.875 93.4283 135.983 93.1394 136.148 92.8837C136.313 92.628 136.532 92.4117 136.79 92.25L136.005 87.5533L138.736 86.3789L139.751 93.0273C139.994 93.4791 140.062 94.0043 139.944 94.5034C139.825 95.0024 139.529 95.4406 139.11 95.735C138.691 96.0293 138.178 96.1594 137.67 96.1004C137.162 96.0415 136.693 95.7977 136.352 95.4152Z"
          fill="#FFB8B8"
        />
        <path
          d="M114.971 93.1976C115.21 93.0087 115.404 92.7696 115.54 92.4973C115.676 92.225 115.752 91.926 115.76 91.6214C115.769 91.3169 115.71 91.0142 115.59 90.7346C115.469 90.455 115.288 90.2054 115.061 90.0033L116.609 85.5011L114.109 83.8906L112.012 90.2794C111.699 90.685 111.545 91.1916 111.579 91.7034C111.613 92.2152 111.834 92.6965 112.199 93.0562C112.563 93.4159 113.047 93.629 113.558 93.6551C114.069 93.6811 114.572 93.5184 114.971 93.1976Z"
          fill="#FFB8B8"
        />
        <path
          d="M127.952 60.0304C130.959 60.0304 133.397 57.5865 133.397 54.5718C133.397 51.5572 130.959 49.1133 127.952 49.1133C124.945 49.1133 122.507 51.5572 122.507 54.5718C122.507 57.5865 124.945 60.0304 127.952 60.0304Z"
          fill="#FFB8B8"
        />
        <path
          d="M114.803 89.8295C114.71 89.8294 114.617 89.8162 114.527 89.7903L112.94 89.3335C112.686 89.2601 112.472 89.0892 112.344 88.8581C112.216 88.627 112.184 88.3544 112.255 88.0998L115.531 76.5071L120.098 65.267C120.552 64.1496 121.501 63.4168 122.575 63.3546C123.065 63.3306 123.551 63.4496 123.975 63.6972C124.4 63.9448 124.743 64.3103 124.964 64.7496C125.21 65.2197 125.345 65.74 125.358 66.2706C125.372 66.8011 125.264 67.3278 125.043 67.81L119.852 79.1912L115.724 89.2108C115.649 89.3937 115.521 89.5502 115.357 89.6604C115.193 89.7705 115 89.8293 114.803 89.8295Z"
          fill="#3F3D56"
        />
        <path
          d="M131.025 59.1465L127.999 59.2004C127.684 59.2066 127.372 59.1385 127.088 59.0016C126.804 58.8647 126.556 58.6627 126.364 58.4121C126.172 58.1614 126.042 57.8691 125.983 57.5586C125.925 57.2481 125.94 56.9282 126.027 56.6247C126.092 56.4053 126.14 56.1815 126.172 55.9551C126.207 55.7082 126.213 55.4582 126.192 55.2098C126.173 54.961 126.07 54.7262 125.901 54.5438C125.731 54.3614 125.504 54.2422 125.258 54.2058C125.012 54.1694 124.76 54.218 124.545 54.3435C124.33 54.4691 124.164 54.6642 124.074 54.8968C123.565 54.9041 122.482 54.7378 121.973 54.7451C120.893 51.9693 123.247 48.3371 125.78 46.9728C128.364 45.582 131.717 46.9969 132.707 49.8957C134.081 49.9204 135.197 51.2791 135.374 52.8085C135.552 54.3379 134.924 55.9349 133.964 57.1071C133.004 58.2793 131.743 59.1337 131.025 59.1465Z"
          fill="#2F2E41"
        />
        <path
          d="M121.08 128.381C120.835 128.38 120.599 128.288 120.416 128.124C120.234 127.96 120.118 127.734 120.091 127.49L118.968 89.6562L133.11 90.6654L133.121 90.7565C136.316 117.573 135.224 126.856 135.213 126.947C135.205 127.084 135.169 127.218 135.107 127.341C135.045 127.463 134.959 127.572 134.853 127.659C134.747 127.747 134.625 127.812 134.493 127.85C134.361 127.888 134.223 127.899 134.087 127.881L130.962 127.956C130.725 127.929 130.506 127.818 130.343 127.643C130.181 127.467 130.087 127.24 130.078 127.001L127.158 99.5983C127.14 99.5272 127.097 99.4646 127.038 99.4217C126.978 99.3788 126.906 99.3582 126.833 99.3636C126.757 99.3634 126.685 99.3893 126.626 99.437C126.568 99.4848 126.528 99.5513 126.514 99.6252L125.385 127.118C125.397 127.373 125.311 127.623 125.144 127.816C124.978 128.009 124.743 128.131 124.489 128.156L121.179 128.376C121.146 128.38 121.113 128.381 121.08 128.381Z"
          fill="#2F2E41"
        />
        <path
          d="M126.948 94.177C126.863 94.177 126.779 94.1757 126.694 94.1731C122.628 94.0504 119.446 90.9663 118.568 90.0239C118.456 89.9038 118.376 89.7578 118.334 89.5989C118.292 89.44 118.29 89.2732 118.328 89.1134L120.674 79.1648L120.052 70.5157C119.954 69.3153 120.111 68.1078 120.513 66.9727C120.914 65.8375 121.551 64.8005 122.381 63.9299C123.073 63.1973 123.914 62.6224 124.846 62.2441C125.779 61.8658 126.782 61.6929 127.787 61.7373C131.746 61.9266 134.869 65.4735 134.897 69.812C134.941 76.5315 134.744 76.9931 134.679 77.1448C132.703 81.7668 133.782 88.2547 134.16 90.1331C134.194 90.3007 134.184 90.4741 134.132 90.637C134.08 90.7999 133.988 90.9468 133.863 91.0638C131.691 93.1304 129.365 94.1769 126.948 94.177Z"
          fill="#3F3D56"
        />
        <path
          d="M137.16 91.6498C136.935 91.6496 136.716 91.5727 136.539 91.4317C136.363 91.2906 136.24 91.0938 136.189 90.8732L133.769 80.3039L130.525 68.2233C130.387 67.7111 130.367 67.1738 130.468 66.6528C130.569 66.1318 130.788 65.641 131.108 65.218C131.398 64.8216 131.797 64.5181 132.256 64.3442C132.715 64.1702 133.215 64.1333 133.694 64.2379C134.743 64.4771 135.558 65.3569 135.822 66.5342L138.472 78.3641L139.794 90.3521C139.822 90.6151 139.746 90.8786 139.581 91.0853C139.417 91.292 139.177 91.4251 138.915 91.4555L137.275 91.6432C137.237 91.6476 137.199 91.6498 137.16 91.6498Z"
          fill="#3F3D56"
        />
        <path
          d="M6.83542 92.7894C7.1226 92.592 7.36312 92.334 7.54005 92.0333C7.71697 91.7327 7.82599 91.3969 7.85943 91.0495C7.89286 90.702 7.84991 90.3515 7.73358 90.0225C7.61725 89.6935 7.43037 89.3941 7.18612 89.1454L8.10999 68.4727L3.4065 69.0019L3.67975 89.2227C3.29009 89.6618 3.07462 90.2288 3.07416 90.8165C3.0737 91.4041 3.28829 91.9715 3.67727 92.4111C4.06624 92.8508 4.60258 93.1321 5.18465 93.2018C5.76672 93.2715 6.35407 93.1248 6.83542 92.7894Z"
          fill="#FFB8B8"
        />
        <path
          d="M15.4157 127.784L18.0927 128.256L20.7385 118.13L17.231 117.434L15.4157 127.784Z"
          fill="#FFB8B8"
        />
        <path
          d="M22.8184 131.656L14.4052 130.174L14.9766 126.915L20.1393 127.825C20.5662 127.9 20.9741 128.059 21.3397 128.292C21.7054 128.525 22.0216 128.829 22.2704 129.184C22.5192 129.54 22.6956 129.942 22.7897 130.366C22.8837 130.79 22.8935 131.228 22.8184 131.656Z"
          fill="#2F2E41"
        />
        <path
          d="M8.06813 129.127H10.7862L12.0791 118.617L8.06769 118.617L8.06813 129.127Z"
          fill="#FFB8B8"
        />
        <path
          d="M16.0281 131.656L7.48586 131.657L7.48571 128.348L12.7276 128.348C13.6029 128.348 14.4424 128.696 15.0614 129.317C15.6803 129.937 16.0281 130.779 16.0281 131.656Z"
          fill="#2F2E41"
        />
        <path
          d="M19.6304 126.249C19.588 126.249 19.5456 126.246 19.5035 126.241L16.3044 125.977C16.1587 125.96 16.0181 125.914 15.891 125.84C15.7639 125.767 15.6529 125.668 15.565 125.551C15.477 125.433 15.4138 125.299 15.3792 125.156C15.3447 125.013 15.3394 124.865 15.3638 124.72L18.3179 108.122L16.3218 97.5714C16.3055 97.4857 16.2589 97.4088 16.1905 97.3548C16.1221 97.3009 16.0365 97.2735 15.9496 97.2779C15.8626 97.2822 15.7802 97.3179 15.7174 97.3784C15.6547 97.4389 15.6159 97.5201 15.6082 97.607L13.1128 125.749C13.0853 126.032 12.9496 126.292 12.7341 126.476C12.5186 126.66 12.2403 126.753 11.9578 126.735L8.94381 126.623C8.67968 126.604 8.43154 126.489 8.24625 126.299C8.06095 126.11 7.95133 125.859 7.93807 125.594L7.73547 91.8642L23.3612 89.9062L24.4527 106.806L24.4484 106.824L20.6816 125.42C20.6242 125.656 20.4894 125.866 20.2988 126.016C20.1083 126.167 19.8729 126.249 19.6304 126.249Z"
          fill="#2F2E41"
        />
        <path
          d="M12.8873 55.0773C15.8946 55.0773 18.3325 52.6334 18.3325 49.6187C18.3325 46.604 15.8946 44.1602 12.8873 44.1602C9.87997 44.1602 7.44208 46.604 7.44208 49.6187C7.44208 52.6334 9.87997 55.0773 12.8873 55.0773Z"
          fill="#FFB8B8"
        />
        <path
          d="M18.0179 94.3277C17.1663 94.3328 16.331 94.0933 15.611 93.6375C12.9733 91.9849 9.97741 92.6417 8.42634 93.1803C8.27242 93.2344 8.10832 93.2528 7.94629 93.2343C7.78425 93.2158 7.62849 93.1608 7.49065 93.0734C7.35438 92.9877 7.23923 92.8721 7.15383 92.7354C7.06842 92.5987 7.01498 92.4445 6.9975 92.2841L4.1769 67.0315C3.70424 62.8005 6.24667 58.8225 10.2222 57.5728C10.3715 57.5259 10.5234 57.4813 10.6778 57.4392C11.9149 57.103 13.2106 57.0426 14.4735 57.2623C15.7364 57.4821 16.9359 57.9766 17.9877 58.7111C19.059 59.4536 19.955 60.4225 20.6125 61.5496C21.2701 62.6767 21.6732 63.9345 21.7936 65.2346L24.1681 90.6565C24.1843 90.8211 24.1621 90.9872 24.1034 91.1417C24.0446 91.2962 23.9509 91.435 23.8295 91.547C22.9972 92.319 20.5813 94.3275 18.0179 94.3277Z"
          fill="#FF9A40"
        />
        <path
          d="M9.42902 72.4466L3.06547 71.7452C2.88441 71.7252 2.70977 71.6664 2.55344 71.5726C2.39711 71.4789 2.26277 71.3525 2.15957 71.2021C2.05636 71.0516 1.98671 70.8806 1.95537 70.7007C1.92403 70.5209 1.93172 70.3363 1.97794 70.1597L3.5977 63.971C3.7048 63.043 4.17465 62.1953 4.90422 61.614C5.6338 61.0327 6.56355 60.7651 7.48963 60.87C8.41571 60.9748 9.26253 61.4435 9.84443 62.1732C10.4263 62.903 10.6958 63.8343 10.5938 64.7629L10.8342 71.1359C10.841 71.3183 10.8086 71.5001 10.739 71.6689C10.6694 71.8376 10.5644 71.9894 10.431 72.1137C10.2977 72.2381 10.1391 72.3322 9.96621 72.3897C9.7933 72.4471 9.61008 72.4665 9.42902 72.4466Z"
          fill="#FF9A40"
        />
        <path
          d="M24.1427 91.9029C24.404 91.6723 24.6118 91.387 24.7513 91.0672C24.8908 90.7474 24.9586 90.4008 24.9501 90.0519C24.9415 89.703 24.8567 89.3602 24.7017 89.0476C24.5466 88.7351 24.3251 88.4605 24.0528 88.243L22.4847 67.6094L17.8759 68.6494L20.5812 88.7434C20.2472 89.2263 20.1015 89.8153 20.1716 90.3987C20.2418 90.9821 20.5231 91.5195 20.9621 91.9089C21.4011 92.2983 21.9673 92.5128 22.5535 92.5117C23.1397 92.5106 23.7052 92.294 24.1427 91.9029Z"
          fill="#FFB8B8"
        />
        <path
          d="M16.1089 71.1488C15.9755 71.0245 15.8704 70.8728 15.8008 70.704C15.7313 70.5353 15.6988 70.3535 15.7057 70.171L15.9462 63.7981C15.8442 62.8694 16.1136 61.9381 16.6955 61.2084C17.2774 60.4786 18.1242 60.0099 19.0503 59.9051C19.9764 59.8003 20.9062 60.0679 21.6357 60.6492C22.3653 61.2305 22.8352 62.0781 22.9423 63.0062L24.562 69.1949C24.6082 69.3715 24.6159 69.556 24.5846 69.7359C24.5533 69.9158 24.4836 70.0868 24.3804 70.2372C24.2772 70.3877 24.1429 70.5141 23.9865 70.6078C23.8302 70.7015 23.6556 70.7604 23.4745 70.7803L17.1109 71.4817C16.9299 71.5018 16.7466 71.4824 16.5737 71.425C16.4007 71.3675 16.2422 71.2733 16.1089 71.1488Z"
          fill="#FF9A40"
        />
        <path
          d="M12.5634 55.2804C12.4666 55.2803 12.3702 55.269 12.2759 55.2468L12.2483 55.2401C7.46077 54.5058 6.40277 51.726 6.17052 50.5651C5.93014 49.3632 6.20386 48.203 6.82236 47.7524C6.48515 46.6851 6.53928 45.7387 6.9836 44.9384C7.75852 43.5426 9.44027 43.0707 9.66582 43.0129C11.0089 42.0197 12.6159 42.6827 12.9082 42.8172C15.5062 41.8536 16.4992 42.6557 16.6788 42.8347C17.8402 43.0439 18.548 43.4936 18.783 44.1717C19.2244 45.4454 17.8285 47.0297 17.7689 47.0966L17.7379 47.1312L15.6583 47.2305C15.461 47.2399 15.2679 47.2908 15.0914 47.3799C14.915 47.469 14.7592 47.5943 14.6342 47.7476C14.5091 47.9009 14.4176 48.0789 14.3656 48.2699C14.3136 48.461 14.3023 48.6609 14.3323 48.8566C14.3886 49.1092 14.4597 49.3582 14.5452 49.6023C14.9004 50.715 15.1665 51.6655 14.8233 52.0268C14.7455 52.0968 14.6497 52.1433 14.5468 52.1612C14.4438 52.1791 14.338 52.1676 14.2413 52.128C13.9161 52.041 13.6953 52.0592 13.5854 52.1824C13.4146 52.3733 13.4667 52.8568 13.7321 53.5437C13.8154 53.7609 13.8378 53.9968 13.797 54.2259C13.7561 54.4549 13.6535 54.6684 13.5003 54.8432C13.3849 54.9796 13.2414 55.0893 13.0797 55.1648C12.9179 55.2403 12.7418 55.2797 12.5634 55.2804Z"
          fill="#2F2E41"
        />
        <path
          d="M31.2598 131.999H0.2217C0.162902 131.999 0.106508 131.976 0.064931 131.934C0.0233541 131.892 0 131.836 0 131.777C0 131.718 0.0233541 131.661 0.064931 131.62C0.106508 131.578 0.162902 131.555 0.2217 131.555H31.2598C31.3186 131.555 31.3749 131.578 31.4165 131.62C31.4581 131.661 31.4815 131.718 31.4815 131.777C31.4815 131.836 31.4581 131.892 31.4165 131.934C31.3749 131.976 31.3186 131.999 31.2598 131.999Z"
          fill="#CCCCCC"
        />
        <path
          d="M139.45 131.999H108.412C108.353 131.999 108.296 131.976 108.255 131.934C108.213 131.892 108.19 131.836 108.19 131.777C108.19 131.718 108.213 131.661 108.255 131.62C108.296 131.578 108.353 131.555 108.412 131.555H139.45C139.508 131.555 139.565 131.578 139.606 131.62C139.648 131.661 139.671 131.718 139.671 131.777C139.671 131.836 139.648 131.892 139.606 131.934C139.565 131.976 139.508 131.999 139.45 131.999Z"
          fill="#CCCCCC"
        />
        <path
          d="M95.0458 94.2316H41.3943C39.337 94.2293 37.3647 93.409 35.9101 91.9507C34.4554 90.4925 33.6371 88.5153 33.6348 86.453V7.77855C33.6371 5.71627 34.4554 3.73912 35.9101 2.28087C37.3647 0.822616 39.337 0.00234053 41.3943 0H95.0458C97.103 0.00234053 99.0753 0.822616 100.53 2.28087C101.985 3.73912 102.803 5.71627 102.805 7.77855V86.453C102.803 88.5153 101.985 90.4925 100.53 91.9507C99.0753 93.409 97.103 94.2293 95.0458 94.2316Z"
          fill="white"
        />
        <path
          d="M86.6212 62.458H49.819C46.0596 62.4399 46.044 56.6994 49.8191 56.6797L86.6212 56.6797C90.378 56.6973 90.3983 62.4383 86.6212 62.458Z"
          fill="#CCCCCC"
        />
        <path
          d="M86.6212 16.2159H49.819C46.0596 16.1977 46.044 10.4572 49.8191 10.4375L86.6212 10.4375C90.378 10.4551 90.3983 16.1961 86.6212 16.2159Z"
          fill="#CCCCCC"
        />
        <path
          d="M86.6212 73.126H49.819C46.0596 73.1079 46.044 67.3673 49.8191 67.3477L86.6212 67.3477C90.378 67.3653 90.3983 73.1063 86.6212 73.126Z"
          fill="#CCCCCC"
        />
        <path
          d="M86.6212 83.794H49.819C46.0596 83.7758 46.044 78.0353 49.8191 78.0156L86.6212 78.0156C90.378 78.0333 90.3983 83.7743 86.6212 83.794Z"
          fill="#CCCCCC"
        />
        <path
          d="M85.9561 49.7432H50.484C49.5141 49.7421 48.5843 49.3554 47.8986 48.6679C47.2128 47.9804 46.827 47.0484 46.8259 46.0761V26.1631C46.827 25.1909 47.2128 24.2588 47.8986 23.5714C48.5843 22.8839 49.5141 22.4972 50.484 22.4961H85.9561C86.9259 22.4972 87.8557 22.8839 88.5415 23.5714C89.2273 24.2588 89.613 25.1909 89.6141 26.1631V46.0761C89.613 47.0484 89.2273 47.9804 88.5415 48.6679C87.8557 49.3554 86.9259 49.7421 85.9561 49.7432Z"
          fill="#FF9A40"
        />
        <path
          d="M95.0458 94.2316H41.3943C39.337 94.2293 37.3647 93.409 35.9101 91.9507C34.4554 90.4925 33.6371 88.5153 33.6348 86.453V7.77855C33.6371 5.71627 34.4554 3.73912 35.9101 2.28087C37.3647 0.822616 39.337 0.00234053 41.3943 0H95.0458C97.103 0.00234053 99.0753 0.822616 100.53 2.28087C101.985 3.73912 102.803 5.71627 102.805 7.77855V86.453C102.803 88.5153 101.985 90.4925 100.53 91.9507C99.0753 93.409 97.103 94.2293 95.0458 94.2316ZM41.3943 1.33347C39.6897 1.3354 38.0555 2.01505 36.8502 3.22332C35.6449 4.43159 34.9669 6.0698 34.965 7.77855V86.453C34.9669 88.1618 35.6449 89.8 36.8502 91.0083C38.0555 92.2166 39.6897 92.8962 41.3943 92.8981H95.0458C96.7504 92.8962 98.3846 92.2166 99.5899 91.0083C100.795 89.8 101.473 88.1618 101.475 86.453V7.77855C101.473 6.0698 100.795 4.43159 99.5899 3.22332C98.3846 2.01505 96.7504 1.33539 95.0458 1.33347H41.3943Z"
          fill="#E6E6E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_486_35164">
          <rect width="140" height="132" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BlogStatsBigIcon;
