import {Outlet, useNavigate, useParams} from "react-router-dom";
import CandidatesIcon from "@components/svgs/teamsbig";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import Tabs from "@components/tabs";

function View() {
  const {id} = useParams();
  const navigate = useNavigate();
  const tabs = [
    {
      name: "Current positions",
      href: `/area/current_positions/${id}`,
    },
    {
      name: "Edit Current Position",
      href: `/area/current_positions/${id}/edit`,
    },
  ];
  return (
    <div>
      <div className="bg-[#DDEFFF33] mx-4 px-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <h2 className="font-normal text-2xl">{id}</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex ps-4 justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="py-4 bg-custom_faded_blue rounded-2xl my-2 px-4">
        <Tabs tabs={tabs} />
        <Outlet />
      </div>
    </div>
  );
}

export default View;
