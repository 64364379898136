

function Input({className, ...rest}: any) {
  return (
    <>
      <input
        className={`border border-[#657A9345] w-full py-3 px-4 rounded-lg bg-transparent  ${className}`}
        {...rest}
      />
    </>
  );
}

export default Input;
