import CandidatesIcon from "@components/svgs/candidatesbig";
import Input from "@components/input";
import Dropdown from "@components/dropdowns/dropdown";
import EmptyInterface from "@components/empty";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import CandidateList from "./dataview";
import CandidatesStats from "@components/stats/candidatesstats";
import CustomDatePicker from "@components/datepicker";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import NewPrint from "@components/svgs/newprint";
import {useQuery} from "@tanstack/react-query";
import {getCandidates} from "@services/candidates";
import TableLoader from "@components/tableLoader";

function Candidates() {
  const [selected, setSelected] = useState("");
  const list = [
    {id: 1, name: "Engineering", value: "engineering"},
    {id: 2, name: "Business Assistant", value: "business assistant"},
    {id: 3, name: "Human Resource", value: "Human resource"},
    {id: 4, name: "Customer Support", value: "customer support"},
  ];

  const navigate = useNavigate();

  const handleAddNewCandidate = () => {
    navigate("addcandidate");
  };

  const {data, isLoading} = useQuery({
    queryKey: ["candidates"],
    queryFn: getCandidates,
  });

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <CandidatesStats />
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="bg-[#DDEFFF33] mt-4 py-3 px-3 mx-4 rounded-lg font-inter">
        <div className="flex items-center justify-between">
          <div className="items-center  grid lg:grid-cols-5 sm:grid-cols-1 grid-cols-1 gap-x-3 w-full">
            <div className="relative ">
              {/* <SearchIcon className="absolute top-3 left-3" /> */}
              <label className="text-custom_gray text-xs ">ID:</label>
              <Input placeholder="Find user..." className={`text-sm`} />
            </div>

            <div className="">
              <label className="text-custom_gray text-xs ">Department:</label>

              <Dropdown
                selected={selected}
                setSelected={setSelected}
                list={list}
              />
            </div>
            <div className="">
              <label className="text-custom_gray text-xs ">Date:</label>

              <CustomDatePicker selectedDate="" setSelectedDate={() => {}} />
            </div>
          </div>
          <div className="flex">
            <button
              onClick={handleAddNewCandidate}
              className=" flex items-center gap-3 bg-custom_dark_blue rounded-xl text-sm px-12  font-outfit  py-3 text-white"
            >
              <span>Search</span>
            </button>
          </div>
        </div>

        <div className={`mt-8  ${data && "bg-white"}`}>
          <div className="px-5 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-outfit font-semibold">Candidates</h1>
            <div className="flex gap-x-3 items-center">
              <CopyIcon />
              <ShareIcon />
              <NewPrint />
              <button
                onClick={handleAddNewCandidate}
                className="bg-custom_deep_blue text-sm text-white px-4 py-2 rounded-lg flex items-center gap-3"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                    fill="white"
                  />
                </svg>
                <span>Add a new candidate</span>
              </button>
            </div>
          </div>
          <div className="">
            {isLoading ? (
              <TableLoader />
            ) : data?.data?.length === 0 ? (
              <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                <EmptyInterface
                  description="No tasks assigned yet..."
                  buttonText="Add a new task"
                  buttonAction={handleAddNewCandidate}
                />
              </div>
            ) : (
              <div>
                <div className="">
                  <CandidateList candidates={data?.data} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Candidates;
