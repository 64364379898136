import './App.css'
import Navigator from '@navigation/index';
import {TimerProvider} from "./contexts/TimerContext";
import {QueryClientProvider} from "@tanstack/react-query";
import client from "@services/client";
function App() {
  return (
    <QueryClientProvider client={client}>
      <TimerProvider>
        <Navigator />
      </TimerProvider>
    </QueryClientProvider>
  );
}

export default App
