 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={30}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M24 15.5v3a6 6 0 0 1-6 6h-6a6 6 0 0 1-6-6v-6a6 6 0 0 1 6-6h3m7 5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
    <circle cx={22.5} cy={8.5} r={4.5} fill="#FF0606" />
    <rect
      width={29}
      height={29}
      y={0.5}
      fill="#657A93"
      fillOpacity={0.27}
      rx={6}
    />
  </svg>
);
export default SvgComponent;
