 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#AC1B35"
      d="M4 3.75a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM6.25 0a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm7.5 4.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-15 9.75A2.25 2.25 0 0 1 2.5 9H10a2.25 2.25 0 0 1 2.25 2.25v.168a2.077 2.077 0 0 1-.015.206 4.28 4.28 0 0 1-.786 2.012c-.82 1.133-2.38 2.114-5.199 2.114-2.817 0-4.377-.981-5.2-2.114a4.28 4.28 0 0 1-.785-2.012 2.857 2.857 0 0 1-.015-.206V11.25Zm1.5.135v.01l.006.074c.058.464.232.907.507 1.286.489.671 1.554 1.495 3.987 1.495s3.498-.823 3.987-1.495a2.78 2.78 0 0 0 .513-1.361v-.144a.75.75 0 0 0-.75-.75H2.5a.75.75 0 0 0-.75.75v.135Zm13.5 2.865c-.882 0-1.605-.135-2.19-.357.24-.44.422-.91.541-1.398.402.152.936.255 1.649.255 1.678 0 2.367-.573 2.67-.999.177-.248.29-.537.327-.84l.003-.042a.375.375 0 0 0-.375-.369h-4.2A3.734 3.734 0 0 0 13 9h4.875c1.035 0 1.875.84 1.875 1.875v.025a3.338 3.338 0 0 1-.609 1.724c-.633.886-1.82 1.626-3.891 1.626Z"
    />
  </svg>
);
export default SvgComponent;
