import LoginImage from "@assets/login.png";
import logo from "@assets/logo.png";
import {Outlet} from "react-router-dom";

function AuthLayout() {
  return (
    <div className="h-full">
      <div className="flex reltaive min-h-full flex-1">
        <div className="relative hidden w-0 flex-1 lg:block lg:w-1/2">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={LoginImage}
            alt="Login"
          />
        </div>
        <img
          className="absolute left-0 top-0 h-6 mt-10 ml-10 w-auto"
          src={logo}
          alt="Remoteli Logo"
        />
        <div className="flex flex-1 w-1/2 bg-custom_dark_blue flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
