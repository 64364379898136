import Button from "@components/button";
import {Disclosure, Transition} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {TopLoader} from "@components/loaders/toploader";
import { Suspense, lazy, useState } from "react";

// list of skills
const skills = [
  "UI/UX Design",
  "Software Engineering",
  "Business Analysis",
  "Project Management",
  "Customer Service",
  "Sales",
  "Marketing",
  "Business Development",
  "Finance",
  "Human Resources",
  "Recruitment",
  "Data Analysis",
  "Data Science",
  "Machine Learning",
  "Artificial Intelligence",
  "Cyber Security",
  "Networking",
  "Cloud Computing",
];

const Add = lazy(() => import("./add/skill"));

function Skills() {
  const [show, setShow] = useState(false);
  return (
    <Disclosure>
      {({open}) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-6 text-left text-sm font-medium text-black  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
            <span>My skills</span>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-6 w-6 text-black-500`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition ease-out duration-300 transform"
            enterFrom="opacity-0 translate-y-[-10%]"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-[-10%]"
          >
            <Disclosure.Panel className="px-4 pb-2 bg-white pt-4 rounded-es-lg rounded-ee-lg  text-sm text-gray-500">
              <div className="flex gap-3 flex-wrap">
                {skills.map((skill) => (
                  <div
                    key={skill}
                    className="flex border px-3 items-center rounded-full py-1 gap-x-3 relative"
                  >
                    <h1 className="text-sm text-black">{skill}</h1>
                    <XCircleIcon className="h-4 w-4" />
                  </div>
                ))}
              </div>
              <div className="flex items-end justify-end mt-8">
                <div>
                  <Button
                    title="Add skill"
                    className="w-1/6 px-8"
                    onClick={() => setShow(true)}
                  />
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
          <Suspense fallback={<TopLoader />}>
          <Add open={show} setOpen={setShow} />
          </Suspense>
        </>
      )}
    </Disclosure>
  );
}

export default Skills;
