import EmptyInterface from "@components/empty";

import EmptyBox from "@assets/svgs/emptyprojects.svg";

function holidays() {
  return (
    <div className={`mt-8  `}>
      <div className="px-5 py-4 flex justify-between items-center">
        <div className="flex gap-x-3 items-center"></div>
      </div>
      <div className="">
        {[].length === 0 ? (
          <div className="mt-4  border-custom_lighter_gray rounded-xl">
            <EmptyInterface
              description="Ooops! No projects completed yet"
              showButton={false}
              icon={EmptyBox}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default holidays;
