import CustomDatePicker from "@components/datepicker";
import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import EmptyInterface from "@components/empty";
import TimeReports from "./dataview";
import TotalTimes from "./totaltimes";
import Dropdown from "@components/dropdowns/dropdown";
import {useState} from "react";

function CompanyTimeReport() {
  const [selected, setSelected] = useState("");
  const list = [
    {
      id: 1,
      name: "Solutions for Pros",
      value: "Solutions for Pros",
    },
    {
      id: 2,
      name: "Turing",
      value: "Turing",
    },
    {
      id: 3,
      name: "Google",
      value: "Google",
    },
  ];

  return (
    <div className="mt-4 py-3  rounded-lg font-inter">
      <div className="flex items-center justify-between ">
        <div className="items-center grid lg:grid-cols-4  sm:grid-cols-1 grid-cols-1 gap-x-3 w-full">
          <div>
            <label className="text-custom_gray text-xs ">Company:</label>
            <Dropdown
              selected={selected}
              setSelected={setSelected}
              list={list}
            />
          </div>
          <div className="">
            <label className="text-custom_gray text-xs ">End:</label>
            <CustomDatePicker selectedDate="" setSelectedDate={() => {}} />
          </div>
          <div className="">
            <label className="text-custom_gray text-xs ">End:</label>

            <CustomDatePicker selectedDate="" setSelectedDate={() => {}} />
          </div>
          <div className="flex items-center">
            <button
              // onClick={handleAddNewCandidate}
              className="flex mt-5 items-center gap-3 bg-custom_dark_blue rounded-xl text-sm px-12 font-outfit  py-3 text-white"
            >
              <span>Filter</span>
            </button>
          </div>
        </div>
      </div>

      <div className={`mt-8 rounded-xl ${data && "bg-white"}`}>
        <div className="px-5 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-outfit font-semibold">All Times</h1>
          <div className="flex gap-x-3 items-center">
            <CopyIcon />
            <ShareIcon />
            <NewPrint />
          </div>
        </div>
        <div className="">
          {data.length === 0 ? (
            <div className="mt-4 border border-custom_lighter_gray rounded-xl">
              <EmptyInterface
                description="No tasks assigned yet..."
                buttonText="Add a new task"
                buttonAction={() => null}
              />
            </div>
          ) : (
            <div>
              <div className="mt-4">
                <TimeReports />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`mt-20 rounded-xl ${data && "bg-white"}`}>
        <div className="px-5 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-outfit font-semibold">Total times</h1>
          <div className="flex gap-x-3 items-center">
            <CopyIcon />
            <ShareIcon />
            <NewPrint />
          </div>
        </div>
        <div className="">
          {data.length === 0 ? (
            <div className="mt-4 border border-custom_lighter_gray rounded-xl">
              <EmptyInterface
                description="No tasks assigned yet..."
                buttonText="Add a new task"
                buttonAction={() => null}
              />
            </div>
          ) : (
            <div>
              <div className="mt-4">
                <TotalTimes />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyTimeReport;
