 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={21}
    fill="none"
    {...props}
  >
    <path
      stroke="#062C78"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1 6.5 9.732-4.866a.6.6 0 0 1 .536 0L21 6.5m-2 3v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8"
    />
  </svg>
);
export default SvgComponent;
