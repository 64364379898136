import CandidatesIcon from "@components/svgs/teamsbig";
import {ChevronLeftIcon, PhotoIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Input from "@components/input";
import UserDropdown from "@components/dropdowns/userdropdown";
import StatusDropdown from "@components/dropdowns/status";
function Page() {
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selecteduser, setSelectedUser] = useState<any>(null);

  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add Projects</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="w-full grid lg:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-2 py-4 px-6 rounded-lg  bg-custom_faded_blue">
        <div className="col-span-2">
          <label className="text-xs  text-[#82868C]">Title</label>
          <Input />
        </div>

        <div className="col-span-2  w-full">
          <textarea
            rows={10}
            defaultValue={
              "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus."
            }
            placeholder="Eg. Job description...."
            className="mt-1 w-full rounded-xl bg-transparent border placeholder:text-[#9CA1AD] border-[#657A9345]"
          />
        </div>

        <div className="col-span-2">
          <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8">
            <div className="text-center">
              <PhotoIcon
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                PNG, JPG, GIF up to 10MB
              </p>
            </div>
          </div>
        </div>

        <div className="col-span-2  w-full">
          <textarea
            rows={10}
            defaultValue={
              "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus."
            }
            placeholder="Eg. Job description...."
            className="mt-1 w-full rounded-xl bg-transparent border placeholder:text-[#9CA1AD] border-[#657A9345]"
          />
        </div>

        <div className="col-span-2 grid gap-4 grid-cols-6">
          <div className="lg:col-span-1 col-span-4">
            <label className="text-xs">Assign to:</label>
            <UserDropdown
              selecteduser={selecteduser}
              setSelectedUser={setSelectedUser}
            />
          </div>

          <div className="lg:col-span-1 col-span-4">
            <label className="text-xs">Status:</label>
            <StatusDropdown
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
          </div>
        </div>

        <div className="col-span-2 flex justify-end mt-3">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-light text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => null}
          >
            Add Project
          </button>
        </div>
      </div>
    </div>
  );
}

export default Page;
