import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {Fragment, useState} from "react";
import {STATUSLIST} from "@components/utils";
interface DropdownProps {
  list?: any;
  setSelected: (value: any) => void;
  selected: any;
}

export default function BadgeDropdown({
  list,
  selected,
  setSelected,
}: DropdownProps) {
  const [value, setValue] = useState(selected);

  const handleSelection = (user: any) => {
    setValue(user);
    setSelected(user);
  };
  return (
    <div>
      <Menu as="div" className="relative w-full text-left">
        <div>
          <Menu.Button className="inline-flex h-12 justify-between py-3 w-full rounded-lg bg-transparent px-4 text-xs focus:outline-none  focus-visible:ring-white/75 items-center">
            <div className="flex gap-x-3 w-full items-center">
              <div>
                <span
                  className={`px-3 flex items-center justify-center rounded-full text-sm py-1 ${value?.colors.bg} ${value?.colors.text}`}
                >
                  {value?.name}
                  <ChevronDownIcon className="h-4 w-4 ms-1" />
                </span>
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute z-20 ${value?.colors.bg} max-h-60 overflow-scroll right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none`}
          >
            {STATUSLIST?.length === 0 && (
              <div className="p-2 text-sm text-center text-gray-500">
                No user found
              </div>
            )}
            {list
              ? list?.map((user: any) => (
                  <div className="px-1 py-1" key={user?.name}>
                    <Menu.Item>
                      {() => (
                        <button
                          onClick={() => handleSelection(user)}
                          className={` group gap-2 flex w-full items-center rounded-md px-2 py-2 text-xs`}
                        >
                          {user?.name}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ))
              : STATUSLIST?.map((user: any) => (
                  <div className="px-1 py-1" key={user?.name}>
                    <Menu.Item>
                      {() => (
                        <button
                          onClick={() => handleSelection(user)}
                          className={` group gap-2 flex w-full items-center rounded-md px-2 py-2 text-xs`}
                        >
                          {user?.name}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
