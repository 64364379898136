import {Fragment} from "react/jsx-runtime";
import Avartar from "@assets/candidate.jpg";
import avartar from "@assets/leadavartar.png";

function Section1() {
  return (
    <Fragment>
      <div className="bg-white grid grid-cols-1 lg:grid-cols-2 rounded-xl py-6 px-8">
        <div className="flex items-start border-r pe-4">
          <img
            src={Avartar}
            className=" h-auto w-24 rounded-full"
            alt="Picture of the Candidate"
          />

          <div className="flex flex-col gap-5 mt-1 ms-6 ">
            <div>
              <h2 className="font-bold text-xl mb-3">
                Graphics designer(Full day)
              </h2>
              <span className="text-sm">
                Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </span>
            </div>
            <div className="w-40">
              <button
                onClick={() => null}
                className={`bg-custom_dark_blue text-sm w-full justify-center text-white px-4 py-3 rounded-lg flex items-center gap-3`}
              >
                Attach user!
              </button>
            </div>
          </div>
        </div>
        {/* contacts */}
        <div className="ps-5">
          <h2 className="font-bold text-xl mb-3">Contacts</h2>

          <div className="flex flex-col gap-3">
            <div className="flex px-4 items-center justify-between border bg-white py-3 rounded-xl">
              <div className="flex items-center gap-2">
                <img
                  src={avartar}
                  alt="user"
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-sm">Andrea Botwe</span>
              </div>
              <div className="ms-12">
                <button
                  className={`bg-[#DD2025] text-sm w-full justify-center text-white px-4 py-3 rounded-lg flex items-center gap-3`}
                >
                  Remove connection
                </button>
              </div>
            </div>

            <div className="flex px-4 items-center justify-between border bg-white py-3 rounded-xl">
              <div className="flex items-center gap-2">
                <img
                  src={avartar}
                  alt="user"
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-sm">Florence Dzifa</span>
              </div>
              <div className="ms-12">
                <button
                  className={`bg-[#DD2025] text-sm w-full justify-center text-white px-4 py-3 rounded-lg flex items-center gap-3`}
                >
                  Remove connection
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Section1;
