import AddTaskIcon from "@components/svgs/addtask";
import MyEditor from "@components/editor";
import {PhotoIcon} from "@heroicons/react/16/solid";
import CustomDatePicker from "@components/datepicker";
import UserDropdown from "@components/dropdowns/userdropdown";
import {UserInterface} from "@components/dropdowns/types";
import StatusDropdown from "@components/dropdowns/status";
import {useState} from "react";

function AddNewTask() {
  const [value, setValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selecteduser, setSelectedUser] = useState<UserInterface | null>(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  return (
    <>
      <div className="gap-y-4 flex flex-col pb-12">
        <div className="w-full px-6 items-center rounded-lg justify-between flex bg-custom_faded_blue">
          <h1 className="text-2xl font-outfit font-medium">Tasks</h1>

          <div className="mt-10">
            <AddTaskIcon />
          </div>
        </div>

        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg  bg-custom_faded_blue">
          <div className="flex justify-between">
            <label className="text-custom_secondary font-outfit font-light">
              Dashboard / <span className="text-custom_blue">Tasks</span>
            </label>
          </div>
          <div className="mt-4 h-40">
            <MyEditor
              value={value}
              setValue={setValue}
              placeholder="Enter Tasks Here..."
            />
          </div>

          <div className="my-4 flex items-center gap-x-2">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                stroke="#6F727A"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-sm text-[#6F727A]">
              Be clear and concise when writing the content. Be as inclusive as
              possible!
            </span>
          </div>

          <div>
            <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8">
              <div className="text-center">
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </div>

          <div className="grid  lg:grid-cols-10  gap-4 my-4">
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Due Date</label>

              <div className="mt-1">
                <CustomDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Assign to:</label>
              <UserDropdown
                selecteduser={selecteduser}
                setSelectedUser={setSelectedUser}
              />
            </div>
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Status:</label>
              <StatusDropdown
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </div>
          </div>
        </div>

        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
            <span className="text-white">Create task</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default AddNewTask;
