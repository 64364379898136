import React from "react";
import {PauseIcon, PlayIcon, StopIcon} from "@heroicons/react/24/solid";
import {useTimer} from "../contexts/TimerContext";

const Timer: React.FC = () => {
  const {time, isRunning, handlePause, handleStop, formatTime, handlePlay} =
    useTimer();

  return (
    <div className="ms-4 py-2 rounded-lg px-5 flex gap-x-5 justify-between bg-custom_orange">
      <div className="text-white">{formatTime(time)}</div>
      {isRunning ? (
        <PauseIcon className="h-6 w-6 text-white" onClick={handlePause} />
      ) : (
        <PlayIcon className="h-6 w-6 text-white" onClick={handlePlay} />
      )}
      <StopIcon className="h-6 w-6 text-white" onClick={handleStop} />
    </div>
  );
};

export default Timer;
