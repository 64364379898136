import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { Toaster } from 'sonner'

ReactDOM.createRoot(document.getElementById("root")!).render(
    <div className="h-full">
      <App />
      <Toaster position="top-right" />
    </div>
);
