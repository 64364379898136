import CandidatesIcon from "@components/svgs/teamsbig";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
import Dropdown from "@components/dropdowns/dropdown";
import {useState} from "react";
import data from "../dataview/data.json";
function Page() {
  const navigate = useNavigate();
  const [contact, setContact] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [product, setProduct] = useState("");

  return (
    <div className="mx-4">
      <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Add Position</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="flex justify-between my-4">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>

      <div className="w-full grid lg:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-2 py-4 px-6 rounded-lg  bg-custom_faded_blue">
        <div className="">
          <label className="text-xs text-[#82868C]">Contact</label>
          <Dropdown
            list={data?.map((item) => ({name: item?.user, value: item?.user}))}
            selected={contact}
            setSelected={setContact}
          />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Title:</label>
          <Dropdown
            list={data?.map((item) => ({name: item?.user, value: item?.user}))}
            selected={title}
            setSelected={setTitle}
          />
        </div>

        <div className="col-span-2  w-full">
          <label className="text-xs text-[#82868C]">Job description:</label>

          <textarea
            rows={10}
            placeholder="Eg. Job description...."
            className="mt-1 w-full rounded-xl bg-transparent border placeholder:text-[#9CA1AD] border-[#657A9345]"
          />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Type:</label>
          <Dropdown
            list={[
              {name: "Intern", value: "Intern"},
              {name: "Permanent", value: "Permanent"},
              {name: "Contract", value: "Contract"},
            ]}
            selected={type}
            setSelected={setType}
          />
        </div>
        <div className="">
          <label className="text-xs text-[#82868C]">Product:</label>
          <Dropdown
            list={[
              {name: "Full-time", value: "full-time"},
              {name: "Part-time", value: "part-time"},
              {name: "Contract", value: "contract"},
            ]}
            selected={product}
            setSelected={setProduct}
          />
        </div>

        <div className="col-span-2 flex justify-end mt-3">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-light text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => null}
          >
            Add Position
          </button>
        </div>
      </div>
    </div>
  );
}

export default Page;
