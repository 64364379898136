import  {Suspense, lazy, useState} from "react";
import initialData, {State} from "./initialdata";
import Column from "./components/column";
import {DragDropContext} from "react-beautiful-dnd";
import { TopLoader } from "@components/loaders/toploader";


const Delete = lazy(() => import("./view/[id]/components/edit/delete"));
function Board() {
  const [show, setShow] = useState(false);
  const [state, setState] = useState<State>(initialData);

  const ondragend = (result: any) => {
    const {destination, source, draggableId} = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };
      setState(newState);
      return;
    }

    // moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setState(newState);
  };

  return (
    <DragDropContext onDragEnd={ondragend}>
      <div className="font-dm_sans flex">
        {state.columnOrder.map((columnId: string, index: number) => {
          const column = state.columns[columnId];
          const tasks = column.taskIds.map((taskId) => state.tasks[taskId]);
          return (
            <Column
              index={index}
              key={column?.id}
              column={column}
              tasks={tasks}
              handleDelete={() => setShow(true)}
            />
          );
        })}
      </div>
      <Suspense fallback={<TopLoader />}>

      <Delete open={show} setOpen={setShow} />
      </Suspense>
    </DragDropContext>
  );
}

export default Board;
