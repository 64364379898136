 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#34C759"
      d="M7.214 4.797a.831.831 0 0 0-.243.59v.101c0 .461.373.834.833.834h4.553l-7.702 7.701a.834.834 0 0 0 1.179 1.179L13.536 7.5v4.553c0 .46.373.834.833.833h.101c.46 0 .834-.373.833-.833V5.387a.832.832 0 0 0-.833-.833H7.804c-.23 0-.44.092-.59.243Z"
    />
  </svg>
);
export default SvgComponent;
