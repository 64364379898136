import moment from "moment";
import Avartar from "@assets/dzifa.png";
import Avartar2 from "@assets/martaa.png";
import Attachment from "@assets/attachment1.png";
import Attachment2 from "@assets/attachemnt2.png";
function NotificationCard({index}: {index: number}) {
  return (
    <div className="flex my-6 items-center ">
      <div className="w-32">
        <span className="text-black ">{moment()?.format("DD MMMM, YYYY")}</span>
      </div>
      {/* i want a small round bullet with a whole in the middle */}
      <div>
        <div className="h-3 w-3 bg-white rounded-full border-2 border-red-500"></div>
      </div>

      <div className="rounded-xl ms-6 w-full py-5 shadow-sm px-4 relative bg-white flex">
        {/* time */}
        <span className="absolute top-3 right-4 text-xs text-gray-400">
          {moment()?.format("hh:mm A")}
        </span>
        {/* avartar */}
        <div className="h-10 w-10 bg-gray-300 rounded-full">
          <img
            src={index % 2 === 0 ? Avartar : Avartar2}
            alt="avatar"
            className="h-10 w-10 rounded-full"
          />
        </div>
        <div className="ms-4 mt-2">
          {index % 2 === 0 ? (
            <p className="text-black">
              <span className="text-custom_blue">Dzifa Delali</span> {""}
              changed the status of the tasked you assigned her from{" "}
              <span className="px-3 rounded-full py-1 bg-orange-100 text-sm text-orange-500">
                Pending
              </span>{" "}
              to
              <span className="px-3 rounded-full text-sm py-1 bg-green-100 text-green-500">
                Complete
              </span>
            </p>
          ) : (
            <div>
              <p className="text-black">
                <span className="text-custom_blue">Mataaa Sefakor</span> {""}
                assigned a task to you and added the following attachements
              </p>

              <div className=" grid grid-cols-2 lg:grid-cols-4 mt-4">
                <img
                  alt="attachment"
                  src={Attachment}
                  className="h-32 w-32 rounded-lg"
                />
                <img
                  alt="attachment"
                  src={Attachment2}
                  className="h-32 w-32 rounded-lg"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationCard;
