import Input from "@components/input";
import {PhotoIcon} from "@heroicons/react/16/solid";
import {XMarkIcon} from "@heroicons/react/24/solid";
 

interface SubtaskProps {
  setName: (index: number, name: string) => void;
  setFile?: (index: number, file: string) => void;
  setNotes: (index: number, notes: string) => void;
  subTask: {
    name: string;
    notes: string;
    file: string;
  };
  index: number;
  removeSubTask: (index: number) => void;
}

function Subtask({
  setName,
  setNotes,
  subTask,
  index,
  removeSubTask,
}: SubtaskProps) {
  return (
    <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg  bg-custom_faded_blue">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-outfit font-medium">
          SUB Task {index + 1}
        </h1>
        <button onClick={() => removeSubTask(index)}>
          <XMarkIcon className="w-5 h-5 text-red-500 " />
        </button>
      </div>

      <div className="my-4 gap-y-4 flex flex-col">
        <div className="">
          <label className="text-xs text-[#82868C]">Name</label>
          <Input
            value={subTask.name}
            onChange={(e: any) => {
              setName(index, e.target.value);
            }}
            placeholder="Eg. Jane doe"
            className="mt-1"
          />
        </div>

        <div>
          <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8">
            <div className="text-center">
              <PhotoIcon
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                PNG, JPG, GIF up to 10MB
              </p>
            </div>
          </div>
        </div>

        <div>
          <textarea
            rows={8}
            value={subTask.notes}
            onChange={(e: any) => {
              setNotes(index, e.target.value);
            }}
            className="w-full rounded-lg bg-transparent text-sm py-3 px-4 border-[#657A9345]"
            placeholder="Write Notes Here..."
          />
        </div>
      </div>
    </div>
  );
}

export default Subtask;
