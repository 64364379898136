import {PhotoIcon} from "@heroicons/react/24/solid";
import Dropdown from "@components/dropdowns/dropdown";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import EditIcon from "@components/svgs/edit";
import StatusDropdown from "@components/dropdowns/status";
import Button from "@components/button";
import Avartar from "@assets/candidate.jpg";
import Accordion from "@components/accordion/accordion";
import {TopLoader} from "@components/loaders/toploader";
import {Suspense, lazy, useState} from "react";

const ProfileUpdate = lazy(() => import("@components/accordion/add/profile"));

function AddNewEmplyee() {
  const [selected, setSelected] = useState("UI/UX Designer");
  const [show, setShow] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("");

  const list = [
    {id: 1, name: "UI/UX Designer", value: "ui/ux designer"},
    {id: 2, name: "Software Engineer", value: "software engineer"},
    {id: 3, name: "Business Assistant", value: "business assistant"},
  ];

  return (
    <>
      <div className="gap-y-4 flex flex-col  ">
        <div className="py-8 bg-custom_faded_blue flex flex-col lg:flex-row px-4">
          <div className="flex flex-col items-center justify-center">
            <img
              src={Avartar}
              className=" h-auto w-80 rounded-full"
              alt="Picture of the Candidate"
            />

            <div className="flex gap-x-3 items-center mt-2">
              <LinkedIn />
              <Instagram />
              <FacebookIcon />
            </div>
          </div>
          <div className="lg:ms-12 mt-8 lg:mt-0">
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <h2 className="text-xl">Immaculate Akuvi</h2>
                  <p className="font-light">UI/UX Design</p>
                </div>
                <div className="ms-6">
                  {/* badge */}
                  <div className="bg-custom_faded_purple text-custom_deep_purple text-xs rounded-full px-3 py-1">
                    <p>Talent pool</p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setShow(true)}
                className="bg-transparent focus:outline-none"
              >
                <EditIcon />
              </button>
            </div>
            <div className="mt-3">
              <p className="text-gray-400 font-outfit">
                Highly skilled software quality assurance engineer with over 4
                years experience in testing and ensuring the quality of software
                applications. consistently met project deadlines and exceeded
                client expectations by implementing effective testing
                strategies. seeking a challenging role where i can leverage my
                expertise to contribute to the success of a dynamic
                organization.
              </p>
            </div>
          </div>
        </div>

        <div className="gap-5 flex lg:flex-row flex-col">
          <div className="lg:w-1/4 w-full py-4 px-3 rounded-lg flex flex-col gap-6 bg-custom_faded_blue">
            <div className="">
              <label className="text-xs">Candidates pool:</label>
              <StatusDropdown
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </div>

            <div className="">
              <label className="text-xs">Candidates pool:</label>
              <Dropdown
                list={list}
                selected={selected}
                setSelected={setSelected}
              />
            </div>

            <div className="">
              <div>
                <Button
                  title="CV"
                  onClick={() => null}
                  props={{style: {backgroundColor: "#0B2238"}}}
                />
              </div>
            </div>
            <div className="">
              <div>
                <Button
                  title="Remoteli CV"
                  props={{style: {backgroundColor: "#0B2238"}}}
                  onClick={() => null}
                />
              </div>
            </div>

            <div className="">
              <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4">
                <div className="text-center">
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Upload cv</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PDF, DOCX, DOC up to 10MB
                  </p>
                </div>
              </div>
            </div>

            <div>
              <Button title="Update" onClick={() => null} />
            </div>
          </div>

          <div className="lg:w-3/4 w-full  rounded-lg flex flex-col gap-6 ">
            <div className="bg-custom_faded_blue py-4 px-3">
              <Accordion />
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<TopLoader />}>
        <ProfileUpdate open={show} setOpen={setShow} />
      </Suspense>
    </>
  );
}

export default AddNewEmplyee;
