import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
function MonthGraph() {
  const data = [
    {
      name: "Week 1",
      report: 1860,
    },
    {
      name: "Week 2",
      report: 3000,
    },
    {
      name: "Week 3",
      report: 2000,
    },
    {
      name: "Week 4",
      report: 2780,
    },
  ];

  return (
    <div className="col-span-5 flex flex-col justify-between bg-white rounded-xl px-3 py-3">
      <div className="flex justify-between gap-y-4 px-3">
        <h1 className="font-outfit font-bold text-black">
          OCTOBER TIME REPORT
        </h1>
      </div>

      <div className="w-full mt-10">
        <ResponsiveContainer height={300} width={"100%"}>
          <BarChart height={300} data={data}>
            <XAxis dataKey="name" fontSize={12} />
            <YAxis fontSize={12} />
            <Tooltip />

            <Bar
              dataKey="report"
              fill="#8517F3"
              activeBar={<Rectangle fill="#8517F3" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default MonthGraph;
