import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import React, {Fragment} from "react";

const months = [
  {name: "January", value: 1},
  {name: "February", value: 2},
  {name: "March", value: 3},
  {name: "April", value: 4},
  {name: "May", value: 5},
  {name: "June", value: 6},
  {name: "July", value: 7},
  {name: "August", value: 8},
  {name: "September", value: 9},
  {name: "October", value: 10},
  {name: "November", value: 11},
  {name: "December", value: 12},
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function MonthSelector({
  selectedMonth,
  setSelectedMonth,
}: {
  selectedMonth: string;
  setSelectedMonth: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Menu as="div" className="relative border-none  inline-block text-left">
      <div>
        <Menu.Button className="inline-flex border-none w-full justify-center gap-x-1.5 rounded-md bg-[#F4F7FE] px-3 py-2 text-sm font-normal text-[#969EAE] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {selectedMonth ? selectedMonth : "Select month"}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute max-h-60 overflow-scroll right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {months.map((month) => (
              <Menu.Item key={month?.value}>
                {({active}) => (
                  <span
                    onClick={() => setSelectedMonth(month.name)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {month.name}
                  </span>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default MonthSelector;
