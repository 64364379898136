import NotificationCard from "@components/notificationcard";

function Read() {
  return (
    <div>
      <div className="my-8 px-8 relative">
        {/* i want a vertical line */}
        <div
          style={{
            left: "9.23rem",
            zIndex: -1,
          }}
          className="border-l left-40 border-[#657A9345] h-full absolute "
        ></div>
        <div className="">
          {[...Array(10)].map((_, index) => (
            <NotificationCard key={index} index={index} />
          ))}
        </div>
      </div>
      <div className="relative">
        <hr className="border-[#657A9345] absolute w-full top-6 -z-20 border-opacity-50" />
        <div className="flex justify-center gap-3 items-center mt-24">
          <button className="border-[#FF9A40] bg-white border rounded-lg px-6 py-3">
            Load more
          </button>
        </div>
      </div>
    </div>
  );
}

export default Read;
