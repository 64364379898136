import axiosInstance from "..";

export async function getAllTasks() {
  const response = await axiosInstance.get("tasks/all");
  return response.data;
}

export async function getMyTasks() {
  const response = await axiosInstance.get("tasks/all/mine");
  return response.data;
}

export async function updateStatus(taskId: number, status: number) {
  const response = await axiosInstance.post(
    `tasks/update_status/${taskId}/${status}`
  );
  return response.data;
}
