// src/axiosConfig.ts
import axios, {AxiosInstance} from "axios";
import {getCookie} from "./cookies";

// Create an Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://api.remoteli.com/api/", // Replace with your API base URL
  timeout: 10000, // Set a timeout (in milliseconds)
  headers: {
    "Content-Type": "application/json",
    // Add other custom headers if needed
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent, e.g., add an authorization token
    const token = getCookie("remoteli_staff_token"); // Retrieve the token from cookies
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors (e.g., redirect to login)
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
