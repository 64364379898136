import TasksStats from "@components/stats/tasksstats";
import CandidatesIcon from "@components/svgs/teamsbig";
import {Outlet} from "react-router-dom";
import Tabs from "@components/tabs";
function tasks() {
  const tabs = [
    {
      name: "Tasks",
      href: `/area/tasks`,
      count: "02",
    },
    {
      name: "Assigned Tasks",
      href: `/area/tasks/assigned`,
      count: "03",
    },
  ];

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <TasksStats />
          </div>
          <CandidatesIcon />
        </div>
      </div>
      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-1">
        <Tabs tabs={tabs} />
        <Outlet />
      </div>
    </>
  );
}

export default tasks;
