import MonthGraph from "./monthgraph";
import YearGraph from "./yeargraph";

function Graphs() {
  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 mt-4">
      <div className="lg:col-span-1">
        <MonthGraph />
      </div>
      <div className="lg:col-span-2">
        <YearGraph />
      </div>
    </div>
  );
}

export default Graphs;
