import React, {Fragment} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import BadgeDropdown from "@components/dropdowns/badgedropdown";
import {STATUSLIST} from "@components/utils";
import {useNavigate} from "react-router-dom";
import moment from "moment";

function TaskList({tasks}: any) {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(6);
  const navigate = useNavigate();

  const handleNext = () => {
    setStart(start + 6);
    setEnd(end + 6);
  };

  const handlePrev = () => {
    setStart(start - 6);
    setEnd(end - 6);
  };

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                User
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Company
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Tasks
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Due date
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {tasks?.slice(start, end).map((task: any) => (
              <Fragment key={task?.id}>
                <tr key={task.id} className="cursor-pointer hover:bg-gray-50">
                  <td
                    onClick={() =>
                      navigate(`/dashboard/updatetask/${task?.id}`)
                    }
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    {task.u_id}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/dashboard/updatetask/${task?.id}`)
                    }
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    {task.c_id}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/dashboard/updatetask/${task?.id}`)
                    }
                    className="hidden w-2/5 px-3 py-4 text-sm text-gray-900 sm:table-cell"
                  >
                    <span
                      className="line-clamp-3"
                      dangerouslySetInnerHTML={{__html: task.task}}
                    />
                  </td>
                  <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-900 lg:table-cell">
                    <BadgeDropdown
                      selected={STATUSLIST?.find(
                        (item) => item.name === "Pending"
                      )}
                      setSelected={() => null}
                    />
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/dashboard/updatetask/${task?.id}`)
                    }
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                  >
                    {moment(task.due_date || new Date())?.format(
                      "MMM DD, YYYY"
                    )}
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TaskList;
