"use client";
import moment from "moment";
 

interface NoteCardProps {
  handleSelection: () => void;
  note: any;
}

function NoteCard({note, handleSelection}: NoteCardProps) {
  return (
    <>
      <div
        onClick={handleSelection}
        className={`bg-white rounded-xl cursor-pointer select-none border p-4 ${note?.border}`}
      >
        <div className="flex justify-between items-center">
          <span
            className={`"font-outfit text-sm font-light ${note?.bg} px-3 py-1 rounded-xl text-white`}
          >
            {note?.companyName}
          </span>
          <div className="">
            <img src={note.icon} alt="chat" />
          </div>
        </div>
        <div className="mt-4">
          <p className=" text-black mt-2">{note?.title}</p>
          <p className="text-sm text-[#1C1C1C66] mt-2 line-clamp-3">
            {note.description}
          </p>
        </div>

        <div className="mt-4">
          <div className="flex justify-between items-center gap-3">
            <p className="text-black font-light text-sm">{note.author}</p>
            <p className="text-black font-light text-sm">
              {moment()?.format("DD/MM/YYYY HH:MMa")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoteCard;
